<template>
  <div>
    <div v-if="searchError">There was an error with your search. Please try again.</div>

    <div v-if="searchInProgress">Loading...</div>
    
    <div v-if="isEmptyQuery">Search for movies/tv shows...</div>

    <div v-if="hasNoResults">No results</div>

    <div v-if="hasResults" class="media-search-results">
      <div
        class="box"
        v-for="item in mediaItems"
        :key="item.imdb_id"
      >
        <div class="row">

          <!-- No reviews from friends - First panel -->
          <div class="col-sm-3" v-if="!item.review">
            <div class="no-reviews-text">
              Your Friends Haven't Rated This Yet -
            </div>
            <br>
            <div class="no-reviews-text">
              Click on title and scroll down to be the first
            </div>
          </div>

          <!-- Has reviews from friends - First panel -->
          <div class="col-sm-3" v-if="item.review">
            <div class="rr-badge-container">              
                <div class="review">
                  {{ item.review.rating }}
                  <img src="/images/star1.png" width="40px" />
                </div>
                <div v-if="isInfluencer(item.user)" class="rr-badge">rR</div>
                <img class="userimg" :src="item.user && item.user.avatar_url ? `${item.user.avatar_url}` : '/images/avatar.png'" />
            </div>
            <div class="tagname" v-if="item.review">
              {{ item.user ? item.user.name : 'A friend reviewed' }}
              
              <div class="influencer-badge-container" v-if="isInfluencer(item.user)">
                <InfluencerBadge></InfluencerBadge>
              </div>
            </div>
          </div>

          <!-- No reviews from friends - Second panel -->
          <div class="line col-sm-4" v-if="!item.review">
            <div class="mt-4 imdb-rating">
              IMDB Rating: {{ item.rating }}/10 - {{ item.votes | localNumber }} IMDB reviews
            </div>

            <div class="review">
              <img src="/images/star1.png" width="40px" />
            </div>
          </div>

          <!-- Has reviews from friends - Second panel -->
          <div class="line col-sm-4" v-if="item.review">
            <div v-if="item.review">
              <div v-if="item.user" class="tagname">{{ item.user.name }} says:</div>
              <p class="contentDesc">{{ item.review.content }}</p>

            </div>
          </div>

          <!-- Third panel -->
          <div class="col-sm-5">
            <div class="container">
              <div class="row">
                <div class="col-sm-8 col-md-10">
                  <router-link
                    :to="{ name: 'media-details', params: { id: item.imdb_id }}"
                    class="tagname prodlink"
                  >
                    {{ item.title }}
                    <div class="tagname">{{ item.type === 'movie' ? 'Movie' : 'TV Series' }}</div>
                    <img
                      class="prothumb"
                      :src="item.poster"
                    />
                  </router-link>
                </div>

                <div class="col-sm-2 col-md-1 rate">
                  <b>{{ item.average_rating }}</b>
                  <img class="irate" src="/images/moon.png" />
                  <section class="d-flex flex-column-reverse">
                    <span v-for="(star,index) in 5" :key="star">
                      <img v-if="star > item.average_rating" class="irate" src="/images/star2.png" />
                      <img v-else class="icons irate" src="/images/star1.png" />
                    </span>
                  </section>
                </div>
              </div>
            </div>

            <div class="frd_viewer" v-if="item.reviewingFriends">
              <img
                v-for="(item3,index2) in item.reviewingFriends[0]"
                :key="index2"
                class="icons"
                v-if="index2 <= 6"
                :src="item3.user.avatar_url"
              />

              <a v-if="item.reviewingFriends > 6" href>
                <b class="counterFriends">+12</b>
              </a>
            </div>
            <!-- end of col-sm-5 -->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isInfluencer } from '../services/user';

export default {
  created() {
    if(!this.isEmptyQuery) {
      this.searchMedia({ mediaSearchQuery: this.$route.query.q })
    }
  },
  methods: {
    isInfluencer,
    ...mapActions('media', [
      'searchMedia'
    ])
  },
  computed: {
    ...mapGetters('media', [
      'mediaItems',
      'searchInProgress',
      'searchError'
    ]),
    hasNoResults() {
      return !this.searchInProgress && !this.isEmptyQuery && this.mediaItems.length === 0;
    },
    hasResults() {
      return !this.searchInProgress && this.mediaItems.length > 0;
    },
    isEmptyQuery() {
      return !this.$route.query.q;
    }
  }
}
</script>

<style scoped>
  .media-search-result__poster {
    background-size: cover;
    width: 100px;
  }

  .media-search-result__title {
    font-size: 28px;
  }

  .imdb-rating {
    font-size: 23px;
    font-weight: bold;
  }

  .no-reviews-text {
    font-size: 22px;
    line-height: 1.35;
    font-weight: bold;
  }
  
  .prodlink {
    color: #212529;
  }
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "msg-container" }, [
    _c("div", { staticClass: "row" }, [
      !_vm.thread
        ? _c("div", { staticClass: "col-md-6 order-last" }, [
            _c("div", { staticClass: "card box-card" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-body p-0" },
                [
                  _vm._l(_vm.messages, function(msg) {
                    return _c(
                      "div",
                      {
                        staticClass: "d-flex msg position-relative",
                        on: {
                          click: function($event) {
                            return _vm.showThread(msg)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "text-center avatar-col mr-3" },
                          [
                            _c("img", {
                              staticClass: "mx-auto avatar",
                              attrs: {
                                src: _vm.getUserPhoto(
                                  msg.from.id == _vm.user.id ? msg.to : msg.from
                                )
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "from-name" }, [
                              _vm._v(
                                _vm._s(
                                  msg.from.id == _vm.user.id
                                    ? msg.to.name
                                    : msg.from.name
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "text-muted d-block d-lg-none",
                                staticStyle: { "word-break": "keep-all" }
                              },
                              [
                                _c("span", [_vm._v(_vm._s(msg.date))]),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(msg.time))])
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-grow-1 text-left msg-txt" },
                          [
                            _c("span", {
                              staticClass: "text-muted font-weight-bolder",
                              domProps: {
                                textContent: _vm._s(
                                  msg.from.id == _vm.user.id
                                    ? "You: "
                                    : msg.from.name.split(" ")[0] + ": "
                                )
                              }
                            }),
                            _vm._v(
                              "\n                          " +
                                _vm._s(msg.content) +
                                "\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "click-to-reply btn btn-primary position-relative position-md-absolute float-right mr-0 mr-md-5 rounded",
                            on: {
                              click: function($event) {
                                return _vm.showThread(msg)
                              }
                            }
                          },
                          [
                            _c("strong", {
                              staticStyle: { "word-break": "keep-all" },
                              domProps: {
                                textContent: _vm._s(
                                  msg.from.id == _vm.user.id
                                    ? "message"
                                    : "click to reply"
                                )
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "text-muted d-none d-lg-block",
                            staticStyle: { "word-break": "keep-all" }
                          },
                          [
                            _c("span", [_vm._v(_vm._s(msg.date))]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(msg.time))])
                          ]
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  !_vm.loaded
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-content-center py-5" },
                        [_c("h3", [_vm._v("Loading...")])]
                      )
                    : _c(
                        "div",
                        { staticClass: "d-flex justify-content-center py-5" },
                        [
                          _vm.friends.length == 0
                            ? _c("h3", [
                                _vm._v("Add some friends to start chatting")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          Object.keys(_vm.messages).length == 0
                            ? _c("h3", [
                                _vm._v(
                                  "Select a friend from search box to start chatting"
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                ],
                2
              )
            ])
          ])
        : _c("div", { staticClass: "col-md-6 order-last" }, [
            _c("div", { staticClass: "card box-card" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("span", { staticClass: "text-pointer" }, [
                  _c("i", {
                    staticClass: "fa fa-plus-circle mr-1",
                    staticStyle: {
                      "font-size": "20px",
                      color: "rgb(0, 174, 255)",
                      "-webkit-text-stroke": "0.5px black"
                    }
                  }),
                  _vm._v(
                    "\n                      " +
                      _vm._s(_vm.thread.user.name) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary float-right rounded",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.returnToAllConvos()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-arrow-left" }),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Return to all Convos")])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body p-0 " }, [
                !_vm.thread.messages.length
                  ? _c("div", { staticClass: "text-center py-5" }, [
                      _vm._v("No messages yet")
                    ])
                  : _c(
                      "div",
                      { ref: "thread", staticClass: "thread" },
                      _vm._l(_vm.thread.messages, function(msg) {
                        return _c(
                          "div",
                          {
                            staticClass: "d-flex mx-2 my-3",
                            class: msg.is_mine
                              ? "justify-content-end"
                              : "justify-content-start"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "thread-msg",
                                class: msg.is_mine ? "msg-me" : "msg-other"
                              },
                              [
                                _vm._v(
                                  _vm._s(msg.content) +
                                    "\n                            "
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    ),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex p-1 border-top" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reply,
                        expression: "reply"
                      }
                    ],
                    staticClass: "flex-grow-1 no-border",
                    attrs: { placeholder: "type message...", type: "text" },
                    domProps: { value: _vm.reply },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.send($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.reply = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      { staticClass: "button", on: { click: _vm.send } },
                      [_vm._v("Send")]
                    )
                  ])
                ])
              ])
            ])
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _vm.thread
          ? _c("div", { staticClass: "my-2 d-flex justify-content-start" }, [
              _c(
                "button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.returnToAllConvos()
                    }
                  }
                },
                [_vm._v("Back to Inbox")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search"
              }
            ],
            staticClass: "form-control friend-search my-2 my-md-2",
            attrs: { placeholder: "Search friends to message" },
            domProps: { value: _vm.search },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.search = $event.target.value
                },
                function($event) {
                  return _vm.searchUsers(_vm.search)
                }
              ]
            }
          })
        ]),
        _vm._v(" "),
        _vm.loading || _vm.results !== null
          ? _c("div", { staticClass: "results" }, [
              _vm.loading
                ? _c("div", [_c("p", [_vm._v("Loading...")])])
                : _vm.results && _vm.results.length === 0
                ? _c("div", [_c("p", [_vm._v("No Results")])])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.results, function(user) {
                  return _c(
                    "div",
                    {
                      staticClass: "d-flex mb-2 results-row",
                      on: {
                        click: function($event) {
                          _vm.showThread(user)
                          _vm.results = null
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "mr-3 avatar",
                        attrs: { src: _vm.getUserPhoto(user) }
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(user.name))])
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("span", { staticClass: "text-pointer" }, [
        _c("i", {
          staticClass: "fa fa-plus-circle mr-1",
          staticStyle: {
            "font-size": "20px",
            color: "rgb(0, 174, 255)",
            "-webkit-text-stroke": "0.5px black"
          }
        }),
        _vm._v("my convos")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
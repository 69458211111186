<template>
  <div id="profile-interests">
    <div role="navigation" class="navbar navbar-default flex-column flex-lg-row position-relative justify-content-between nav_bg border p-0 p-lg-3" id="navigation">
      <!-- bg-light class replace by nav_bg -->
      <!-- <a @click="showModalProfile" class="navbar-brand justify-content-center">
        <button type="submit" class="btn myrr_btn btn-light">my rR</button>
      </a> -->

      <div class="d-flex d-lg-none w-100 justify-content-between flex-row p-3 mobile-nav navbar-light align-items-center"
        style="background: #5d5d5d;">
        <router-link
          to="/"
          class="rRbutton navbar-brand justify-content-center">
          rRipple
        </router-link>
        <router-link to="/profile" class="btn myrr_btn btn-light d-flex align-items-center">my rR</router-link>
        
        <router-link class="nav-link mr-1 text-white d-lg-none" to="/cart" style="font-size: 18px;">
            <i class="fa fa-shopping-cart" aria-hidden="true"></i>

            (<span class="cart-items-count badge-pill p-0" v-text="cartItemsCount"></span>)
        </router-link>
        <button class="navbar-toggler bg-white text-secondary pt-1 pb-1" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <router-link to="/profile" class="btn myrr_btn btn-light d-none d-lg-inline">my rR</router-link>
      <!-- added a new class -->
      <router-link
        to="/"
        class="rRbutton navbar-brand justify-content-center d-none d-lg-inline">
        rRipple
      </router-link>
      <!-- added a new class -->
      <div id="search-section"
        :class="searchSectionClass">
    
        <div class="" v-if="searchType == searchTypes.PRODUCT">
          *Terms like "Biographies" or "Surfing" are searched as categories
        </div>
        <div class="d-flex flex-lg-row flex-column">
          <div class="d-flex flex-column">
            <form v-if="searchType === searchTypes.PRODUCT" @submit.prevent="searchProducts()" class="form-inline">
              <input v-model="productSearchQuery" type="text" placeholder="Search" class="form-control mr-0 mr-md-2 srch_box" />
            </form>

            <form v-if="searchType === searchTypes.MEDIA" @submit.prevent="searchMedia()" class="form-inline">
              <input v-model="mediaSearchQuery" type="text" placeholder="tv/movie search" class="form-control mr-0 mr-md-2 srch_box" />
            </form>
            
            <div v-if="searchType == searchTypes.PRODUCT" style="z-index: 5;">
              <div v-click-outside="() => { categoriesListOpen = false; }">
                <a :class="[categoriesListOpen ? 'category-toggle text-primary active' : 'category-toggle text-primary']" style="text-decoration: underline;" href="#" @click.prevent="toggleCategorySearch">
                  <strong>Search by Category/Add Category</strong>
                </a>
                <div class="category-search bg-white pt-3 pb-3 pl-1 pr-1 position-absolute shadow"
                  v-show="categoriesListOpen">
                  <div class="pl-4">
                    <input class="form-control" type="text" placeholder="Type to search categories..."
                      v-model="categoryQuery" @input="filterCategories">
                    <hr/>
                    <h2 class="text-muted font-weight-bold m-0">Click the Category You Want</h2>
                    <p>
                      (You can add keywords in the box above to refine within the category, or search the category alone to see
                        what your friends have reviewed in that category!)
                    </p>
                  </div>
                  <hr/>
                  <ul class="categories-list">
                    <li v-for="category in categoriesResults" :data-value="category.node_id"
                      :class="[selectedCategory == category.node_id ? 'selected' : '']"
                      @click="selectCategory(category.node_id)">
                      <span v-if="categoryQuery">
                        <strong class="category-query">{{ category.name.substring(0, categoryQuery.length) }}</strong><span class="autocomplete-text">{{ category.name.substring(categoryQuery.length, category.name.length) }}</span>
                      </span>
                      <span v-else>
                        {{ category.name }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <section class="search-buttons d-flex flex-row justify-content-center">
            <button class="button mt-2 mt-lg-0 mr-lg-2 pr-4 pl-4 mb-lg-4"
              @click.prevent="invokeSearch()">Search</button>

            <button class="button mt-2 mt-lg-0 mb-lg-4" @click="toggleSearchType()">
              Switch to
              <span v-if="searchType === searchTypes.PRODUCT">TV/Movie Search</span>
              <span v-if="searchType === searchTypes.MEDIA">Product Search</span>
            </button>
          </section>
        </div>
      </div>
      <div :class="[searchVisible ? 'mobile-search-icon active' : 'mobile-search-icon']"
        :data-original-title="searchVisible ? 'Close Search' : 'Search'"
        data-toggle="tooltip"
        data-placement="bottom"
        v-if="showMobileSearchButton"
        id="mobile-search-button"
        @click="toggleSearch()"></div>

      </div>

      <transition name="fade">
        <div :class="searchRowClass" id="mobile-search-row" v-show="!showMobileSearchButton"
          @click="toggleSearch(true)">
          <div class="search-button">
            <div class="label">Search</div>
            <button class="btn btn-primary bg-primary text-white">
            </button>
          </div>
        </div>
      </transition>


    <div id="cwrap" class="pt-1 pt-md-3">
      <div class="row menubar">
        <div class="col-sm-12">
          <div class="row">
            <router-link
              v-for="(item,index) in arrMenu"
              tag="div"
              class="item"
              exact-active-class="active"
              :key="item.id"
              v-bind:class="['col-md-'+item.size]"
              :to="item.path"
            >
              <span class="item-label">{{ item.name }}</span>
              <div v-if="item.isactive" class="triangle-down"></div>
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <slot v-if="$route.path.startsWith('/products')"></slot>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex';
import newsfeeds from "./NewsFeed.vue";
import friendship from "./Friendship.vue";
import message from "./Messages.vue";
import discussionForum from "./DiscussionForum.vue";
import cart from "./Cart.vue";
import interestscom from "./Interests.vue";
import Groups from './Groups'
import ClickOutside from 'vue-click-outside';
import collect from 'collect.js';

const SEARCH_TYPES = {
  PRODUCT: 'product',
  MEDIA: 'media'
}

export default {
  directives: {
    ClickOutside,
  },
  components: {
    Groups,
    newsfeed: newsfeeds,
    friendship: friendship,
    message: message,
    discussionForum: discussionForum,
    cart: cart,
    interestscom: interestscom,
  },
  data() {
    return {
      searchTypes: SEARCH_TYPES,
      searchType: SEARCH_TYPES.PRODUCT,
      mediaSearchQuery: this.$route.query.q ? this.$route.query.q : '',
      productSearchQuery: this.$route.query.q ? this.$route.query.q : '',

      tabview: "myinterest",
      current_parent: null,
      list: this.interests,
      selected_interests: this.user_interests,
      tobe_saved: [],
      feeds: [],
      isShowPanel: true,
      newsFeedPanel: true,
      arrMenu: [
        { id: 0, name: "feed", path: '/', isactive: true, size: 2 },
        { id: 1, name: "friends", path: '/friends', isactive: false, size: 2 },
        { id: 2, name: "message", path: '/messages', isactive: false, size: 2 },
        { id: 3, name: "discussion forum", path: '/discussions', isactive: false, size: 2 },
        { id: 4, name: "cart", path: '/cart', isactive: false, size: 2 },
        { id: 5, name: "my groups", path: '/groups', isactive: false, size: 2 }
      ],
      product_images: "",
      images_path: "",
      interestData: [],
      userInterest: [],
      activeItem: {},
      isInterest: true,
      categories: [],
      categoriesResults: [],
      categoriesListOpen: false,
      categoryQuery: null,
      selectedCategory: null,
      searchVisible: false,
      searchSectionClass: 'navbar-right search-section p-3 p-lg-0',
      searchRowClass: 'mobile-search-row row p-2 w-100 pr-0 mr-0 ml-0 justify-content-end',
      showMobileSearchButton: false,
    };
  },
  computed: {
    cartItemsCount() {
      return window.cartItemsCount;
    },
  },
  created: function() {
    window.invokedSearchViaBox = false;
    axios.get("/categories").then(({ data }) => {
      this.categories = data;
      this.categoriesResults = data;      
      this.selectedCategory =  this.$route.query.category ? this.$route.query.category : null;
      if (this.selectedCategory) {
        this.categoryQuery = this.categories.filter(category => category.node_id == this.selectedCategory)[0].name;
      }
      
      this.filterCategories();
    });

    // $(() => $('body .mobile-search-icon').tooltip({ placement: 'bottom' }))
  },
  mounted() {
    $(() => {
      if (this.categoriesListOpen) {
        this.adjustCategorySearch();
      }

      this.showMobileSearchButton = !this.isOnScreen('#mobile-search-row');

      $(window).on('resize', e => {
        if ($(window).width() > 576) {
          $('.search-section').removeAttr('style');
        }
        if (this.categoriesListOpen) {
          this.adjustCategorySearch();
        }
      }).on('scroll', e => {
        if ($(window).width() > 576) {
          $('#search-section').removeAttr('style');
          return;
        }

          this.showMobileSearchButton = !this.isOnScreen('#mobile-search-row');
          this.adjustSearchSectionPositioning(!this.showMobileSearchButton);
      });

      $('body').on('click', e => {
        if (!this.searchVisible || $(window).width() > 576) {
          return;
        }

        const target = $(e.target);
        const isSearchSection = target.attr('id') == 'search-section' || target.closest('#search-section').length > 0;
        const isSearchRow = target.attr('id') == 'mobile-search-row' || target.closest('#mobile-search-row').length > 0;
        const isSearchBtn = target.attr('id') == 'mobile-search-button' || target.closest('#mobile-search-button').length > 0;

        if (!isSearchSection && !isSearchRow && !isSearchBtn) {
          this.toggleSearch(true);
        }
      })
    });
  },
  methods: {
    ...mapActions('media', {
      searchMediaAction: 'searchMedia',
    }),
    ...mapActions('product', {
      searchProductsAction: 'searchProducts',
      firstPage: 'firstPage',
    }),
    adjustCategorySearch() {
      if ($(window).width() > 991) {
        return $('.category-search').removeAttr('style');
      }

      const offset = $('.category-toggle:first').position().top + 5;

      $('.category-search').css('top', offset);
    },
    toggleSearchType() {
      this.searchType = this.searchType === SEARCH_TYPES.PRODUCT
        ? SEARCH_TYPES.MEDIA
        : SEARCH_TYPES.PRODUCT;
    },
    toggleSearch(usingRow = false) {
      this.searchVisible = !this.searchVisible;


        if (this.searchVisible) {
          this.searchRowClass = 'mobile-search-row row p-2 w-100 pr-0 mr-0 ml-0 justify-content-end show';
          setTimeout(() => this.searchRowClass += ' shown', 300);
        } else {
          this.searchRowClass = 'mobile-search-row row p-2 w-100 pr-0 mr-0 ml-0 justify-content-end'
        }

      if (this.searchVisible) {
        setTimeout(() => {
          this.searchSectionClass = 'navbar-right search-section p-3 p-lg-0 mobile-shown';
        }, usingRow ? 350 : 0);

        this.adjustSearchSectionPositioning(usingRow);
      } else {
        this.searchSectionClass = 'navbar-right search-section p-3 p-lg-0';
      }
    },
    adjustSearchSectionPositioning(usingRow = false) {
        if (usingRow) {
          const mobileSearchRow = $('.mobile-search-row');

          $('.search-section').css('position', 'absolute');
          $('.search-section').css('top', '105px');
          $('.search-section').css('right', '45px');
        } else {
          $('.search-section').removeAttr('style');
        }
    },
    async searchMedia() {
      let mediaSearchQuery = this.mediaSearchQuery;

      this.searchMediaAction({ mediaSearchQuery })

      if(this.$route.query.q === mediaSearchQuery) {
        return;
      }

      this.$router.push({
        name: 'media-search-results',
        query: {
          q: mediaSearchQuery
        }
      });
    },
    async searchProducts(nodeId = null) {
      let productSearchQuery = this.productSearchQuery;
      let productSearchCategory = nodeId;
      if ($(window).width() < 577 && this.searchVisible) {
        this.toggleSearch(true);
      }
      this.categoriesListOpen = false;
      this.firstPage();

      if(this.$route.name == 'product-search-results' && this.$route.query.q === productSearchQuery && this.$route.query.category === productSearchCategory) {
        console.debug('Aborted same search')
        return;
      }

      this.$router.push({
        name: 'product-search-results',
        query: {
          q: productSearchQuery,
          category: productSearchCategory
        }
      });
    },
    showModalProfile: function() {
      var self = this;
      this.arrMenu.forEach(function(item, index) {
        item.isactive = false;
      });
      this.isInterest = true;
      this.isShowPanel = false;
    },
    setInActiveProduct: function() {
      this.activeItem = {};
      this.arrMenu[0].isactive = true;
      this.isShowPanel = true;
      this.isInterest = true;
      this.newsFeedPanel.click;
    },
    showProductFeed: function(item) {
      this.arrMenu.forEach(function(item, index) {
        item.isactive = false;
      });
      this.isShowPanel = true;
    },
    fetchFeeds: function() {
      alert("Fetch Feeds");
    },
    toggleMenu: function(myindex) {
      this.isShowPanel = true;
      var self = this;
      this.arrMenu.forEach(function(item, index) {
        item.isactive = false;
      });
      self.arrMenu[myindex].isactive = true;
    },
    removeUserInterest(index) {
      axios
        .delete("/profile/interests/" + this.selected_interests[index].id)
        .then(response => {
          this.selected_interests = response.data;
        })
        .catch(() => {});
    },
    removeSelected(index) {
      this.tobe_saved.splice(index, 1);
    },
    upLevel() {
      // console.info(this.current_parent.parent);
      if (!this.current_parent.parent) {
        this.list = this.interests;
        this.current_parent = null;
        return;
      }

      this.checkInterest(this.current_parent.parent);
    },
    checkInterest(interest) {
      let interest_id = typeof interest === "object" ? interest.id : interest;
      this.toggling = true;
      axios
        .get("/profile/interests/" + interest_id)
        .then(response => {
          let found_interest = response.data;
          if (found_interest.children.length) {
            this.current_parent = found_interest;
            this.list = found_interest.children;
            return;
          }

          if (
            !this.tobe_saved.find(element => {
              return element.id == found_interest.id;
            })
          ) {
            this.tobe_saved.push(found_interest);
          }
        })
        .catch(() => {})
        .then(() => {
          this.toggling = false;
        });
    },
    submitInterest() {
      var self = this;
      axios
        .post("/profile/interests", {
          interests: this.tobe_saved.map(interest => {
            return interest.id;
          })
        })
        .then(response => {
          this.tobe_saved = [];
          this.selected_interests = response.data;
          this.tabview = "myinterest";
          // console.info(this.current_parent);
          this.list = this.interests;
          this.current_parent = null;
        })
        .catch(() => {})
        .then(() => {})
        .finally(() => {
          // console.log("Finally");
        });
    },
    toggleCategorySearch() {
      this.categoriesListOpen = !this.categoriesListOpen;
    },
    filterCategories() {
      if (!this.categoryQuery) {
        return this.categoriesResults = this.categories;
      }

      this.categoriesResults = this.categories.filter(category => {
        return category.name.toLowerCase().substring(0, this.categoryQuery.length) == this.categoryQuery.toLowerCase();
      });
    },
    selectCategory(nodeId) {
      this.searchProducts(nodeId);
    },
    invokeSearch() {
      window.invokedSearchViaBox = true;

      return this.searchType == this.searchTypes.PRODUCT ? this.searchProducts() : this.searchMedia();
    },
    isOnScreen(elem) {
      elem = $(elem);
      // if the element doesn't exist, abort
      if( elem.length == 0 ) {
        return;
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (top >= viewport_top && top < viewport_bottom) ||
      (bottom > viewport_top && bottom <= viewport_bottom) ||
      (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
    },
  }
};
</script>

<style lang="scss" scoped>
.search-buttons button {
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.category-toggle.active {
  color: #0056b3 !important;
  background: transparent;
}
.categories-list li {
	list-style: none;
	font-weight: 600;
	text-decoration: underline;
	line-height: 2;
	cursor: pointer;
  font-size: 16px;
}

.autocomplete-text {
  color: #929292;
}

.categories-list li:hover {
	color: #00aeff;
	font-weight: 500;
}
.categories-list li:hover > .autocomplete-text, .categories-list li:hover > .category-query, .categories-list li.selected {
	color: #00aeff;
	font-weight: 500;
}

.search-section {
  margin-right: 200px;
}

@media (max-width: 991.98px) {
  .search-section {
    margin: 0 !important;
  }
  .search-section .form-inline, .search-section .form-control {
    width: 100% !important;
  }

  #navigation {
    flex-wrap: nowrap;
  }
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading ? _c("div", [_vm._v("Loading...")]) : _vm._e(),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", { staticClass: "media-search-results" }, [
          _c("div", { staticClass: "box media-search-result d-flex" }, [
            _c("div", { staticClass: "row no-gutters" }, [
              _c("div", { staticClass: "col-xl-6" }, [
                _c("div", { staticClass: "d-flex" }, [
                  _c("div", { staticClass: "media__details" }, [
                    _c("div", { staticClass: "text-left" }, [
                      _vm._v(_vm._s(_vm.mediaItem.title))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-left media-info--underlined media-info--small"
                      },
                      [
                        _vm._v(
                          " \n                " +
                            _vm._s(
                              _vm.mediaItem.type === "series"
                                ? "TV Series"
                                : "Movie"
                            ) +
                            ":\n                " +
                            _vm._s(_vm.mediaItem.genre) +
                            "\n                "
                        ),
                        _vm.mediaItem.production
                          ? _c("span", [
                              _vm._v(
                                "\n                  - " +
                                  _vm._s(_vm.mediaItem.production) +
                                  "\n                "
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("img", {
                        staticClass: "media-search-result__poster",
                        attrs: { src: _vm.mediaItem.poster }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "rv_box" }, [
                    _c("div", { staticClass: "rvrate" }, [
                      _c(
                        "div",
                        { staticClass: "avg-rating font-weight-bolder" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.mediaItem.average_rating > 5
                                  ? 5
                                  : _vm.mediaItem.average_rating
                              ) +
                              "\n                        "
                          ),
                          _c("i", { staticClass: "fa fa-star text-warning" }),
                          _vm._v(" "),
                          _vm.mediaItem.average_rating > 5
                            ? _c("span", [
                                _vm._v("\n                            + "),
                                _c("i", {
                                  staticClass: "fa fa-moon text-warning",
                                  staticStyle: { transform: "rotate(200deg)" }
                                }),
                                _vm._v("!\n                        ")
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        class: [
                          _vm.mediaItem.average_rating > 5
                            ? "icons irate moon-filled"
                            : "icons irate"
                        ],
                        attrs: {
                          src: [
                            _vm.mediaItem.average_rating > 5
                              ? "/images/moon-filled.png"
                              : "/images/moon.png"
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "stars-box d-flex flex-column-reverse" },
                        _vm._l(5, function(star) {
                          return _c("span", { key: star }, [
                            star > _vm.mediaItem.average_rating
                              ? _c("img", {
                                  staticClass: "icons irate",
                                  attrs: { src: "/images/star2.png" }
                                })
                              : _c("img", {
                                  staticClass: "icons irate",
                                  attrs: { src: "/images/star1.png" }
                                })
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column media__info-container" },
                  [
                    _c("div", { staticClass: "media__info d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "media__info-inner media__info-item" },
                        [
                          _c("div", { staticClass: "text-right" }, [
                            _vm._v("(" + _vm._s(_vm.mediaItem.year) + ")")
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.mediaItem.short_description))
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "media-info media-info--underlined"
                            },
                            [_vm._v(_vm._s(_vm.mediaItem.rated))]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "media__info-right d-none d-md-block"
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "media__info-item" }, [
                      _c("div", { staticClass: "media-info-title mt-4" }, [
                        _vm._v("\n                Top Cast:\n              ")
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.mediaItem.top_cast_members) +
                            "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4" }, [
                        _vm._v(
                          "\n                IMDB Rating: " +
                            _vm._s(_vm.mediaItem.rating) +
                            "/10 (" +
                            _vm._s(_vm._f("localNumber")(_vm.mediaItem.votes)) +
                            " IMDB reviews)\n              "
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-xl-6 review-container" }, [
                _c(
                  "div",
                  { staticClass: "Review_overflow" },
                  [
                    _vm.mediaItem.reviews.length === 0
                      ? _c("div", { staticClass: "text-center" }, [
                          _c("p", { staticClass: "py-10" }, [
                            _vm._v(
                              "\n                  No reviews for this\n                  " +
                                _vm._s(
                                  _vm.mediaItem.type === "movie"
                                    ? "Movie"
                                    : "TV Show"
                                ) +
                                "\n                  yet. Be the first!\n                "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(
                      _vm.mediaItem.reviews.slice(0, _vm.reviewLimit),
                      function(r) {
                        return _c(
                          "div",
                          { key: r.id, staticClass: "review_flexbox" },
                          [
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("div", { staticClass: "rr-badge-container" }, [
                                _vm.isInfluencer(r.user)
                                  ? _c("div", { staticClass: "rr-badge" }, [
                                      _vm._v("rR")
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "userimg",
                                  attrs: { src: r.user.avatar_url }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("b", [_vm._v(_vm._s(r.user.name))]),
                                _vm._v(" "),
                                _vm.isInfluencer(r.user)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "influencer-badge-container"
                                      },
                                      [_c("InfluencerBadge")],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-8" }, [
                              _c("div", [
                                _c("i", [
                                  _vm._v(
                                    _vm._s(r.user.name.split(" ")[0]) + " says:"
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(r.content) +
                                    "\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "review_star font-weight-bolder"
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(r.rating > 5 ? 5 : r.rating) +
                                      "\n                      "
                                  ),
                                  _c("i", {
                                    staticClass: "fa fa-star text-warning"
                                  }),
                                  _vm._v(" "),
                                  r.rating > 5
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                          + "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "fa fa-moon text-warning",
                                          staticStyle: {
                                            transform: "rotate(200deg)"
                                          }
                                        }),
                                        _vm._v("!\n                      ")
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ])
                          ]
                        )
                      }
                    ),
                    _vm._v(" "),
                    _vm.mediaItem.reviews.length > _vm.reviewLimit
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-content-center my-2" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                on: {
                                  click: function($event) {
                                    _vm.reviewLimit = _vm.reviewLimit + 10
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                see more reviews\n              "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.mediaItem.friend_reviews.length
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "frd_viewer" },
                        [
                          _vm._l(_vm.mediaItem.friend_reviews, function(
                            item3,
                            index2
                          ) {
                            return _c("img", {
                              key: index2,
                              staticClass: "icons",
                              attrs: { src: item3.user.avatar_url }
                            })
                          }),
                          _vm._v(" "),
                          _vm.mediaItem.friend_reviews.length > 6
                            ? _c("a", { attrs: { href: "" } }, [
                                _c("b", { staticClass: "counterFriends" }, [
                                  _vm._v(
                                    "+" +
                                      _vm._s(
                                        _vm.mediaItem.friend_reviews.length - 6
                                      )
                                  )
                                ])
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "div",
                {
                  staticClass: "rvw_wraper box mt-3 text-left",
                  staticStyle: { "font-size": "1rem" }
                },
                [
                  _vm.mediaItem.can_review
                    ? _c("div", [
                        _c("h3", { staticClass: "my-3" }, [
                          _vm._v("Add Review")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("rating", {
                              attrs: {
                                grade: 0,
                                "max-stars": 5,
                                "moon-ctive": false
                              },
                              on: {
                                "rating-set": function($event) {
                                  _vm.reviewForm.new_rating = $event
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "title" } }, [
                            _vm._v("Title")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.reviewForm.title,
                                expression: "reviewForm.title"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "title",
                              placeholder: "Awesome title",
                              name: "title"
                            },
                            domProps: { value: _vm.reviewForm.title },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.reviewForm,
                                  "title",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "body" } }, [
                            _vm._v("Content")
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.reviewForm.body,
                                expression: "reviewForm.body"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { id: "body", name: "body" },
                            domProps: { value: _vm.reviewForm.body },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.reviewForm,
                                  "body",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reviewForm.is_private,
                                  expression: "reviewForm.is_private"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.reviewForm.is_private
                                )
                                  ? _vm._i(_vm.reviewForm.is_private, null) > -1
                                  : _vm.reviewForm.is_private
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.reviewForm.is_private,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.reviewForm,
                                          "is_private",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.reviewForm,
                                          "is_private",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.reviewForm, "is_private", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(
                              "\n                    Make Review Private\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              on: { click: _vm.submitReview }
                            },
                            [_vm._v("Add Review")]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.mediaItem.user_review
                    ? _c("div", [
                        _c("h3", { staticClass: "my-3" }, [
                          _vm._v("Edit Review")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.mediaItem.user_review.is_private,
                                  expression: "mediaItem.user_review.is_private"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.mediaItem.user_review.is_private
                                )
                                  ? _vm._i(
                                      _vm.mediaItem.user_review.is_private,
                                      null
                                    ) > -1
                                  : _vm.mediaItem.user_review.is_private
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.mediaItem.user_review.is_private,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.mediaItem.user_review,
                                          "is_private",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.mediaItem.user_review,
                                          "is_private",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.mediaItem.user_review,
                                      "is_private",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(
                              "\n                    Make Review Private\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  return _vm.updateReview(
                                    _vm.mediaItem.user_review.is_private
                                  )
                                }
                              }
                            },
                            [_vm._v("Update Review")]
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { searchMediaByFriends } from '../../api/media'

const state = {
  mediaSearchQuery: '',
  mediaItems: [],
  searchInProgress: false,
  searchComplete: false,
  searchError: false
}

const getters = {
  mediaSearchQuery: state => state.mediaSearchQuery,
  mediaItems: state => state.mediaItems,
  searchInProgress: state => state.searchInProgress,
  searchError: state => state.searchError,
}

const actions = {
  async searchMedia({ commit }, { mediaSearchQuery }) {
    commit('setMediaQuery', { mediaSearchQuery })

    commit('setSearchInProgress', true)

    try {
      let mediaItems = await searchMediaByFriends(mediaSearchQuery)
      commit('setMediaItems', { mediaItems })
    } catch (error) {
      commit('setSearchError', true)
      commit('setMediaItems', { mediaItems: [] })
    }

    commit('setSearchInProgress', false)
  }
}

const mutations = {
  setMediaQuery(state, { mediaSearchQuery }) {
    state.mediaSearchQuery = mediaSearchQuery
  },

  setMediaItems(state, { mediaItems }) {
    state.mediaItems = mediaItems
  },

  setSearchInProgress(state, value) {
    state.searchInProgress = value
  },

  setSearchError(state, value) {
    state.searchError = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
<template>
    <div class="rating-display-rating">
        <ul class="rating-display-list">
            <li v-for="star in maxStars" :class="{ 'rating-display-active': star <=stars }" class="rating-display-star">
                <icon :name="star <= stars ? 'star' : 'star-o'"/>
            </li>
            <li :class="{ 'rating-display-active': moonIsActive }" class="rating-display-moon">
                <icon name="moon-o"/>
            </li>
        </ul>
        <span v-if="moonIsActive">6 of {{ maxStars }}</span>
        <span v-else>{{ stars }} of {{ maxStars }}</span>
    </div>
</template>

<script>
    import 'vue-awesome/icons/star'
    import 'vue-awesome/icons/star-o'
    import 'vue-awesome/icons/moon-o'

    import Icon from 'vue-awesome/components/Icon'

    export default {
        components: {Icon},
        props: ['grade', 'maxStars', 'moonActive'],
        data() {
            return {
                stars: this.grade,
                moonIsActive: this.moonActive
            }
        }
    }
</script>

<style>
    .rating-display-rating {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        font-size: 14px;
        color: #a7a8a8;
    }

    .rating-display-list {
        margin: 0 0 5px 0;
        padding: 0;
        list-style-type: none;
    }

    .rating-display-star {
        display: inline-block;
    }

    .rating-display-active {
        color: #f3d23e;
    }

    .rating-display-moon {
        display: inline-block;
    }

</style>
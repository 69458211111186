var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rating" }, [
    _c(
      "ul",
      { staticClass: "list" },
      [
        _vm._l(_vm.maxStars, function(star) {
          return _c(
            "li",
            {
              staticClass: "star",
              class: { active: star <= _vm.stars },
              on: {
                click: function($event) {
                  return _vm.rate(star)
                }
              }
            },
            [
              _c("i", {
                class: [star <= _vm.stars ? "fa fa-star" : "far fa-star"]
              })
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "moon",
            class: { active: _vm.moonIsActive },
            on: {
              click: function($event) {
                return _vm.moon()
              }
            }
          },
          [
            _c("i", {
              class: [_vm.moonIsActive ? "fa fa-moon" : "far fa-moon"]
            })
          ]
        )
      ],
      2
    ),
    _vm._v(" "),
    _vm.moonIsActive
      ? _c("span", [_vm._v("6 of " + _vm._s(_vm.maxStars))])
      : _c("span", [_vm._v(_vm._s(_vm.stars) + " of " + _vm._s(_vm.maxStars))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rating-display-rating" }, [
    _c(
      "ul",
      { staticClass: "rating-display-list" },
      [
        _vm._l(_vm.maxStars, function(star) {
          return _c(
            "li",
            {
              staticClass: "rating-display-star",
              class: { "rating-display-active": star <= _vm.stars }
            },
            [
              _c("icon", {
                attrs: { name: star <= _vm.stars ? "star" : "star-o" }
              })
            ],
            1
          )
        }),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "rating-display-moon",
            class: { "rating-display-active": _vm.moonIsActive }
          },
          [_c("icon", { attrs: { name: "moon-o" } })],
          1
        )
      ],
      2
    ),
    _vm._v(" "),
    _vm.moonIsActive
      ? _c("span", [_vm._v("6 of " + _vm._s(_vm.maxStars))])
      : _c("span", [_vm._v(_vm._s(_vm.stars) + " of " + _vm._s(_vm.maxStars))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
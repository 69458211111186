<template>
    <div>
        <h2>
            <img :src="imageUrl"
                 alt=""
                 class="img-responsive"
                 width="540"
                 height="400"
            >
        </h2>
        <rating-display :max-stars=maxStars
                        :grade=grade
                        :moon-active=moonActive>
        </rating-display>
    </div>
</template>

<script>
    export default {
        props: ['maxStars', 'grade', 'moonActive', 'imageUrl'],
        data() {
            return {}
        }
    }
</script>

<style scoped>

</style>
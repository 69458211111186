/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')
require('./functions')

window.Vue = require('vue')
window.Env = $('#_env').attr('content')
import InterestsSelect from './components/InterestsSelect.vue';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.component('media-search-results', require('./components/MediaSearchResults.vue').default)
Vue.component('media-item-details', require('./components/MediaItemDetails.vue').default)
Vue.component('stars', require('./components/Stars.vue').default)
Vue.component('rating', require('./components/Rating.vue').default)
Vue.component('rating-display', require('./components/RatingDisplay.vue').default)
Vue.component('product-display', require('./components/ProductDisplay.vue').default)
Vue.component('product-view', require('./components/ProductView.vue').default)
Vue.component('setup-interests', require('./components/SetupInterests.vue').default)
Vue.component('product-list-display', require('./components/ProductListDisplay.vue').default)
Vue.component('register-user', require('./components/Register.vue').default)
Vue.component('referral-modal', require('./components/ReferralModal.vue').default);
Vue.component('address-form', require('./components/AddressForm.vue').default);
Vue.component('card-form', require('./components/CardForm.vue').default);
Vue.component('checkout', require('./components/Checkout.vue').default);

import VueYouTubeEmbed from 'vue-youtube-embed'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)
import interests from './components/Interests.vue'
import interestscom from './components/Interests.vue'
import home from './components/Home.vue'
import Router from 'vue-router'
import store from './store'

import linkify from 'vue-linkify'
import newsfeeds from './components/NewsFeed'
import profile from './components/Interests.vue';
import friendship from './components/Friendship.vue'
import message from './components/Messages.vue'
import discussionForum from './components/DiscussionForum.vue'
import cart from './components/Cart.vue'
import Groups from './components/Groups'
import MediaSearchResults from './components/MediaSearchResults.vue';
import MediaItemDetails from './components/MediaItemDetails.vue';
import ProductSearchResults from './components/ProductSearchResults.vue';
import Checkout from './components/Checkout.vue';
import ProductFeedItem from './components/ProductFeedItem.vue';

Vue.directive('linkified', linkify)

Vue.use(VueYouTubeEmbed)

// https://stackoverflow.com/a/39248551/10861500
$.fn.getFormData = function() {
  var _ = {};
  $.map(this.serializeArray(), function(n) {
    const keys = n.name.match(/[a-zA-Z0-9_]+|(?=\[\])/g);
    if (keys.length > 1) {
      let tmp = _;
      let pop = keys.pop();
      let j;
      for (let i = 0; i < keys.length, j = keys[i]; i++) {
        tmp[j] = (!tmp[j] ? (pop == '') ? [] : {} : tmp[j]), tmp = tmp[j];
      }
      if (pop == '') tmp = (!Array.isArray(tmp) ? [] : tmp), tmp.push(n.value);
      else tmp[pop] = n.value;
    } else _[keys.pop()] = n.value;
  });
  return _;
}

function str_random(length = 16) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

Array.prototype.remove = function() {
  var what, a = arguments, L = a.length, ax;
  while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1);
      }
  }
  return this;
};
class Error {
  constructor () {
    this.errors = {}
  }

  get (field) {
    if (this.errors[field]) {
      return this.errors[field][0]
    }
  }

  record (errors) {
    this.errors = errors
  }

  addError (errorKey, error) {
    this.errors[errorKey] = error
  }
}

Vue.use(Router)
const routes = [
  { path: '/', name: 'home', component: newsfeeds },
  { path: '/profile', component: profile },
  { path: '/friends', component: friendship },
  { path: '/messages', component: message },
  { path: '/discussions', component: discussionForum },
  { path: '/cart', name: 'shopping-cart', component: cart },
  { path: '/interests', component: interestscom },
  { path: '/groups', component: Groups },
  { path: '/media/search', name: 'media-search-results', component: MediaSearchResults},
  { path: '/media/:id', name: 'media-details', component: MediaItemDetails},
  { path: '/products/search', name: 'product-search-results', component: ProductSearchResults},
  { path: '/products/:id', name: 'product-details', component: ProductFeedItem},
  { path: '/checkout', name: 'checkout', component: Checkout},
]

const router = new Router({
  mode: 'history',
  base: '/home',
  routes, // short for `routes: routes`
})

Vue.filter('localNumber', function (value) {
    if (!value) return ''
    return Number(value).toLocaleString()
})

// use a method here to listen for an event posted from the component!
new Vue({
  el: '#app',
  data: {
    json: {},
    newRating: '',
    rating: 0,
    body: '',
    title: '',
    errors: new Error(),
  },
  router,
  store,
  components: {
    interests,
    home,
    InterestsSelect,
  },
  methods: {
    setJson (payload) {
      this.json = payload
    },
  },
})

if (['local', 'stage', 'staging'].includes(window.env)) {
  require('./staging')
}

const adjustNavPosition = function () {
  const windowWidth = $(window).width();

  if (windowWidth < 992) {
    $('#nav').appendTo('#mobile-nav-container')
  } else {
    $('#nav').appendTo('#desktop-nav-container')
  }
};

$(function () {
  let movedItemBox = false;

  $('body').on('mousedown', '.item-box', function (e) {
    movedItemBox = false;
  }).on('mousemove', '.item-box', function (e) {
    movedItemBox = true;
  }).on('mouseup', '.item-box', function (e) {
    if (!movedItemBox && e.which == 1) {
      const $this = $(this);
      const title = $this.find('.prodlink:first');
    
      if ($(window).width() < 576 && $(e.target).offset().top >= title.offset().top) {
        window.location.href = title.attr('href');
      }
    }

    movedItemBox = false;
  });
});
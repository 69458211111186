var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c("div", { staticClass: "d-flex mb-1 justify-content-between" }, [
            _c("p", [_vm._v("Invite to " + _vm._s(_vm.group.topic))]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "modal-default-button text-muted",
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("\n                        Close\n                    ")]
            )
          ]),
          _vm._v(" "),
          !_vm.loading
            ? _c("div", { staticClass: "flex" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userSearch,
                      expression: "userSearch"
                    }
                  ],
                  staticClass: "form-control w-100",
                  attrs: { placeholder: "Search" },
                  domProps: { value: _vm.userSearch },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.userSearch = $event.target.value
                      },
                      function($event) {
                        return _vm.searchUsers($event.target.value)
                      }
                    ]
                  }
                }),
                _vm._v(" "),
                _vm.userSearch.length === 0
                  ? _c("div", { staticClass: "mt-3" }, [
                      _vm._v(
                        "\n                        Type to Search\n                    "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "py-2" },
                  _vm._l(_vm.users.slice(0, 10), function(u) {
                    return _c("div", {}, [
                      _c("div", { staticClass: "d-flex mb-1" }, [
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "invite-button",
                              on: {
                                click: function($event) {
                                  return _vm.invite(u)
                                }
                              }
                            },
                            [_vm._v("+ invite")]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "ml-2 text-left flex-grow-1" },
                          [_vm._v(_vm._s(u.name))]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("img", {
                            staticStyle: { height: "20px" },
                            attrs: { src: u.avatar_url }
                          })
                        ])
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _c("div", [
                _vm._v("\n                    Loading...\n                ")
              ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
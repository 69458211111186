<template>
    <div>
        <!-- START OF FEED -->
        <div id="feed">
            <div id="root">
                <div id="profile-interests" v-if="product">
                    <div class="rvw_wraper box">
                        <router-link :to="{ name: 'home' }" class="back_btn">Back</router-link>

                        <div class="row">
                            <div class="col-xl-6 ">
                                <div class="d-flex">
                                    <div class="details_prod">
                                        <div class="tagname">{{ product.name }}</div>
                                        <div class="tagname" v-text="currentPriceDisplay"></div>
                                        <img
                                                v-if="product.photos && product.photos[0]"
                                                :src="'/storage/product_images/'+product.photos[0].server_path"
                                                class="prothumb"
                                                style="max-height: 400px;"
                                        />
                                    </div>
                                    <div class="rv_box">
                                        <div class="rvrate">
                                            <div class="avg-rating font-weight-bolder">
                                                {{ product.average_rating > 5 ? 5 : product.average_rating }}
                                                <i class="fa fa-star text-warning"></i>
                                                <span v-if="product.average_rating > 5">
                                                    + <i class="fa fa-moon text-warning"
                                                        style="transform: rotate(200deg);"></i>!
                                                </span>
                                            </div>
                                            <!-- <span style="font-weight:bolder">{{ product.average_rating }}</span> -->
                                            <div class="stars-box d-flex flex-column-reverse">
                                                <span v-for="star in 5" :key="star">
                                                    <img
                                                            v-if="star > product.average_rating"
                                                            class="icons irate"
                                                            src="/images/star2.png"
                                                    />
                                                    <img v-else class="icons irate" src="/images/star1.png"/>
                                                </span>
                                                <img class="icons irate mb-2" :src="[product.average_rating > 5 ? '/images/moon-filled.png' : '/images/moon.png']"/>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- rvrate -->
                                </div>
                                <!-- rv_box -->

                                <div v-if="product.variations.length > 0" class="cart_box">
                                    <div>
                                        <section id="attributes-section" :key="attributesKey">
                                            <select class="form-control mb-2 select2-control" v-for="attribute in variableAttributes"
                                                :key="attribute.id" v-model="selectedOptions[attribute.name]"
                                                :data-placeholder="attribute.label"
                                                :data-attribute="attribute.name">
                                                <!-- <option selected disabled v-text="attribute.label"></option> -->
                                                <option v-for="(value, index) in getAttributeValues(attribute)"
                                                    :key="index * value.length" v-text="value" :value="value"
                                                    @change="updateOptions">
                                                </option>
                                            </select>
                                        </section>

                                        <h5 class="font-weight-normal mb-3" v-if="shippingDays">
                                            Usually ships within {{ shippingDays }} days
                                        </h5>
                                        <ul v-html="productDescription"></ul>
                                    </div>
                                    <!-- qty_cart -->
                                </div>

                                <div v-if="product.imported_from_amazon && !product.amz_fullfillment_enabled" class="cart_box flex-column text-center">
                                    <div class="p-2" style="font-size: 1.1rem;">
                                        We've had so many orders we're currently out of stock, buy on Amazon Here:
                                    </div>
                                    <a class="btn btn-primary btn-lg cart m-0" :href="product.sellers_website">Order from Amazon - We've temporarily sold our stock</a>
                                </div>
                                <div class="cart_box" v-else>
                                    <ul v-html="productDescription"></ul>
                                    <div class="qty_cart">
                                        <div class="form-group">
                                            <label class="h5 text-dark" for="quantity">Qty:</label>
                                            <input class="form-control" v-model="productQty" id="quantity">
                                        </div>

                                        <div @click="addToCart" class="cart m-0" id="add-to-cart">
                                            <i :class="addToCartIcon"></i>
                                            add to cart
                                        </div>
                                    </div>
                                </div>
                                <!-- cart_box -->
                                
                                <!-- Referral Link -->
                                <section id="referral-section" v-show="product.can_refer">
                                <hr class="mb-2"/>
                                    <button type="button" id="get-referral" class="btn btn-secondary btn-lg btn-block mt-1 mb-1"
                                        @click="getReferralLink($event)">
                                        <!-- Can be changed to <i :class="referralBtnIcon"></i> if needed-->
                                        <i class="fa fa-link"></i>
                                        Get partner link for this product
                                    </button>
                                    <h6 class="font-weight-bold mb-3">
                                        *Partner links let you make money by posting off-rRipple
                                    </h6>
                                </section>
                                <!-- /Referral Link -->

                                <section
                                    class="product-video-container"
                                    v-if="product.youtube_video_url || product.can_edit"
                                >
                                    <h3>Seller Added Video:</h3>

                                    <div class="product-video" v-if="product.youtube_video_url">
                                        <youtube
                                            :video-id="getIdFromYouTubeURL(product.youtube_video_url)"
                                            player-width="100%"
                                        ></youtube>

                                        <div class="text-right" v-if="isAdmin">
                                            <a href @click.prevent="adminDeleteVideo()">Admin Delete Video</a>
                                        </div>
                                    </div>
                      
                                    <div v-if="product.can_edit">
                                        <input
                                            class="product-video-input"
                                            v-model="videoUrl"
                                            placeholder="Add video to this Page with YouTube URL..."
                                        />

                                        <div class="text-right">
                                            <button @click.prevent="submitVideo()" class="button-submit-video">Submit Video</button>
                                        </div>
                                    </div>
                                </section>

                                <section class="product-video-container">
                                    <h1 class="h2">Community Added Videos</h1>

                                    <div v-if="!product.community_videos.length">No videos yet.</div>

                                    <div class="community-videos-overflow">
                                        <div v-for="video in product.community_videos" :key="video.id">
                                            <youtube
                                                :video-id="getIdFromYouTubeURL(video.url)"
                                                player-width="100%"
                                            ></youtube>

                                            <div class="text-right">
                                                <a href @click.prevent="flagVideoAsInappropriate(video)">Flag as inappropriate</a>
                                                <span v-if="isAdmin">|</span>
                                                <a v-if="isAdmin" href @click.prevent="adminDeleteCommunityVideo(video)">Admin Delete Video</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="!product.can_edit && product.community_videos.length < 3"
                                        class="add-community-video-container"
                                    >
                                        <input
                                            class="product-video-input"
                                            v-model="communityVideoUrl"
                                            placeholder="Add video to this Page with YouTube URL..."
                                        />

                                        <div class="text-right">
                                            <button @click.prevent="submitCommunityVideo()" class="button-submit-video">Submit Video</button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <!-- end col-xl-6 -->

                            <div class="col-xl-6">
                                <div class="Review_overflow">
                                    <h3 class="m-0 d-lg-none">Reviews</h3>
                                    <div class="review_flexbox mb-3 pt-0 d-lg-none"></div>
                                    <div class="text-center" v-if="product.reviews.length === 0">
                                        <p class="py-10">No reviews for this Product yet. Be the first!</p>
                                    </div>
                                    <div v-for="r in product.reviews.slice(0,reviewLimit)" class="review_flexbox">
                                        <div class="col-md-4 p-0">
                                            <div class="rr-badge-container">
                                                <div class="review_star font-weight-bolder">
                                                    {{ r.rating > 5 ? 5 : r.rating }}
                                                    <i class="fa fa-star text-warning"></i>
                                                    <span v-if="r.rating > 5">
                                                        + <i class="fa fa-moon text-warning"
                                                            style="transform: rotate(200deg);"></i>!
                                                    </span>
                                                </div>
                                                <div v-if="r.user && isInfluencer(r.user)" class="rr-badge">rR</div>
                                                <img v-if="r.user" class="userimg" :src="getUserPhoto(r.user)"/>
                                                <img v-else class="userimg" src="/images/avatar.png"/>
                                            </div>

                                            <div>
                                                <strong v-if="r.user">{{ r.user.name }}</strong>
                                                <strong v-else>Anonymous</strong>
                                                <div v-if="r.user && isInfluencer(r.user)" class="influencer-badge-container">
                                                    <InfluencerBadge></InfluencerBadge>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div>
                                                <i v-if="r.user">{{ r.user.name.split(' ')[0] }} says:</i>
                                                <i v-else>Anonymous says:</i>
                                            </div>
                                            <p>
                                                {{ r.content }}
                                            </p>
                                        </div>
                                    </div>

                                    <div v-if="product.reviews.length > reviewLimit"
                                         class="d-flex justify-content-center my-2">
                                        <button @click="reviewLimit = reviewLimit + 10" class="button">see more
                                            reviews
                                        </button>
                                    </div>
                                    <!-- row review_flexbox -->
                                </div>
                                <!-- Review_overflow -->

                                <div v-if="product.friend_reviews.length">
                                    <div class="frd_viewer">
                                        <img
                                                v-for="(item3,index2) in product.friend_reviews"
                                                :key="index2"
                                                class="icons"
                                                v-if="index2 <= 6"
                                                :src="getUserPhoto(item3.user)"
                                        />

                                        <a v-if="product.friend_reviews.length > 6" href>
                                            <b class="counterFriends">+{{ product.friend_reviews.length - 6 }}</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- end col-xl-6 -->
                        </div>
                        <!-- end row -->
                    </div>
                    <div v-if="product.can_edit" class="rvw_wraper box mt-3 text-left" style="font-size: 1rem">
                        <h3 class="my-3">Manage Product</h3>

                        <h5 class="featurette-heading">
                            Existing variations
                        </h5>

                        <div class="table-responsive-md">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Price (USD)</th>
                                    <th>Weight (Oz)</th>
                                    <th>Time to ship (days)</th>
                                    <th>Count</th>
                                    <th>Image name</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="variant in product.variations">
                                    <td> {{ variant.name }}</td>
                                    <td> {{ variant.price_cents/100 }}</td>
                                    <td> {{ variant.ship_weight_oz }}</td>
                                    <td> {{ variant.ship_time_days }}</td>
                                    <td> {{ variant.count }}</td>
                                    <td> {{ variant.image_path }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <h5 class="featurette-heading">
                            Create Variant
                        </h5>

                        <form class="mb-3" action="/create_variant" method="post" enctype="multipart/form-data">

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="name">Name</label>
                                        <input type="text" class="form-control" placeholder="Variant Name"
                                               name="variant_name">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="Price">Price (Dollars and Cents)</label>
                                        <input type="number" step="any" class="form-control" placeholder="Price"
                                               name="price">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="weight">Weight (Oz)</label>
                                        <input type="number" class="form-control" placeholder="Weight" name="weight">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="TimeToShip">Time to Ship (days)</label>
                                        <input type="number" class="form-control" placeholder="Days"
                                               name="time_to_ship">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="Count">Variant Count</label>
                                        <input type="number" class="form-control" placeholder="Count"
                                               name="variant_count">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="Photos">Choose variant image</label>
                                        <input type="file" class="form-control-file" name="variant_photo[]" required/>
                                    </div>
                                </div>
                            </div>
                            <input type="hidden" name="_token" :value="csrf()">
                            <input type="hidden" :value="product.id" name="product_id"/>
                            <input type="submit" class="button" value="Add Variant"/>
                        </form>
                    </div>
                    <div class="rvw_wraper box mt-3 text-left" style="font-size: 1rem">
                        <div v-if="product.can_review">
                            <h3 class="my-3">Add Review</h3>
                            <div class="form-group">
                                <rating :grade="0"
                                        :max-stars="5"
                                        :moon-ctive="false"
                                        @rating-set="reviewForm.newRating = $event"
                                >
                                </rating>
                            </div>

                            <div class="form-group">
                                <label for="title">Title</label>
                                <input type="text" class="form-control" id="title"
                                       placeholder="Awesome title"
                                       name="title" v-model="reviewForm.title">
                            </div>

                            <div class="form-group">
                                <label for="body">Content</label>
                                <textarea class="form-control" id="body" name="body"
                                          v-model="reviewForm.body"></textarea>
                            </div>

                            <div class="form-group">
                                <label>
                                    <input type="checkbox" v-model="reviewForm.is_private">
                                    Make review anonymous
                                </label>
                            </div>

                            <div class="form-group">
                                <button @click="submitReview" class="button">Add Review</button>
                            </div>
                        </div>

                        <div v-if="product.user_review">
                            <h3 class="my-3">Edit Review</h3>
                            <div class="form-group">
                                <label>
                                    <input type="checkbox" v-model="product.user_review.is_private">
                                    Make review anonymous
                                </label>
                            </div>
                            <div class="form-group">
                                <button @click="updateReview(product.user_review.is_private)" class="button">Update Review</button>
                            </div>
                        </div>
                    </div>
                    <!-- end box -->
                </div>
            </div>
        </div>
        
        <referral-modal :link="referralLink" v-if="product"></referral-modal>
        <h4 class="text-white p-5" v-if="!product" v-text="noProductText"></h4>
        <!-- END OF FEED -->
    </div>
</template>
<script>
import InfluencerBadge from './InfluencerBadge';
import collect from 'collect.js';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap.css';
import NProgress from 'nprogress/nprogress';
import 'nprogress/nprogress.css';
import {
    isInfluencer
} from '../services/user';
import {
    getIdFromURL as getIdFromYouTubeURL
} from "vue-youtube-embed";
require('../notify');
const numeral = require('numeral');

export default {
    name: 'ProductFeedItem',
    // props: ['product'],
    components: {
        InfluencerBadge
    },
    mounted() {
        NProgress.configure({ parent: 'body' });
        // NProgress.start();
        axios.get(`/products/${this.$route.params.id}`)
            .then(({ data }) => {
                this.product = data.product;
                this.currentPrice = numeral(this.product.product_price).format('0,0.00');
                this.variableAttributes.each(attribute => {
                    this.selectedOptions[attribute.name] = null;
                });
                
                const that = this;
                $('.select2-control').select2({
                    theme: 'bootstrap',
                    containerCssClass: 'mb-2',
                    allowClear: true,
                });

                $('.select2-control').on('select2:select', function () {
                    const select = $(this);
                    const otherAttrsSelects = $('.select2-control').not(this);
                    that.selectedOptions[select.data('attribute')] = $(this).find('option:selected').val();

                    $.each(otherAttrsSelects, function () {
                        const otherSelect = $(this);
                        const attribute = $(this).data('attribute');
                        const options = $(this).find('option');
                        const filtered = that.getAttributeValues(attribute).toArray();
                                                                    let i = 0;
                        $(options).each((index, option) => {
                                                                    if (!filtered.includes($(option).val())) {
                                $(option).prop('disabled', true);
                                                } else {
                                $(option).prop('disabled', false)
                            }

                            i++;
                        })

                        otherSelect.trigger('change');
                    });

                    const allSelected = that.validateAttributesSelected(false);

                    if (allSelected) {
                        const variation = that.variation;

                        that.currentPrice = numeral(variation.price).format('0,0.00');
                        that.shippingDays = variation.shipping_days;
                    } else {
                        that.currentPrice = numeral(that.product.product_price).format('0,0.00');
                        that.shippingDays = null;
                    }
                }).on('select2:clear', function () {
                    that.variableAttributes.each(variableAttribute => {
                        that.selectedOptions[variableAttribute.name] = null;
                    });

                    $(this).trigger('select2:select');
                });
            }).catch(() => {
                this.noProductText = 'Product Not Found';
            }).finally(() => {
                // NProgress.done();
            });

    },
    data() {
        return {
            product: null,
            isAdmin: window.isAdmin,
            videoUrl: '',
            communityVideoUrl: '',
            productQty: 1,
            reviewLimit: 3,
            start: 1,
            reviewForm: {
                title: null,
                body: null,
                newRating: null,
                is_private: false,
            },
            referralLink: null,
            addToCartIcon: 'fa fa-cart-plus',
            selectedOptions: {},
            attributesKey: 1,
            currentPrice: '0,00',
            shippingDays: null,
            noProductText: 'Loading...',
        }
    },
    computed: {
        productDescription() {
            const description = this.product.description;

            if (description === null || description === undefined || description == 0) {
                return "<li>This product does not have a description yet.</li>"
            }

            return "<li>" + this.product.description.split('<br/>').join('</li></li>') + "</li>";
        },
        variableAttributes() {
            return collect(this.product.variable_attributes);
        },
        variations() {
            return collect(this.product.variations);
        },
        variationsAttributes() {
            return collect(this.product.variations_attributes);
        },
        variation() {
            return this.getVariation();
        },
        currentPriceDisplay() {
            return '$' + this.currentPrice;
        }
    },
    methods: {
        getAttributeValues(attribute) {
            const name = attribute instanceof Object ? attribute.name : attribute;
            const variableAttributes = this.variableAttributes.where('name', '!=', name);
                const variations = this.variations.filter(variation => {
                let take = true;
                const attributes = collect(variation.attributes);

                variableAttributes.each(otherAttribute => {
                    const selectedAttributeValue = this.selectedOptions[otherAttribute.name];
                   
                    const otherAttrResults = attributes
                        .where('name', otherAttribute.name)
                        .where('value', this.selectedOptions[otherAttribute.name]);
                                       if (otherAttrResults.isEmpty() && this.selectedOptions[otherAttribute.name]) {
                        take = false;
                    }
                });

                return !attributes.where('name', name).isEmpty() && take;
            });
            let values = variations.map(variation => {
                return collect(variation.attributes).where('name', name).pluck('value').unique();
            }).flatten().unique();

            
            return values;
        },
        updateOptions() {
            this.attributesKey += 1;
        },
        getIdFromYouTubeURL,
        isInfluencer,
        flagVideoAsInappropriate(video) {
            alert('Not implemented yet')
        },
        adminDeleteVideo() {
            let shouldDelete = confirm('Are you sure you want to delete the video?')

            if (shouldDelete) {
                axios.delete(`/products/${this.product.id}/video`).then(res => {
                    location.reload()
                }).catch(error => {
                    const errors = Object.values(error.response.data.errors).map(err => err[0]).join('\r\n')
                    window.alert(`Errors: ${errors}`)
                })
            }
        },
        adminDeleteCommunityVideo(video) {
            let shouldDelete = confirm('Are you sure you want to delete the video?')

            if (shouldDelete) {
                axios.delete(`/products/${this.product.id}/community-video/${video.id}`).then(res => {
                    location.reload()
                }).catch(error => {
                    const errors = Object.values(error.response.data.errors).map(err => err[0]).join('\r\n')
                    window.alert(`Errors: ${errors}`)
                })
            }
        },
        submitCommunityVideo() {
            let video = {
                url: this.communityVideoUrl
            }

            axios.post(`/products/${this.product.id}/community-video`, video).then(res => {
                location.reload()
            }).catch(error => {
                const errors = Object.values(error.response.data.errors).map(err => err[0]).join('\r\n')
                window.alert(`Errors: ${errors}`)
            })
        },
        submitVideo() {
            let video = {
                youtube_video_url: this.videoUrl
            }

            axios.post(`/products/${this.product.id}/video`, video).then(res => {
                location.reload()
            }).catch(error => {
                const errors = Object.values(error.response.data.errors).map(err => err[0]).join('\r\n')
                window.alert(`Errors: ${errors}`)
            })
        },
        addFriend: function () {
            this.isAddFriend = true
        },
        addToCart: function () {
            const valid = this.validateAttributesSelected();

            if (!valid) {
                return;
            }

            const variation = this.getVariation();

            const referral = this.getQueryStringValue('referral');
            // If the product cannot be add to cart
            if (!this.product.can_add_to_cart) {
                // Check whether a referral id exists in the query string
                if (this.$route.query != null) {
                    // If it does, attach the referral id to the login URL and redirect the user
                    // to it.
                    return window.location.href = '/login/?referral=' + referral;
                }

                // Else, redirect to the login page without any query string.
                return window.location.href = '/login';
            }

            const defaultIcon = this.addToCartIcon;
            this.addToCartIcon = 'fa fa-circle-notch spin';

            $('#add-to-cart').addClass('disabled');

            window.axios.post(this.getAddToCartUrl(referral), {
                    quantity: this.productQty
                })
                .then(response => {
                    const data = response.data;

                    if (data.success) {
                        return window.location.href = '/home/cart'

                        if (response.message) {
                            $.notify(response.message, 'success');
                        }
                        return;
                    }

                    let message = data.message ?
                        data.message :
                        'An error has occured. Please refresh the page and try again.';

                    // Display the error message
                    $.notify(message, 'error');
                }).catch(error => {
                    // Look for validation errors
                    if (typeof error == Object) {
                        const errors = Object.values(error.response.data.errors)
                            .map(err => err[0]).join('\r\n');

                        return $.notify(`Errors: ${errors}`, 'error');
                    }

                    // We do not need to inform the user of any other errors.
                    $.notify('An error has occured. Please refresh the page and try again.', 'error');
                }).finally(() => {
                    $('#add-to-cart').removeClass('disabled');
                    this.addToCartIcon = defaultIcon;
                })
        },
        getVariation() {
            let variation = this.variations.filter(variation => {
                const attributes = collect(variation.attributes);
                let yes = true;
                // has attribute of name: "name"
                // equal to value: "value"
                this.variableAttributes.each(attr => {
                    const name = attr.name;
                    const value = this.selectedOptions[name];

                    if (attributes.where('name', name).where('value', value).isEmpty()) {
                        yes = false;
                    }
                });

                return yes;
            }).first();

            return variation;
        },
        validateAttributesSelected(notify = true) {
            let yes = true;

            this.variableAttributes.each(variableAttribute => {
                const selectedValue = this.selectedOptions[variableAttribute.name];
            
                if (!selectedValue) {
                    if (notify) {
                        $.notify(`Please select ${variableAttribute.label}`, 'error');
                    }

                    yes = false;
                }
            });

            return yes;
        },
        getAddToCartUrl(referral = null) {
            let url = `/api/cart/add/${this.product.id}/`;

            if (this.variation) {
                url += this.variation.id;
            }

            if (referral != null) {
                url += '?referral=' + referral;
            }

            return url;
        },
        csrf() {
            return window.token
        },
        submitReview() {
            axios.post('/products/' + this.product.id + '/review', this.reviewForm).then(res => {
                location.reload()
            }).catch(error => {
                const errors = Object.values(error.response.data.errors).map(err => err[0]).join('\r\n')
                window.alert(`Errors: ${errors}`)
            })
        },
        updateReview(isPrivate) {
            axios.patch('/products/' + this.product.id + '/review', {
                is_private: isPrivate
            }).then(res => {
                location.reload()
            }).catch(error => {
                const errors = Object.values(error.response.data.errors).map(err => err[0]).join('\r\n')
                window.alert(`Errors: ${errors}`)
            })
        },
        getReferralLink(event) {
           
            const button = $(event.target);
            let self = this;

            if (this.referralLink !== null) {
                return $('#referral-modal').modal('show');
            }

            button.attr('disabled', true);
            button.find('i.fa').removeClass('fa-link').addClass('fa-circle-notch spin');

            axios
                .get("/products/" + self.product.id + "/referrals/link")
                .then(response => {
                    self.referralLink = response.data.link;

                    $('#referral-modal').modal('show');
                })
                .finally(() => {
                    button.attr('disabled', false);
                    button.find('i.fa').removeClass('fa-circle-notch spin').addClass('fa-link');
                });
        },

        getQueryStringValue(key) {
            return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
        },
        getUserPhoto(user) {
        if (!user) {
            return '/images/avatar.png';
        }

        if (user.avatar) {
            return `/storage/images/${user.avatar}`;
        }

        if (user.facebook_profile_pic_url) {
            return user.facebook_profile_pic_url;
        }

        return '/images/avatar.png';
        },
    },
}
</script>
<style scoped>
    .back_btn {
        display: block;
    }

    .cart {
        background-color: rgb(0, 174, 255);
    }

    .variant-select {
        font-size: 1.2em;
    }

    @media screen and (min-width: 576px) {
        .variant-select {
            font-size: 1.5em;
        }
    }
    
    @media screen and (min-width: 576px) {
        .variant-select select {
            min-width: 200px;
        }   
    }

    .userimg {
        height: 140px;
        width: 140px;
        margin-bottom: 10px;
    }

    .frd_viewer {
        margin-top: 0;
        padding: 15px;
        font-size: 24px;
        
    }

    @media screen and (min-width: 576px) {
        .frd_viewer {
            border-left: solid gray 1px;
        }
    }

    .influencer-badge-container {
        line-height: 1;
    }

    .product-video-container {
        margin-top: 20px;
        text-align: left;
        padding-bottom: 20px;
    }

    .product-video-container .h2 {
        font-size: 18px;
    }

    @media screen and (min-width: 576px) {
        .product-video-container {
            margin-top: 0;
        }
    }

    .product-video-input {
        padding: 0 10px;
        font-size: 16px;
        line-height: 38px;
        border-radius: 5px;
        box-shadow: none;
        border: 2px solid #2b2b2b;
        width: 100%;
    }

    .button-submit-video {
        background-color: #343434;
        border-radius: 5px;
        color: white;
        font-size: 15px;
        padding: 10px;
        cursor: pointer;
        margin-top: 15px;
    }

    .product-video {
        padding: 15px 0;
    }

    .community-videos-overflow {
        max-height: 450px;
        overflow-y: auto;
    }

    .add-community-video-container {
        margin-top: 15px;
    }

    .rvw_wraper .table th {
        min-width: 100px;

    }
    @media screen and (min-width: 576px) {
        .rvw_wraper .table th {
            min-width: auto;
        }
    }

    .rr-badge {
        top: inherit;
        left: inherit;
    }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container pr-lg-1 pl-lg-1",
      attrs: { id: "profile-interests" }
    },
    [
      _c("div", { staticClass: "row pr-lg-5 pl-lg-5" }, [
        _c(
          "div",
          { staticClass: "col-lg-4 col-sm-12 mx-sm-auto mb-2 mb-md-0" },
          [
            !_vm.settingUp
              ? _c("div", { staticClass: "card box-card" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body pl-0" }, [
                    _vm.user
                      ? _c("div", { staticClass: "profile-info" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row m-0 ml-3 flex-column flex-xl-row justify-content-center\n              align-items-center align-items-xl-start justify-content-xl-start"
                            },
                            [
                              _c("img", {
                                staticClass: "profile-picture img-reponsive",
                                attrs: {
                                  alt: _vm.user.name,
                                  src: _vm.getUserPhoto(_vm.user)
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "thumbnail-link ml-lg-3 mt-2 mt-xl-5",
                                  on: {
                                    click: function($event) {
                                      _vm.$("#picture-modal").modal("show")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  Upload New Thumbnail\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row align-items-center align-items-lg-start flex-column m-0 ml-3 mt-lg-3 font-weight-bolder"
                            },
                            [
                              _c("div", { staticClass: "field" }, [
                                _vm.editing.name
                                  ? _c("section", [
                                      _c(
                                        "div",
                                        { staticClass: "form-group m-0" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.update.name,
                                                expression: "update.name"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Name",
                                              disabled: _vm.editing.name === 1
                                            },
                                            domProps: {
                                              value: _vm.update.name
                                            },
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.updateName()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.update,
                                                  "name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary btn-sm",
                                          attrs: {
                                            type: "button",
                                            disabled: _vm.editing.name === 1
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateName()
                                            }
                                          }
                                        },
                                        [
                                          _vm.editing.name === 1
                                            ? _c("i", {
                                                staticClass:
                                                  "fa fa-circle-notch spin"
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                    Update\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.editing.name !== 1
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-secondary btn-sm",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function($event) {
                                                  _vm.editing.name = false
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-times"
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  : _c("section", [
                                      _c("p", {
                                        domProps: {
                                          textContent: _vm._s(_vm.user.name)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "fa fa-edit",
                                        on: {
                                          click: function($event) {
                                            _vm.editing.name = true
                                          }
                                        }
                                      })
                                    ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "field mt-xl-2" }, [
                                _vm.editing.email
                                  ? _c("section", [
                                      _c(
                                        "div",
                                        { staticClass: "form-group m-0" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.update.email,
                                                expression: "update.email"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "email",
                                              placeholder: "Email",
                                              disabled: _vm.editing.email === 1
                                            },
                                            domProps: {
                                              value: _vm.update.email
                                            },
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.updateEmail()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.update,
                                                  "email",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.editing.email !== 1
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-sm",
                                              attrs: {
                                                type: "button",
                                                disabled:
                                                  _vm.editing.email === 1
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateEmail()
                                                }
                                              }
                                            },
                                            [
                                              _vm.editing.email === 1
                                                ? _c("i", {
                                                    staticClass:
                                                      "fa fa-circle-notch spin"
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                    Update\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-secondary btn-sm",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              _vm.editing.email = false
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-times"
                                          })
                                        ]
                                      )
                                    ])
                                  : _c("section", [
                                      _c("p", {
                                        staticClass: "mt-0",
                                        domProps: {
                                          textContent: _vm._s(_vm.user.email)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "fa fa-edit",
                                        on: {
                                          click: function($event) {
                                            _vm.editing.email = true
                                          }
                                        }
                                      })
                                    ])
                              ])
                            ]
                          )
                        ])
                      : _c(
                          "div",
                          { staticClass: "profile-info-loader" },
                          [
                            _c(
                              "vue-content-loading",
                              { attrs: { width: 300, height: 150 } },
                              [
                                _c("rect", {
                                  attrs: {
                                    x: "20",
                                    y: "0",
                                    rx: "4",
                                    ry: "4",
                                    width: "100",
                                    height: "100"
                                  }
                                }),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    x: "130",
                                    y: "50",
                                    rx: "4",
                                    ry: "4",
                                    width: "150",
                                    height: "14"
                                  }
                                }),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    x: "20",
                                    y: "110",
                                    rx: "4",
                                    ry: "4",
                                    width: "300",
                                    height: "14"
                                  }
                                }),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    x: "20",
                                    y: "134",
                                    rx: "4",
                                    ry: "4",
                                    width: "300",
                                    height: "14"
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _c("hr", { staticClass: "mb-2 mt-2" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "referral-box text-left pl-3 pt-2" },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _vm._m(2),
                        _vm._v(" "),
                        _vm._m(3),
                        _vm._v(" "),
                        _vm._m(4),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n              *Becoming an influencer requires inviting at least 15 users that make a purchase and $2000 sold via external links.\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary btn-lg btn-block",
                            attrs: {
                              type: "button",
                              id: "registration-referral-link"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getReferralLink($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-link" }),
                            _vm._v(
                              "\n              click to get partner link\n            "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              : _c("div", [
                  _c(
                    "h3",
                    {
                      staticClass: "bg-white p-3 mb-3 sidebox font-weight-bold"
                    },
                    [_vm._v("\n          add at least 3 interests\n        ")]
                  ),
                  _vm._v(" "),
                  _vm._m(5)
                ]),
            _vm._v(" "),
            _vm.user
              ? _c("div", { staticClass: "p-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block btn-lg",
                      staticStyle: {
                        "background-color": "rgb(0, 174, 255)",
                        border: "4px solid white",
                        "font-size": "16px",
                        "font-weight": "600"
                      },
                      attrs: {
                        disabled:
                          _vm.user.is_seller || _vm.seller_request_loading
                      },
                      on: { click: _vm.makeSeller }
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.seller_request_loading,
                            expression: "seller_request_loading"
                          }
                        ],
                        staticClass: "fa fa-circle-notch spin"
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v("Become A Seller -")]),
                      _vm._v(" "),
                      _c("p", [_vm._v("Click to Convert to a Seller")]),
                      _vm._v(" "),
                      _c("p", [_vm._v("Account & Sell Products")]),
                      _vm._v(" "),
                      _c("span", [_vm._v("on rRipple")]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-light" }, [
                        _vm._v("(Seller Acct's are Free)")
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              _vm.tabview == "addinterest"
                ? "col-8 col-lg-6 mx-sm-auto mb-2 mb-md-0 p-0"
                : "col-12 col-lg-8 mx-sm-auto mb-2 mb-md-0 p-0"
            ]
          },
          [
            _c("div", { staticClass: "card box-card interests-container" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm.tabview == "myinterest"
                  ? _c(
                      "span",
                      {
                        staticClass: "text-pointer",
                        on: {
                          click: function($event) {
                            _vm.tabview = "addinterest"
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-plus-circle",
                          staticStyle: {
                            "font-size": "20px",
                            color: "rgb(0, 174, 255)",
                            "-webkit-text-stroke-width": ".5px",
                            "-webkit-text-stroke-color": "black"
                          }
                        }),
                        _vm._v(" add interests\n          ")
                      ]
                    )
                  : _vm.tabview === "addinterest" && _vm.settingUp
                  ? _c(
                      "span",
                      {
                        staticClass: "text-pointer",
                        on: {
                          click: function($event) {
                            _vm.tabview = "myinterest"
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-plus-circle",
                          staticStyle: {
                            "font-size": "20px",
                            color: "rgb(0, 174, 255)",
                            "-webkit-text-stroke-width": ".5px",
                            "-webkit-text-stroke-color": "black"
                          }
                        }),
                        _vm._v(" add interests\n          ")
                      ]
                    )
                  : _c(
                      "span",
                      {
                        staticClass: "text-pointer",
                        on: {
                          click: function($event) {
                            _vm.tabview = "myinterest"
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-arrow-left",
                          staticStyle: {
                            "font-size": "20px",
                            color: "rgb(0, 174, 255)",
                            "-webkit-text-stroke-width": ".5px",
                            "-webkit-text-stroke-color": "black"
                          }
                        }),
                        _vm._v(" my interests\n          ")
                      ]
                    ),
                _vm._v(" "),
                _vm.tabview == "addinterest" && _vm.current_parent
                  ? _c(
                      "span",
                      {
                        staticClass: "text-pointer float-right",
                        on: { click: _vm.upLevel }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-arrow-up",
                          staticStyle: {
                            "font-size": "20px",
                            color: "rgb(0, 174, 255)",
                            "-webkit-text-stroke-width": ".5px",
                            "-webkit-text-stroke-color": "black"
                          }
                        }),
                        _vm._v(" Up a level\n          ")
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "card-body position-relative",
                  attrs: { id: "interests-container" }
                },
                [
                  _vm.tabview == "myinterest"
                    ? [
                        _c(
                          "div",
                          { staticClass: "row" },
                          _vm._l(_vm.selected_interests, function(
                            interest,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "interest-item col-lg-4 col-md-6 col-sm-12 p-0"
                              },
                              [
                                _c("div", { staticClass: "card" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card-body p-0 i-item" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-pointer float-right position-absolute",
                                          on: {
                                            click: function($event) {
                                              return _vm.removeUserInterest(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-times"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "img-responsive",
                                        staticStyle: {
                                          height: "70px",
                                          "margin-bottom": "5px"
                                        },
                                        attrs: {
                                          src: "/icons/" + interest.icon
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("h4", {
                                        domProps: {
                                          textContent: _vm._s(interest.name)
                                        }
                                      })
                                    ]
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    : _vm.tabview == "addinterest"
                    ? _c(
                        "div",
                        { staticClass: "interest-lists row" },
                        _vm._l(_vm.list, function(interest) {
                          return _c(
                            "div",
                            {
                              key: interest.id,
                              staticClass:
                                "interest-item col-lg-4 col-md-6 col-sm-12 p-0"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "card",
                                  on: {
                                    click: function($event) {
                                      return _vm.checkInterest(interest)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "card-body p-0 i-item" },
                                    [
                                      _c("img", {
                                        staticClass: "img-responsive",
                                        staticStyle: {
                                          height: "70px",
                                          "margin-bottom": "5px"
                                        },
                                        attrs: {
                                          src: "/icons/" + interest.icon
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("h4", {
                                        domProps: {
                                          textContent: _vm._s(interest.name)
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabview == "addinterest",
                expression: "tabview == 'addinterest'"
              }
            ],
            staticClass: "col-4 col-lg-2 mx-sm-auto p-0 pl-md -2"
          },
          [
            _c("div", { staticClass: "card box-card interests-container" }, [
              _vm._m(6),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "selected-interest-lists row p-0" },
                  _vm._l(_vm.tobe_saved, function(interest) {
                    return _c(
                      "div",
                      { key: interest.id, staticClass: "interest-item p-0" },
                      [
                        _c("div", { staticClass: "card p-0" }, [
                          _c("div", { staticClass: "card" }, [
                            _c("div", { staticClass: "card-body p-0" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-pointer float-right position-absolute",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeSelected(_vm.index)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-times" })]
                              ),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "img-responsive",
                                staticStyle: {
                                  height: "70px",
                                  "margin-bottom": "5px"
                                },
                                attrs: { src: "/icons/" + interest.icon }
                              }),
                              _vm._v(" "),
                              _c("h4", {
                                domProps: { textContent: _vm._s(interest.name) }
                              })
                            ])
                          ])
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", {}, [
              _c(
                "button",
                {
                  staticClass:
                    "button button-p7 float-right btn-lg submit-new-interests w-100",
                  attrs: { disabled: !this.tobe_saved.length },
                  on: { click: _vm.submitInterest }
                },
                [_vm._v("submit new interests")]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("referral-modal", { attrs: { link: _vm.referralLink } }),
      _vm._v(" "),
      _vm._m(7),
      _vm._v(" "),
      _vm.user
        ? _c(
            "div",
            {
              staticClass: "modal fade",
              attrs: {
                id: "picture-modal",
                tabindex: "-1",
                role: "dialog",
                "aria-hidden": "true"
              }
            },
            [
              _c(
                "div",
                { staticClass: "modal-dialog", attrs: { role: "document" } },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-body row m-0 ml-3 flex-column flex-xl-row justify-content-center\n              align-items-center align-items-xl-start justify-content-xl-start"
                      },
                      [
                        _c("img", {
                          staticClass: "profile-picture img-reponsive",
                          attrs: {
                            id: "picture-preview",
                            alt: _vm.user.name,
                            src: _vm.getUserPhoto(_vm.user)
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "h5",
                          {
                            staticClass: "thumbnail-link ml-lg-3 mt-2 mt-xl-5",
                            on: {
                              click: function($event) {
                                _vm.$("#picture-input").trigger("click")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            Browse for New Pic\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "d-none",
                          attrs: {
                            name: "picture-input",
                            id: "picture-input",
                            type: "file"
                          },
                          on: {
                            change: function($event) {
                              return _vm.previewPicture($event.target)
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-block",
                          attrs: {
                            type: "button",
                            name: "",
                            id: "",
                            disabled: _vm.editing.picture === 1
                          },
                          on: {
                            click: function($event) {
                              return _vm.updatePicture()
                            }
                          }
                        },
                        [
                          _vm.editing.picture === 1
                            ? _c("i", {
                                staticClass: "fa fa-circle-notch spin"
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n            Submit New Profile Pic\n          "
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("span", { staticClass: "text-pointer" }, [
        _c("i", {
          staticClass: "fa fa-plus-circle",
          staticStyle: {
            "font-size": "20px",
            color: "rgb(0, 174, 255)",
            "-webkit-text-stroke-width": ".5px",
            "-webkit-text-stroke-color": "black"
          }
        }),
        _vm._v(" my info & settings\n          ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("i", {
        staticClass: "fa fa-plus-circle",
        staticStyle: {
          "font-size": "20px",
          color: "rgb(0, 174, 255)",
          "-webkit-text-stroke": "0.5px black"
        }
      }),
      _vm._v("\n              money made by inviting others\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n              - Users invited that made their first purchase:\n              "
      ),
      _c("span", { attrs: { id: "referral-users" } }, [_vm._v("0")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n              - Dollars of sales from externally placed partner links:\n              "
      ),
      _c("span", { attrs: { id: "referral-external-sales" } }, [_vm._v("0")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n              - Dollars of sales from influencer reviews and posts:\n              "
      ),
      _c("span", { attrs: { id: "referral-influencer" } }, [_vm._v("0")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "bg-white p-3 mb-3 sidebox sidebox-sm font-weight-bold" },
      [
        _vm._v(
          "\n          * rRipple uses interests so we never show you any products or\n          services that don’t match your interests."
        ),
        _c("br"),
        _vm._v(
          "\n          You also never see anything that isn’t from friends, your groups, or those you follow."
        ),
        _c("br"),
        _vm._v(
          "\n          In this way, rRipple can also have no advertisements - ever\n        "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header selected-interests-header" }, [
      _c("span", { staticClass: "text-pointer" }, [
        _c("i", {
          staticClass: "fa fa-plus-circle",
          staticStyle: {
            "font-size": "20px",
            color: "rgb(0, 174, 255)",
            "-webkit-text-stroke-width": ".5px",
            "-webkit-text-stroke-color": "black"
          }
        }),
        _vm._v(" selected interests\n          ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal",
        attrs: { tabindex: "-1", role: "dialog", id: "seller-modal" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog",
            staticStyle: { "max-width": "50%" },
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header d-block border-0 pb-2" }, [
                _c("h2", { staticClass: "modal-title text-center" }, [
                  _vm._v("Congratulations!")
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    staticStyle: {
                      position: "absolute",
                      top: "1rem",
                      right: "1.5rem",
                      "font-size": "2rem"
                    },
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close"
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body text-center" }, [
                _c("p"),
                _c("h2", [_vm._v("You are now a rRipple Seller")]),
                _vm._v(" "),
                _c("h2", { staticClass: "mb-3" }, [
                  _vm._v(
                    "To List a Product, click on this icon in the upper right:"
                  )
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "mb-3" }, [
                  _vm._v(
                    "Then the arrow next to your name, then “Create Product”"
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "At this point shipping is charged by weight, $4 minimum and $4 for every 3 pounds after. International shipping is 1.5 times higher for Canada and 3 times higher for all other countries"
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-underline" }, [
                  _vm._v(
                    "You wil be given order details via e-mail for fulfillment"
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("span", { staticClass: "text-underline" }, [
                    _vm._v("*Please ship all orders within 3 days")
                  ]),
                  _vm._v(
                    ", late shipment may result in cancelation. It is preferable to send the buyer a shipment notice and tracking code by e-mail, we will soon update the platform to allow you to provide tracking codes and we will do this automatically\n              "
                  ),
                  _c("span", { staticClass: "text-underline" }, [
                    _vm._v("rRipple charges 20% platform fees")
                  ]),
                  _vm._v(
                    " as we do significant marketing for you (including influencer marketing, and\n              "
                  ),
                  _c("span", { staticClass: "text-underline" }, [
                    _vm._v("your products can go viral for free on rRipple")
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n              You are charged nothing for listing products or having a seller account - the 20% is deducted from your sales before payouts are provided. This amount also includes credit card charges)\n            "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("span", { staticClass: "text-underline" }, [
                    _vm._v("At this time we payout biweekly by check")
                  ]),
                  _vm._v(
                    ", please ensure your acount e-mail is\n              correct so we can arrange payment\n            "
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n              rRipple reserves the right to ban or limit any sellers, or remove products for any\n              reasons including late shipments, abuse, poor quality or customer service, or legality\n            "
                  )
                ]),
                _vm._v(" "),
                _c("p"),
                _vm._v(
                  "\n            You are responsible for ensuring the legality of your products and that you are not shipping products illegal in the area you are shipping to"
                ),
                _c("p"),
                _vm._v(" "),
                _c("p", [
                  _vm._v("rRipple reserves the right to ban product categories")
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "rRipple reserves the right to issue customer refunds for damage, late shipping,"
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("incorrect items, low quality, or other reasons")
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("Returns and replacements wil be sent to you")
                ]),
                _vm._v(
                  "\n            If you’d like to fulfill an item we already have for sale, e-mail "
                ),
                _c("a", { attrs: { href: "mailto:sellers@rRippled.com" } }, [
                  _vm._v("sellers@rRippled.com")
                ]),
                _vm._v(" "),
                _c("p")
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="friendwrap">
      <div class="row">
        <div class="col-sm-9">
          <div class="pplcontent">
            <div class="row">
              <div style="cursor:pointer;" @click="inActiveFriendRequest" class="col-md-6 myfriend">
                <i
                  class="fa fa-plus-circle"
                  style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
                ></i>
                <strong :class="[!isFriendRequest ? 'text-underline' : '']">My Friends</strong>
              </div>
              <!-- myfriend -->
              <div class="col-md-4 mx-auto">
                <div :class="[isFriendRequest ? 'button button-p7 friend_requests_selected' : 'button button-p7']"
                  @click="activeFriendRequest">
                    <p class="p-0 m-0" v-if="!isFriendRequest">
                      View Friend Requests <span class="new_requests">(<span v-text="incoming_requests.length"></span> new)</span>
                    </p>
                    <p class="m-0 p-2" v-else>
                      View Friends
                    </p>
                </div>
              </div>
            </div>
            <!-- myfriend -->

            <!-- Friend requests -->
            <div class="overflow" v-if="isFriendRequest">
              <div
                v-for="friendship in incoming_requests"
                :key="friendship.id"
                class="row friendbox"
              >

                <div class="col-lg-2">
                    <button class="btn btn-secondary btn-sm disabled" disabled="disabled" v-if="friendship.accepted">
                      <i class="fa fa-check"></i> Accepted!
                    </button>
                    <button class="btn btn-primary btn-sm" v-else @click="acceptRequest(friendship)">
                      <i class="fa fa-check"></i> Accept
                    </button>
                    <div>
                      <img class="userimg" :src="getUserPhoto(friendship.user)" />
                      <br />
                    </div>
                    <strong>{{ friendship.user.name }}</strong>
                  
                </div>
                <!-- col-lg-2 -->

                <!-- col-sm-2 -->

                <div class="col-lg-4 frnddetails mt-2 mt-lg-0">
                  <ul>
                    <li>{{ friendship.sender_reviews }} Reviews</li>

                    <li>{{ friendship.mutual }} Mutual Friends</li>
                    <li>{{ friendship.sender_friends }} Friends</li>
                  </ul>
                </div>
                <!-- col-sm-4 -->

                <div class="col-lg-6 frnddetails mt-2 mt-lg-0 friend-interests">
                  <div class="row">
                    <div class="col-sm-3">Into:</div>
                    <div class="col-sm-9" style="friendship-interests">
                      <section class="d-flex flex-column align-items-center text-center"
                        v-for="interest in friendship.sender_interests" :key="interest.id">
                        <img
                          :alt="interest.name"
                          class="iicon"
                          :src="'/icons/'+interest.icon"
                        />
                        <small class="m-0 p-0" style="line-height: 1.1;">{{ interest.name }}</small>
                      </section>
                    </div>
                  </div>
                  <!-- col-sm-11 -->
                </div>
                <!-- col-sm-6 frnddetails -->
              </div>
              <div class="row justify-content-center align-items-center" style="height: 200px"
              v-if="fetchedFriendRequests && incoming_requests.length == 0">
                <h2>You have no incoming friend requests</h2>
              </div>
              <div class="row justify-content-center align-items-center pb-3 pt-2" style="height: 100%;"
                v-if="!fetchedFriendRequests">
                <i class="fa fa-circle-notch spin text-primary" style="font-size: 5rem;"></i>
              </div>
              <!-- row friendbox -->
            </div>

            <!-- Friends -->
            <div v-else :class="[friends ? 'overflow' : '']">
              <div class="row justify-content-center align-items-center pb-3 pt-2" style="height: 100%;"
                v-if="!fetchedFriends">
                <i class="fa fa-circle-notch spin text-primary" style="font-size: 5rem;"></i>
              </div>
              <div class="row justify-content-center align-items-center" style="height: 200px"
              v-if="fetchedFriends && friends.length == 0">
                <h2>No friends were found</h2>
              </div>
              <div v-for="friend in friends" :key="friend.id" class="row friendbox">
                <div class="col-lg-2 col-sm-2 col-xs-2">
                  <div class="chk_box flex-lg-row flex-column align-items-center">
                    <div class="d-lg-block d-flex flex-column align-items-center">
                      <img class="userimg" :src="getUserPhoto(friend)" />
                      <strong class="d-inline d-lg-none">{{ friend.name }}</strong>
                      <br />
                    </div>
                  </div>
                  <strong class="d-none d-lg-block">{{ friend.name }}</strong>
                </div>
                <!-- col-lg-2 -->

                <!-- col-sm-2 -->

                <div class="col-lg-4 col-sm-10 col-xs-10 frnddetails mt-2 mt-lg-0">
                  <ul>
                    <li>{{ friend.reviews }} Reviews</li>

                    <li>{{ friend.mutual_friends }} Mutual Friends</li>
                    <li>{{ friend.friends.length }} Friends</li>
                  </ul>
                </div>
                <!-- col-sm-4 -->

                <div class="col-lg-6 col-sm-12 frnddetails mt-2 mt-lg-0  friend-interests">
                  <div class="col-sm-3">Into:</div>
                  <div class="col-sm-9" style="display: grid;grid-template-columns: repeat(4, 25%);">
                    <section class="d-flex flex-column align-items-center text-center"
                      v-for="interest in friend.interests" :key="interest.id">
                      <img
                        :alt="interest.name"
                        class="iicon"
                        :src="'/icons/'+interest.icon"
                      />
                      <small class="m-0 p-0" style="line-height: 1.1;">{{ interest.name }}</small>
                    </section>
                  </div>
                  <!-- col-sm-11 -->
                </div>
                <!-- col-sm-6 frnddetails -->
              </div>
              <!-- row friendbox -->
            </div>
            <!-- overflow -->
          </div>
          <!-- pplcontent -->
        </div>

        <div class="col-sm-3 mt-3 mt-md-0">
          <div class="row">
            <div class="col-12 addfriend" @click="addFriend">Add Friends & Follow Influencers</div>
          </div>
          <div class="row mt-2">
              <div class="wrapbox" data-toggle="modal" data-target="#invite-modal">
                <img class="emailicon" src="/images/email_icon.png" />
                <div class="ml-3">Invite friends via email</div>
              </div>
            </div>
          </div>
        </div>

      <div v-if="isAddFriend" class="row addfriend_box">
        <!-- <div @click="closeFriend" class="frd_close">
          <span aria-hidden="true">&times;</span>
        </div> -->
        <div class="col-sm-12">
          <div class="addfriend_overflow">
            <div class="addfriend_header">
              <div class="col-lg-12">
                <input
                  v-model="searchFriend"
                  @keyup="searchMyNotFriend"
                  class="friend_search"
                  type="text"
                  placeholder="Search Friends..."
                />
              </div>
              <!-- col-lg-12 -->
              <div @click="closeAddFriend" class="frd_close">
                <span aria-hidden="true">&times;</span>
              </div>
            </div>

            <!-- end addfriend_header -->

            <div class="row position-relative">
              
              <div :style="fetchingNotFriends || (!fetchingNotFriends && searchFriend.replace(/\s/g, '').length > 0 && notfriends.length == 0) ? 'height: 200px;' : ''"
                  class="col-12 d-flex justify-content-center align-items-center pb-3 pt-2 align-item-center p-0">
                  <i v-if="fetchingNotFriends" class="fa fa-circle-notch spin text-primary" style="font-size: 5rem;"></i>
                  <h4 v-else-if="!fetchingNotFriends && searchFriend.replace(/\s/g, '').length > 0 && notfriends.length == 0">
                    No results were found
                  </h4>
              </div>
              
              <div v-for="user in notfriends" class="col-md-12" v-if="user != false">
                <div class="srch_frd flex-row">
                  <div class="col-lg-5 col-md-4">
                    <div class="d-flex user-info align-items-center">
                      <div class="influencer-badge" v-if="user.is_influencer">rR</div>
                      <img :src="getUserPhoto(user)" alt />
                      {{ user.name }}
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4">
                    <button type="button" class="btn btn-outline-secondary" v-if="isInfluencer(user)"
                      @click="viewInfluencerDetails($event, user)">
                      View Influencer Details
                    </button>
                  </div>
                  <div class="col-md-4">
                    <div class="actions-box row">
                      <div class="col-md-6 p-1">
                        <div :class="getFollowClass(user)"
                          v-if="isInfluencer(user)"
                          @click="toggleFollow(user)">
                          <i class="fa fa-circle-notch spin" v-if="followInProgress.includes(user.id)"></i>
                          <span v-if="!user.already_followed">Follow</span>
                          <span v-else>Unfollow</span>
                        </div>
                      </div>

                      <div class="col-md-6 p-1">
                        <div @click="onAddFriend(user)"
                          :class="[requestInProgress.includes(user.id) ? 'add_frd disabled' : 'add_frd' ]"
                          v-if="!user.request_sent">
                          <i class="fa fa-circle-notch spin" v-if="requestInProgress.includes(user.id)"></i>
                          Add Friend
                        </div>

                        <div class="add_frd request_sent" v-else>
                          <i class="fa fa-check"></i>
                          Request sent
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="influencer-dialog"
                :class="[showInfluencerDialog ? 'influencer-details show' : 'influencer-details']">
                <a class="close" href="#" @click="closeInfluencerDialog">
                  <i class="fa fa-times"></i>
                </a>
                <p>
                  <span class="font-weight-bold">Followers:</span>
                  <span class="text-underline" v-text="selectedInfluencer.followers"></span>
                </p>
                <p>
                  <span class="font-weight-bold">Dollars created from posts:</span>
                  <span class="text-underline">
                    ${{ selectedInfluencer.dollars_from_posts }}
                  </span>
                </p>
                <p>
                  <span class="font-weight-bold">Top 4 Interests Reviewed:</span>
                  <p>
                    {{ selectedInfluencer.top_4_interests.join(', ') }}
                  </p>
                </p>
              </div>

              <div class="col-md-6"></div>
            </div>
            <!-- row -->
          </div>
          <!-- end addfriend_overflow -->
        </div>
        <!-- col-sm-12 -->
      </div>
        <!-- col-sm-3 -->
      </div>
        
        <!-- Modal -->
      <div class="modal fade" id="invite-modal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true" style="top: 30vh;">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body d-flex justify-content-center flex-column pt-5">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute;top: 20px;right: 20px;">
                <span aria-hidden="true">&times;</span>
              </button>

              <h4 class="mb-3">
                Copy and paste this into an e-mail to friends you wish to invite:
              </h4>


              <textarea style="position: absolute; height: 0; opacity: 0; z-index: -1;" id="invitation-email">
                <p>Hey!</p>
                I'm using rRipple, a platform where we can see each other's reviews of products, movies, tv shows, etc - but only in areas both of us are interested in. 
                 	&lt;a href="{{ referral_link }}"&gt;Click through to rRipple</a> (https://www.rRippled.com) and sign up using Facebook so it will automatically friend us!
              </textarea>
              <blockquote class="blockquote">
                <p class="mb-1">Hey!</p>
                I'm using rRipple, a platform where we can see each other's reviews of products, movies, tv shows, etc - but only in areas both of us are interested in. 
                <a :href="referral_link">Click through to rRipple</a> (https://www.rRippled.com) and sign up using Facebook so it will automatically friend us!
              </blockquote>
              <button class="btn btn-primary" @click="copyEmailToClipboard">
                <i :class="emailCopyIcon"></i>
                <span v-text="emailCopyText"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<style lang="scss" scoped>
.user-info {
  @media (max-width: 991px) {
    flex-direction: column;
  }
  
  position: relative;

  .influencer-badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }
}

.add_frd {
  &.disabled {
    background-color: #52c4fa;
  }
  &.following {
    background-color: #6c757d;
  }
}

.request_sent {
  color: #fff;
  background: #949799; /** alternative: #00c853 */
  cursor: auto;
}

.influencer-badge {
	width: 30px;
	height: 30px;
	font-weight: 900;
	background-color: #00aeff;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	padding: .3rem;
	padding-top: .5rem;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;

  @media (max-width: 991px) {
    // width: 100%;
  }
}

.influencer-details {
	position: absolute;
	width: 400px;
	height: auto;
	z-index: 2;
	right: calc(50% - 300px);
	top: 60px;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
	transition: all 0.3s ease-in-out;
	opacity: 0;
	pointer-events: none;
	padding: 0.5rem 1rem 0.5rem 1rem;
	border: 1px solid #d5d5d5;
  &.show {
    opacity: 1;
    pointer-events: initial;
  }
  .close {
    position: absolute;
    top: 5px;
    right: 10px;
  }
}
.friend_requests_selected {
	background: #059ade;
	border: 1px solid #00aeff;
  transform: scale(.95);
  transition: all .1s ease-in-out;
}
.text-underline {
  text-decoration: underline;
}

.friend-interests {
  @media screen and (max-width: 576px) {
    display: grid;
  }
}

.friendship-interests {
  display: grid;
  grid-template-columns: repeat(4, 25%);

  @media screen and (max-width: 1601px) {
    grid-template-columns: repeat(3, 33%);
  }
}
</style>

<script>
import { collect } from 'collect.js';
import { isInfluencer } from '../services/user';
import InfluencerBadge from './InfluencerBadge';

export default {
  name: "interesets",
  props: ["user_interests", "interests"],
  data() {
    return {
      tabview: "myinterest",
      current_parent: null,
      list: this.interests,
      selected_interests: this.user_interests,
      tobe_saved: [],
      fetchedFriends: false,
      fetchedFriendRequests: false,
      fetchedNotFriends: false,
      friends: [],
      friendsrequest: [],
      incoming_requests: [],
      isAddFriend: false,
      notfriends: [],
      searchFriend: "",
      isFriendRequest: false,
      origNotFriends: [],
      showInfluencerDialog: false,
      selectedInfluencer: {
        followers: 1131,
        dollars_from_posts: 327000,
        top_4_interests: ['Skiing', 'Photography', 'Travel', 'Restaurants'],
      },
      requestInProgress: [],
      followInProgress: [],
      friendship: [],
      referral_link: null,
      fetchingNotFriends: false,
      emailCopyText: 'Copy To Clipboard',
      emailCopyIcon: 'fa fa-copy',
    };
  },

  created: function() {
    this.fetchFriends();
    
    axios.get('/user/referral-link').then(({ data }) => {
        this.referral_link = data;
    });
  },

  methods: {
    isInfluencer,
    acceptRequest: function(friendship) {
      
          console.log(friendship)
      axios
        .post("/confirmRequest", {
          friendship: friendship.id,
        })
        .then(({ data }) => {
          friendship.accepted = 1;
          console.log(friendship)
          let sender = friendship.user;
          sender.friends = friendship.sender_friends;
          sender.interests = friendship.sender_interests;
          sender.reviews = friendship.sender_reviews;
          this.friends.push(sender);
        });
    },
    fetchFriends: function() {
      axios.get("/fetchfriends").then(({ data }) => {
        console.info(data.friends, data.friend_requests);
        this.friends = data.friends;
        this.incoming_requests = data.friend_requests;
        this.fetchedFriends = true;
        this.fetchedFriendRequests = true;
      });
    },
    fetchNotFriends: function() {
      axios.get("/friendrequest").then(({ data }) => {
        this.friendsrequest = data;
      });
    },
    activeFriendRequest: function() {
      this.isFriendRequest = !this.isFriendRequest;
    },
    inActiveFriendRequest: function() {
      this.isFriendRequest = false;
      this.fetchFriends();
    },
    searchMyNotFriend: function() {
      this.notfriends = [];
      this.origNotFriends = [];

      if (this.searchFriend.replace(/\s/g, '').length == 0) {
        this.fetchingNotFriends = false;
        console.log('here');
        return;
      }

      this.fetchingNotFriends = true;

      axios.get(`/fetchnotfriends/?q=${this.searchFriend}`).then(({ data }) => {
        this.notfriends = data.notfriends;
        this.origNotFriends = data.notfriends;
        this.fetchedNotFriends = true;
        this.fetchingNotFriends = false;
      });
    },
    addFriend: function() {
      this.isAddFriend = true;
    },
    closeAddFriend: function() {
      this.isAddFriend = false;
    },
    onAddFriend: function(user) {
      this.requestInProgress.push(user.id);
      axios
        .post("/addfriend", {
          friend_id: user.id
        })
        .then(({ data }) => {
          user.request_sent = true;
          this.addFriend();
          this.requestInProgress.splice(user.id, 1);
          // alert("You have requested a new friend");
        });
    },
    closeFriend: function() {
      this.isAddFriend = false;
    },
    getFollowClass(user) {
      if (user.already_followed) {
        return 'add_frd following';
      }
      
      return this.followInProgress.includes(user.id) ? 'add_frd disabled' : 'add_frd';
    },
    viewInfluencerDetails(e, user) {
      console.log(e);
      this.selectedInfluencer.followers = user.followers.length;
      this.selectedInfluencer.top_4_interests = collect(user.top_4_interests).pluck('name');
      this.selectedInfluencer.dollars_from_posts = user.total_referrals_income;
      this.showInfluencerDialog = true;
      var offset = $(e.target).offset(); 

      const influencerDialog = $('#influencer-dialog');

      influencerDialog.offset({ top: offset.top });
      // influencerDialog.css('top', `${offset.top}px)`)
    },
    closeInfluencerDialog(e) {
      e.preventDefault();
      this.showInfluencerDialog = false;
      $('#influencer-dialog').offset({ top: 0 });
    },
    followInfluencer(user) {
      if (this.followInProgress.includes(user.id)) {
        return;
      }

      this.followInProgress.push(user.id);
      axios.post(`/follow/${user.id}`)
        .then(( { data }) => {
          if (data.followed) {
            this.notfriends = collect(this.notfriends).map(notFriend => {
              if (notFriend.id == user.id) {
                notFriend.already_followed = true;
              }

              return notFriend;
            }).toArray();

            this.followInProgress.splice(this.followInProgress.indexOf(user.id), 1);
          }
        });
    },
    unfollowInfluencer(user) {
      if (this.followInProgress.includes(user.id)) {
        return;
      }

      this.followInProgress.push(user.id);
      axios.post(`/unfollow/${user.id}`)
        .then(( { data }) => {
          if (data.followed) {
            this.notfriends = collect(this.notfriends).map(notFriend => {
              if (notFriend.id == user.id) {
                notFriend.already_followed = false;
              }

              return notFriend;
            }).toArray();

            this.followInProgress.splice(this.followInProgress.indexOf(user.id), 1);
          }
        });
    },
    toggleFollow(user) {
      if (user.already_followed) {
        return this.unfollowInfluencer(user);
      }

      this.followInfluencer(user);
    },
    getUserPhoto(user) {
      if (!user) {
        return '/images/avatar.png';
      }

      if (user.avatar) {
        return `/storage/images/${user.avatar}`;
      }

      if (user.facebook_profile_pic_url) {
        return user.facebook_profile_pic_url;
      }

      return '/images/avatar.png';
    },
    copyEmailToClipboard() {
        const emailTextarea = $('#invitation-email');
        emailTextarea.focus();
        emailTextarea[0].setSelectionRange(0, emailTextarea.html().length);

        document.execCommand("copy");
        this.emailCopyIcon = 'fa fa-clipboard';
        this.emailCopyText = 'Copied!';

        setTimeout(() => {
          this.emailCopyIcon = 'fa fa-copy';
          this.emailCopyText = 'Copy To Clipboard';
        }, 1500)
    },
  }
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [
        _c("img", {
          staticClass: "img-responsive",
          attrs: { src: _vm.imageUrl, alt: "", width: "180", height: "128" }
        })
      ]),
      _vm._v(" "),
      _c("rating-display", {
        attrs: {
          "max-stars": _vm.maxStars,
          grade: _vm.grade,
          "moon-active": _vm.moonActive
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
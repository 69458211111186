<template>
  <div>
    <div v-if="loading">Loading...</div>

    <div v-if="!loading" class="media-search-results">
      <div class="box media-search-result d-flex">
        <div class="row no-gutters">
          <div class="col-xl-6">
            <div class="d-flex">
              <div class="media__details">
                <div class="text-left">{{ mediaItem.title }}</div>
                <div class="text-left media-info--underlined media-info--small"> 
                  {{ mediaItem.type === 'series' ? 'TV Series' : 'Movie' }}:
                  {{ mediaItem.genre }}
                  <span v-if="mediaItem.production">
                    - {{ mediaItem.production }}
                  </span>
                </div>
                <div>
                  <img :src="mediaItem.poster" class="media-search-result__poster" />
                </div>
              </div>


              <div class="rv_box">
                  <div class="rvrate">
                      <div class="avg-rating font-weight-bolder">
                          {{ mediaItem.average_rating > 5 ? 5 : mediaItem.average_rating }}
                          <i class="fa fa-star text-warning"></i>
                          <span v-if="mediaItem.average_rating > 5">
                              + <i class="fa fa-moon text-warning"
                                  style="transform: rotate(200deg);"></i>!
                          </span>
                      </div>
                      <!-- <span style="font-weight:bolder">{{ product.average_rating }}</span> -->
                      <img :class="[mediaItem.average_rating > 5 ? 'icons irate moon-filled' : 'icons irate']" :src="[mediaItem.average_rating > 5 ? '/images/moon-filled.png' : '/images/moon.png']"/>
                      <div class="stars-box d-flex flex-column-reverse">
                          <span v-for="star in 5" :key="star">
                              <img
                                      v-if="star > mediaItem.average_rating"
                                      class="icons irate"
                                      src="/images/star2.png"
                              />
                              <img v-else class="icons irate" src="/images/star1.png"/>
                          </span>
                      </div>
                  </div>
              </div>
            </div>

            <div class="d-flex flex-column media__info-container">
              <div class="media__info d-flex">
                <div class="media__info-inner media__info-item">
                  <div class="text-right">({{ mediaItem.year }})</div>

                  <div>{{ mediaItem.short_description }}</div>
                  
                  <div class="media-info media-info--underlined">{{ mediaItem.rated }}</div>
                </div>
                <div class="media__info-right d-none d-md-block"></div>
              </div>

              <div class="media__info-item">
                  <div class="media-info-title mt-4">
                  Top Cast:
                </div>
                
                <div>
                  {{ mediaItem.top_cast_members }}
                </div>

                <div class="mt-4">
                  IMDB Rating: {{ mediaItem.rating }}/10 ({{ mediaItem.votes | localNumber }} IMDB reviews)
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 review-container">
            <div class="Review_overflow">
              <div class="text-center" v-if="mediaItem.reviews.length === 0">
                  <p class="py-10">
                    No reviews for this
                    {{ mediaItem.type === 'movie' ? 'Movie' : 'TV Show' }}
                    yet. Be the first!
                  </p>
              </div>

              <div v-for="r in mediaItem.reviews.slice(0,reviewLimit)" class="review_flexbox" :key="r.id">
                <div class="col-md-4">
                    <div class="rr-badge-container">
                        <div v-if="isInfluencer(r.user)" class="rr-badge">rR</div>
                        <img class="userimg" :src="r.user.avatar_url"/>
                    </div>

                    <div>
                        <b>{{ r.user.name }}</b>
                        <div v-if="isInfluencer(r.user)" class="influencer-badge-container">
                            <InfluencerBadge></InfluencerBadge>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div>
                        <i>{{ r.user.name.split(' ')[0] }} says:</i>
                    </div>
                    <p>
                        {{ r.content }}
                    </p>

                    <div class="review_star font-weight-bolder">
                        {{ r.rating > 5 ? 5 : r.rating }}
                        <i class="fa fa-star text-warning"></i>
                        <span v-if="r.rating > 5">
                            + <i class="fa fa-moon text-warning"
                                style="transform: rotate(200deg);"></i>!
                        </span>
                    </div>
                </div>
              </div>

              <div
                v-if="mediaItem.reviews.length > reviewLimit"
                class="d-flex justify-content-center my-2"
              >
                <button @click="reviewLimit = reviewLimit + 10" class="button">
                  see more reviews
                </button>
              </div>
            </div>

            <div v-if="mediaItem.friend_reviews.length">
              <div class="frd_viewer">
                <img
                  v-for="(item3,index2) in mediaItem.friend_reviews"
                  :key="index2"
                  class="icons"

                  :src="item3.user.avatar_url"
                />

                <a v-if="mediaItem.friend_reviews.length > 6" href>
                  <b class="counterFriends">+{{ mediaItem.friend_reviews.length - 6 }}</b>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div  v-if="!loading" class="rvw_wraper box mt-3 text-left" style="font-size: 1rem">
          <div v-if="mediaItem.can_review">
              <h3 class="my-3">Add Review</h3>
              <div class="form-group">
                  <rating :grade="0"
                          :max-stars="5"
                          :moon-ctive="false"
                          @rating-set="reviewForm.new_rating = $event"
                  >
                  </rating>
              </div>

              <div class="form-group">
                  <label for="title">Title</label>
                  <input type="text" class="form-control" id="title"
                          placeholder="Awesome title"
                          name="title" v-model="reviewForm.title">
              </div>

              <div class="form-group">
                  <label for="body">Content</label>
                  <textarea class="form-control" id="body" name="body"
                            v-model="reviewForm.body"></textarea>
              </div>

              <div class="form-group">
                  <label>
                      <input type="checkbox" v-model="reviewForm.is_private">
                      Make Review Private
                  </label>
              </div>

              <div class="form-group">
                  <button @click="submitReview" class="button">Add Review</button>
              </div>
          </div>

          <div v-if="mediaItem.user_review">
              <h3 class="my-3">Edit Review</h3>
              <div class="form-group">
                  <label>
                      <input type="checkbox" v-model="mediaItem.user_review.is_private">
                      Make Review Private
                  </label>
              </div>
              <div class="form-group">
                  <button @click="updateReview(mediaItem.user_review.is_private)" class="button">Update Review</button>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMediaItem, addReview } from "../api/media";
import { isInfluencer } from '../services/user';

export default {
  data() {
    return {
      loading: true,
      mediaItem: {},
      reviewForm: {
        is_private: false,
        title: "",
        body: "",
        new_rating: null,
      },
      reviewLimit: 3,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    isInfluencer,

    async fetchData() {
      this.loading = true;
      this.mediaItem = await getMediaItem(this.$route.params.id);
      this.loading = false;
    },

    async submitReview() {
      try {
        await addReview(this.$route.params.id, this.reviewForm);
        location.reload();
      } catch (error) {
        const errors = Object.values(error.response.data.errors).map(err => err[0]).join('\r\n');
        window.alert(`Errors: ${errors}`);
      }
    },
  },

  watch: {
    $route(to, from) {
      this.fetchData();
    },
  },
};
</script>

<style>
.media-search-result__poster {
  background-size: cover;
  max-height: 290px;
  width: auto;
  max-width: 100%;
}

.media-search-result__title {
  font-size: 28px;
}

.media__details {
  width: 100%;
}

.media__rating {
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 576px) {
  .media__rating {
    width: 140px;
  }
}

.rating-container {
  font-size: 25px;
  width: 100%;
  max-width: 100px;
  color: #309fd8;
  display: flex;
  height: auto;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  border-left: solid gray 1px;
  float: right;
}

.media__info {
  padding-top: 5px;
}

.media__info-inner {
  padding-right: 5px;
}

.media__info-right {
  border-left: 1px solid gray;
  min-width: 100px;
  height: 80px;
}

.media__info-item {
  font-size: 16px;
  text-align: left;
}

.media__info-container {
  border-top: 1px solid gray;
}

.media-info--underlined {
  text-decoration: underline;
}

.media-info-title {
  font-size: 19px;
}

.media-info--small {
  font-size: 16px;
}

@media screen and (min-width: 576px) {
  .review-container {
    border-left: 1px solid grey;
  }
}

.media-search-results .Review_overflow {
  border-left: 0;
}

.media__review-count {
  font-weight:bolder;
  font-size: 16px;
}

</style>



const scroll = function (slider) {
    const customScrollbar = slider.closest('.custom-scrollbar');
    const height = (customScrollbar.find('.custom-scrollbar-wrapper')[0].scrollHeight + customScrollbar.height()) + 50;
    const position = slider.position().top;
    const scrollPercent = (position / slider.closest('.rail--y').height()) * 100;
    const scrollPx = scrollPercent / 100 * height;
    customScrollbar.find('.custom-scrollbar-wrapper').scrollTop(scrollPx);
  };
  
window.loadCustomScrollbars = function () {

$('body .custom-scrollbar').each(function () {
    if ($(this).find('.rail--y').length > 0) {
    $(this).find('.rail--y').remove();
    }

    $(this).append('<div class="rail--y"><div class="custom-scrollbar--y"></div></div>')
    $(this).attr('data-custom-scrollbar', str_random());

    const scrollbarHeight = $(this).find('.custom-scrollbar-wrapper').height();
    const height = ($(this).find('.custom-scrollbar-wrapper')[0].scrollHeight + scrollbarHeight) + 50;
    const sliderHeight = (scrollbarHeight / height) * 100;

    $(this).find('.custom-scrollbar--y').css('height', sliderHeight + '%');
    $(this).find('.custom-scrollbar--y').draggable({
    containment: 'parent',
    axis: 'y',
    drag: function () {
        scroll($(this));
    },
    });
});
};

$(function () {
  loadCustomScrollbars();

  $('body').on('click touchstart', '.custom-scrollbar .rail--y', function (e) {
    const y = e.pageY - $(this).offset().top;
    const slider = $(this).find('.custom-scrollbar--y');

    slider.css('top', y + 'px');
    scroll(slider);
  });
});
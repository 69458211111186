<template>
  <div>
    <div id="discussion">
      <!-- START OF discussion -->

      <div class="discussion_container">
        <div class="myfriend p-0">
          <i
            class="fa fa-plus-circle"
            style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
          ></i>
          <b>Group Chat</b>
        </div>

        <div
          class="px-3 pb-3"
          v-if="discussions.length === 0"
        >Go to my groups tab to create or join groups!</div>

        <!-- myfriend -->
        <h4 class="ml-3 mt-2" v-if="discussions.length > 0">(click the group name below to enter that group chat)</h4>
        <div class="discussion_overflow d-flex pt-2 pb-2">
          <div
            v-for="(item,index) in discussions"
            :key="item.id"
            @click="makeActive(index)"
            class="topic_box border-0"
            :style="item == discussions[activeTopicIndex] ? 'transform: scale(.95);' : ''"
          >
            <div v-if="item == discussions[activeTopicIndex]" class="selectedItem bg-primary" style="border: 1px outset #000 !important">
              {{ item.topic }}
              <p style="font-size: 14px;" class="text-center m-0">(active below)</p>
            </div>
            <span class="selectedItem" v-else>{{ item.topic }}</span>
          </div>
          <!-- topic_box -->
        </div>
        <!-- discussion_overflow -->
      </div>
      <!-- discussion_container -->
      <br />
      <div
        v-if="discussions.length > 0 && discussions[activeTopicIndex]"
        class="discussion_container"
      >
        <div class="myfriend p-0">
          <b>
            Topic: {{ discussions[activeTopicIndex].topic }} ({{ discussions[activeTopicIndex].posts.length
            }})
          </b>
        </div>
        <!-- myfriend -->
        <div class="discussion_overflow">
          <!-- start topic_chatbox -->
          <div
            v-for="post in discussions[activeTopicIndex].posts"
            :key="post.id"
            class="topic_chatbox"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="px-3 mb-2 d-flex align-items-center">
                <div
                  v-if="isInfluencer(post.user)"
                  class="rr-badge rr-badge--small rr-badge--inline mr-2"
                >rR</div>
                <img
                  class="topic_img"
                  :src="post.user.avatar_url"
                />
                {{ post.user.name }}
                <InfluencerBadge type="super" v-if="isInfluencer(post.user)"></InfluencerBadge>
              </div>
              <div class="mr-2" v-if="discussions[activeTopicIndex].is_owner">
                <button
                  @click="deleting = [discussions[activeTopicIndex].id, post.id]"
                  class="button button-sm"
                >Delete Post</button>
                <button
                  v-if="!post.is_mine"
                  @click="kicking = [discussions[activeTopicIndex].id, post.user_id]"
                  class="button button-sm"
                >Ban User</button>
              </div>
            </div>
            <div class="col-lg-12 post-content" v-html="post.content"></div>
            <div class="col-lg-12 pt-2" v-if="post.youtube_url">
              <youtube
                :video-id="getIdFromYouTubeURL(post.youtube_url)"
                player-width="300"
                player-height="180"
              ></youtube>

              <div class="text-right admin-delete-video-container" v-if="isAdmin">
                <a href @click.prevent="adminDeleteVideo(post)">Admin Delete Video</a>
              </div>
            </div>
          </div>
          <!-- end topic_chatbox -->
        </div>
        <!-- discussion_overflow -->
      </div>
      <!-- discussion_container -->
      <br />
      <div class="discussion_container" v-if="discussions.length > 0">
        <div class="col-lg-12">
          <div class="row position-relative">
            <div class="col-lg-12">
              <div class="row dtlsHeader">
                <div class="col-lg-7">
                  <b>Topic: {{ discussions[activeTopicIndex].topic }}</b>
                </div>
                <div class="SeacrhBox col-lg-5">
                  <input
                    type="text"
                    id="mySearch"
                    v-model="searchText"
                    @input="searchProducts($event.target.value)"
                    placeholder="Tag a product in your post"
                    title="Tag a product in your post"
                    data-toggle="tooltip"
                  />
                </div>
                <!-- SeacrhBox -->
              </div>
              <!-- row dtlsHeader -->
            </div>
            <!-- col-lg-12 -->

            <div :class="searchText.length ? 'col-lg-7' : 'col-lg-12'">
              <div class="text-container">
                <!-- <textarea
                  v-model="comment"
                  class="comment"
                  name="comment"
                  placeholder="Enter Reply"
                ></textarea> -->
                  <quill-editor
                    ref="comment"
                    v-model="comment"
                    :options="quillOptions"
                    @change="onEditorChange($event)"
                    style="position:relative; height: 170px;"
                  />


                <div class="reply-controls">
                  <input
                    id="add-youtube-url"
                    class="reply-youtube-url"
                    v-model="youtubeUrl"
                    placeholder="Add YouTube video by entering URL here & submitting..."
                    title="Add YouTube video by entering URL here & submitting..."
                    data-toggle="tooltip"
                  />

                  <button class="button py-1 button-blk" @click="submitMessage">
                    <span class="d-md-none">Send</span>
                    <span class="d-none d-md-inline-block">Submit Message</span>
                  </button>
                </div>
              </div>
            </div>
            <!-- change to 12 for full width -->

            <div v-show="searchText.length" class="col-lg-5 prdc_box-container">
              <div class="prdc_box" id="product-search-results">
                <!-- Product -->
                <ul id="myMenu">
                  <div v-if="searchText.length < 3">Please enter at least 3 characters</div>
                  <div v-else-if="!products.length && !fetchingNewProducts">No Results</div>
                  <template v-if="products.length">
                    <li
                      class="mb-2 product"
                      style="cursor: pointer"
                      @click="insertProduct(item)"
                      v-for="(item,index) in products"
                      :key="'product_'+index"
                    >
                      <img class="mr-2" :src="item.photo_featured ? item.photo_featured : item.image_link ? item.image_link : '/icons/default.png'" alt />
                      <div>{{ item.name }}</div>
                    </li>
                  </template>
                  <li v-if="fetchingNewProducts && (products.length < productsExpectedResults)">
                    <vue-content-loading :width="450" :height="30">
                      <rect x="0" y="0" rx="4" ry="4" width="40" height="30" />
                      <rect x="50" y="5" rx="4" ry="4" width="200" height="15" />
                    </vue-content-loading>
                  </li>
                </ul>
              </div>
              <!-- prdc_box -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- col-lg-12 -->
      </div>
      <!-- discussion_container -->
    </div>
    <confirm-modal
      v-if="kicking"
      message="Are you sure you want to kick this user from the group?"
      @confirm="kickUser(kicking[0], kicking[1])"
      @close="kicking = null"
    ></confirm-modal>
    <confirm-modal
      v-if="deleting"
      message="Are you sure you want to delete this post from the group?"
      @confirm="deletePost(deleting[0], deleting[1])"
      @close="deleting = null"
    ></confirm-modal>
  </div>
  <!-- END OF discussion -->
</template>
<script>
import { getIdFromURL as getIdFromYouTubeURL } from "vue-youtube-embed";
import ConfirmModal from "./ConfirmModal";
import InfluencerBadge from "./InfluencerBadge";
import { isInfluencer } from "../services/user";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import VueContentLoading from 'vue-content-loading';
import { searchProductsSimple, getCachedResults } from '../api/products';
import Echo from 'laravel-echo';
import collect from 'collect.js';

export default {
  name: "DiscussionForum",
  props: ["user_interests", "interests"],
  components: { ConfirmModal, InfluencerBadge, quillEditor, VueContentLoading },
  data() {
    return {
      isAdmin: window.isAdmin,
      tabview: "myinterest",
      current_parent: null,
      list: this.interests,
      selected_interests: this.user_interests,
      tobe_saved: [],
      discussions: [],
      products: [],
      loading: false,
      activeTopicIndex: 0,
      comment: "",
      youtubeUrl: "",
      searchText: "",
      kicking: null,
      deleting: null,
      products_search_id: null,
      quillOptions: {
        placeholder: 'Enter reply',
        theme: 'snow',
        modules: {
          toolbar: false,
        },
      },
      blacklist: [],
      productSearchPage: 0,
      lastProductSearchKeyword: null,
      productsExpectedResults: 20,
      productsSearchFailed: false,
      productsSearchDone: false,
      productSearchPages: 100,
      productsCacheRetrieved: false,
      fetchingNewProducts: false,
      productsSearchTimeout: null,
      completedProductSearches: [],
      productSearchesCache: {},
    };
  },
  created: function() {
    this.fetchDiscussion();
    this.fetchInterest();
    
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.MIX_PUSHER_APP_KEY,
        forceTLS: false,
    });
  },
  mounted() {
    $(() => {
      window.Echo.private(`users.${window.User.id}`)
        .listen('.paapi.products.imported', (event) => {
          this.productSearchPage = event.pagination.current_page;
          this.productSearchPages = event.pagination.number_of_pages;
          this.productsCacheRetrieved = true;
          this.fetchingNewProducts = false;

          if (event.keywords == this.searchText) {
            getCachedResults(event.cache_key).then((results) => {
              const products = collect(results.products).map(product => {
                product.image_link = results.images[product.amazon_asin];

                return product;
              }).toArray();
              this.products = this.products.concat(products);

              if (this.products.length >= this.productsExpectedResults) {
                this.completedProductSearches.push(this.searchText)
              }

              this.logProductsSearchData();
            }).catch(() => {
              console.log('err', ...arguments)
            }).finally(() => {
              $('#product-search-results').trigger('scroll')
            });
          } else {
            console.warn('Resutls from an old search will not be retrieved.', {
              Query: event.keywords,
            });
          }
        })
        .listen('.paapi.products.failed', event => {
          this.productsSearchFailed = true;
          this.fetchingNewProducts = false;
          this.productsSearchFailed = false
        })
      .on('pusher:subscription_succeeded', member => {        
        console.log('subscription suceeded')
      }).on('pusher:subscription_failed', () => {
        console.warn('Pusher subscription failed!');
        window.alert('An error has occured! Please refresh the page and try again.');
      });

      $('body').on('scroll', '#product-search-results, #product-search-results *', e => {
        console.log('scrolled')

        if (this.canLoadMoreProducts) {
          this.productsCacheRetrieved = false
          this.resumeProductsSearch()
        }
      })
    });
  },
  methods: {
    isInfluencer,
    getIdFromYouTubeURL,
    adjustInputsTooltips() {
      const windowWidth = $(window).width();

      if (windowWidth < 864) {
        if (windowWidth < 292) {
          $('#mySearch').tooltip();
        } else {
          $('#mySearch').tooltip('dispose');
        }

        return $('#add-youtube-url').tooltip();
      }

      if (windowWidth < 1224 && windowWidth > 990) {
        return $('#add-youtube-url').tooltip();
      }

      try {
        $('#add-youtube-url').tooltip('dispose');
      } catch {

      }
    },
    adminDeleteVideo(post) {
      let shouldDelete = confirm('Are you sure you want to delete this video?');

      if(shouldDelete) {
        axios.delete(`/discussion-posts/${post.id}/video`).then(() => {
          post.youtube_url = '';
        });
      }
    },
    deletePost(discussionId, postId) {
      axios.post(`/discussion/${discussionId}/delete/${postId}`).then(() => {
        this.fetchDiscussion();
      });
    },
    kickUser(discussionId, userId) {
      axios.post(`/groups/${discussionId}/kick/${userId}`);
    },
    searchProducts(search = "") {

      if (this.productsSearchTimeout) {
        clearTimeout(this.productsSearchTimeout)
      }

      if (search.length < 3) {
        return;
      }

      if (search != this.lastProductSearchKeyword) {
        this.products = []
      }

      this.productsSearchTimeout = setTimeout(() => {
        if (search == this.lastProductSearchKeyword) {
          this.productsSearchPage++
        } else {
          this.lastProductSearchKeyword = search
          this.productsSearchPage = 1
          this.productsExpectedResults = 20
        }

        this.searchText = search
        this.productsSearchDone = false
        this.productsSearchFailed = false
        this.productsCacheRetrieved = false
        this.fetchingNewProducts = true;

        searchProductsSimple(
          search,
          null,
          this.productsSearchPage,
          str_random()
        ).then((data) => {
          if (this.productsSearchPage == 1) {
            try {
              this.products = Object.values(data.products)
            } catch {
              this.products = data.products
            }

            this.productsExpectedResults = data.expected_results
            console.log('registered', data, this.products, this.productsExpectedResults)
          } else {
            console.log('not first', this.productsSearchPage)
          }
        })
      }, 500)
    },
    resumeProductsSearch() {
        if ($('#product-search-results .product:last').is(':visible') && this.canLoadMoreProducts) {
          console.log('searching e')
          this.searchProducts(this.searchText);
        } else {
          console.log('not visible');
        }
    },
    logProductsSearchData() {
        // console.log('page', this.productSearchPage)
        // console.log('pages', this.productSearchPages)
        // console.log('expected', this.productsExpectedResults)
        // console.log('results', this.products.length)
        // console.log('failed', this.productsSearchFailed)
        // console.log('done', this.productsSearchDone)
        // console.log('cache retrieved', this.productsCacheRetrieved)
    },
    insertProduct(p) {
      this.comment += ` <a href="${p.referral_link}">${p.name}</a> `;
      this.searchText = "";
    },
    fetchDiscussion: function() {
      var self = this;
      axios.get("/fetchdiscussions").then(({ data }) => {
        self.discussions = data.discussions;
        self.blacklist = data.blacklist;

        this.$nextTick(() => {
          if (this.$route.query.group) {
            const index = self.discussions.findIndex(
              d => d.id == this.$route.query.group
            );
            if (index !== -1) this.makeActive(index);
          }
        });
      });
    },
    fetchInterest: function() {
      var self = this;
      axios.get("/profile/fetchinterest").then(({ data }) => {
        self.interests = data.interests;
        self.user_interests = data.user_interests;
        this.adjustInputsTooltips()
      });
    },
    makeActive: function(index) {
      this.activeTopicIndex = index;
      //this.activeTopic = item;
    },
    submitMessage: function() {
      var self = this;
      axios
        .post("/savemessage/", {
          comment: this.comment,
          youtube_url: this.youtubeUrl,
          discussion_id: self.discussions[self.activeTopicIndex].id
        })
        .then(res => {
          self.fetchDiscussion();
          this.comment =
            "Post successful! Please see the top of the discussion thread above for your post";
          this.youtubeUrl = "";
        })
        .catch(error => {
          if (error.response.data.message) {
            return $.notify(error.response.data.message, 'error');
          }

          if (error.response.data && error.response.data.errors) {
            var map = error.response.data.errors;
            Object.keys(map).forEach(function(key) {
              value = map[key];
              errors[key] = value;

              $.notify(value, 'error');
            });

            return this.errors.record(errors);
          }
          
          return $.notify('The given data is invalid.', 'error');
        });
    },    
    onEditorChange({ quill, html, text }) {
      if (!html) {
        return;
      }

      let comment = this.comment;

      if (!this.isValidHtml(html)) {
        // console.debug(html, 'did not pass, replaced with:', text)

        this.comment = comment.replace(html, text);

        return;
      }

      // console.debug(html, 'is valid HTML');

      $(html).find('*').each((index, child) => {
        const childHtml = child.outerHTML;

        // console.debug('Checking child:', childHtml)

        if (!this.isValidHtml(childHtml)) {
          const text = childHtml.replace(/(<([^>]+)>)/gi, "");
          // console.debug(childHtml, 'did not pass, replaced with:', text);

          comment = comment.replace(childHtml, text);
        }

        if (this.isLink(childHtml)) {
          // console.debug(childHtml, 'is a link');

          if (!this.isValidLink(childHtml)) {
            // console.log(childHtml, 'is an invalid link')

            comment = comment.replace(childHtml, '');
          }
        }
      });

      this.comment = comment;
    },
    isValidHtml(html) {
      // console.debug('tag', $(html).prop('tagName'))
      return ['p', 'a', 'span', 'br'].includes($(html).prop('tagName').toLowerCase());
    },
    isLink(html) {
      return $(html).prop('tagName') == 'A';
    },
    isValidLink(html) {
      html = $(html);
      let href = html.prop('href');

      if (!href) {
        return false;
      }

      href = new URL(href);

      // console.debug('URL', href)
        const allowedHosts = [
            '127.0.0.1',
            'localhost',
            'rrippled.com',
            'rriple.com',
            'staging.rrippled.com',
            'staging.rriple.com',
        ];

      if (this.blacklist.includes(href.hostname)) {
        $.notify('You can add product tag links by searching in the [tag a product] box to the [right]')
      }

      return allowedHosts.includes(href.hostname);
    },
  },
  computed: {
    canLoadMoreProducts() {
      return (this.products.length < this.productsExpectedResults && !this.fetchingNewProducts)
        || this.productsSearchFailed
    }
  }
};
</script>

<style scoped>
.topic_box {
  width: initial;
}
.text-container {
  position: relative;
}

.reply-controls {
  position: relative;
  padding: 0 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: .5rem;
  margin-bottom: 1rem;
}

.reply-controls .button {
  min-width: 60px;
  margin-left: 10px;
  line-height: 32px;
}

@media screen and (min-width: 576px) {
  .reply-controls .button {
    margin-left: 0;
    line-height: 32px;
  }
}

.reply-youtube-url {
  padding: 0 10px;
  font-size: 16px;
  line-height: 38px;
  border-radius: 5px;
  box-shadow: none;
  border: 2px solid #2b2b2b;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .reply-youtube-url {
    width: 60%;
  }
}

.post-content {
  white-space: pre-wrap;
}

.admin-delete-video-container {
  max-width: 300px;
  font-size: 9px;
}

.prdc_box-container {
  position: absolute;
  top: 90px;
}

.prdc_box {
  background: white;
}

@media screen and (min-width: 576px) {
  .prdc_box-container {
    position: static;
  }
}

.comment,
#mySearch {
  font-size: 16px;
}

@media screen and (min-width: 576px) {
  .comment,
  #mySearch {
    font-size: 18px;
  }
}

.post-content {
  font-size: 16px;
}

@media screen and (min-width: 576px) {
  .post-content {
    font-size: 18px;
  } 
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "friendwrap" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-9" }, [
          _c("div", { staticClass: "pplcontent" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-md-6 myfriend",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.inActiveFriendRequest }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-plus-circle",
                    staticStyle: {
                      "font-size": "20px",
                      color: "rgb(0, 174, 255)",
                      "-webkit-text-stroke-width": ".5px",
                      "-webkit-text-stroke-color": "black"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "strong",
                    { class: [!_vm.isFriendRequest ? "text-underline" : ""] },
                    [_vm._v("My Friends")]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4 mx-auto" }, [
                _c(
                  "div",
                  {
                    class: [
                      _vm.isFriendRequest
                        ? "button button-p7 friend_requests_selected"
                        : "button button-p7"
                    ],
                    on: { click: _vm.activeFriendRequest }
                  },
                  [
                    !_vm.isFriendRequest
                      ? _c("p", { staticClass: "p-0 m-0" }, [
                          _vm._v("\n                    View Friend Requests "),
                          _c("span", { staticClass: "new_requests" }, [
                            _vm._v("("),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.incoming_requests.length
                                )
                              }
                            }),
                            _vm._v(" new)")
                          ])
                        ])
                      : _c("p", { staticClass: "m-0 p-2" }, [
                          _vm._v(
                            "\n                    View Friends\n                  "
                          )
                        ])
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _vm.isFriendRequest
              ? _c(
                  "div",
                  { staticClass: "overflow" },
                  [
                    _vm._l(_vm.incoming_requests, function(friendship) {
                      return _c(
                        "div",
                        { key: friendship.id, staticClass: "row friendbox" },
                        [
                          _c("div", { staticClass: "col-lg-2" }, [
                            friendship.accepted
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-secondary btn-sm disabled",
                                    attrs: { disabled: "disabled" }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(" Accepted!\n                  ")
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary btn-sm",
                                    on: {
                                      click: function($event) {
                                        return _vm.acceptRequest(friendship)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-check" }),
                                    _vm._v(" Accept\n                  ")
                                  ]
                                ),
                            _vm._v(" "),
                            _c("div", [
                              _c("img", {
                                staticClass: "userimg",
                                attrs: {
                                  src: _vm.getUserPhoto(friendship.user)
                                }
                              }),
                              _vm._v(" "),
                              _c("br")
                            ]),
                            _vm._v(" "),
                            _c("strong", [_vm._v(_vm._s(friendship.user.name))])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-lg-4 frnddetails mt-2 mt-lg-0"
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(friendship.sender_reviews) +
                                      " Reviews"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(friendship.mutual) +
                                      " Mutual Friends"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(friendship.sender_friends) +
                                      " Friends"
                                  )
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-lg-6 frnddetails mt-2 mt-lg-0 friend-interests"
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-3" }, [
                                  _vm._v("Into:")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-9", staticStyle: {} },
                                  _vm._l(friendship.sender_interests, function(
                                    interest
                                  ) {
                                    return _c(
                                      "section",
                                      {
                                        key: interest.id,
                                        staticClass:
                                          "d-flex flex-column align-items-center text-center"
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "iicon",
                                          attrs: {
                                            alt: interest.name,
                                            src: "/icons/" + interest.icon
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          {
                                            staticClass: "m-0 p-0",
                                            staticStyle: {
                                              "line-height": "1.1"
                                            }
                                          },
                                          [_vm._v(_vm._s(interest.name))]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.fetchedFriendRequests &&
                    _vm.incoming_requests.length == 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "row justify-content-center align-items-center",
                            staticStyle: { height: "200px" }
                          },
                          [
                            _c("h2", [
                              _vm._v("You have no incoming friend requests")
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.fetchedFriendRequests
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "row justify-content-center align-items-center pb-3 pt-2",
                            staticStyle: { height: "100%" }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fa fa-circle-notch spin text-primary",
                              staticStyle: { "font-size": "5rem" }
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              : _c(
                  "div",
                  { class: [_vm.friends ? "overflow" : ""] },
                  [
                    !_vm.fetchedFriends
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "row justify-content-center align-items-center pb-3 pt-2",
                            staticStyle: { height: "100%" }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fa fa-circle-notch spin text-primary",
                              staticStyle: { "font-size": "5rem" }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fetchedFriends && _vm.friends.length == 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "row justify-content-center align-items-center",
                            staticStyle: { height: "200px" }
                          },
                          [_c("h2", [_vm._v("No friends were found")])]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.friends, function(friend) {
                      return _c(
                        "div",
                        { key: friend.id, staticClass: "row friendbox" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-lg-2 col-sm-2 col-xs-2" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "chk_box flex-lg-row flex-column align-items-center"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-lg-block d-flex flex-column align-items-center"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "userimg",
                                        attrs: { src: _vm.getUserPhoto(friend) }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "strong",
                                        { staticClass: "d-inline d-lg-none" },
                                        [_vm._v(_vm._s(friend.name))]
                                      ),
                                      _vm._v(" "),
                                      _c("br")
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "strong",
                                { staticClass: "d-none d-lg-block" },
                                [_vm._v(_vm._s(friend.name))]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-lg-4 col-sm-10 col-xs-10 frnddetails mt-2 mt-lg-0"
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(_vm._s(friend.reviews) + " Reviews")
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(friend.mutual_friends) +
                                      " Mutual Friends"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(friend.friends.length) + " Friends"
                                  )
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-lg-6 col-sm-12 frnddetails mt-2 mt-lg-0  friend-interests"
                            },
                            [
                              _c("div", { staticClass: "col-sm-3" }, [
                                _vm._v("Into:")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-9",
                                  staticStyle: {
                                    display: "grid",
                                    "grid-template-columns": "repeat(4, 25%)"
                                  }
                                },
                                _vm._l(friend.interests, function(interest) {
                                  return _c(
                                    "section",
                                    {
                                      key: interest.id,
                                      staticClass:
                                        "d-flex flex-column align-items-center text-center"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "iicon",
                                        attrs: {
                                          alt: interest.name,
                                          src: "/icons/" + interest.icon
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        {
                                          staticClass: "m-0 p-0",
                                          staticStyle: { "line-height": "1.1" }
                                        },
                                        [_vm._v(_vm._s(interest.name))]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-3 mt-3 mt-md-0" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 addfriend", on: { click: _vm.addFriend } },
              [_vm._v("Add Friends & Follow Influencers")]
            )
          ]),
          _vm._v(" "),
          _vm._m(0)
        ])
      ]),
      _vm._v(" "),
      _vm.isAddFriend
        ? _c("div", { staticClass: "row addfriend_box" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "addfriend_overflow" }, [
                _c("div", { staticClass: "addfriend_header" }, [
                  _c("div", { staticClass: "col-lg-12" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchFriend,
                          expression: "searchFriend"
                        }
                      ],
                      staticClass: "friend_search",
                      attrs: { type: "text", placeholder: "Search Friends..." },
                      domProps: { value: _vm.searchFriend },
                      on: {
                        keyup: _vm.searchMyNotFriend,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.searchFriend = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "frd_close",
                      on: { click: _vm.closeAddFriend }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("×")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row position-relative" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 d-flex justify-content-center align-items-center pb-3 pt-2 align-item-center p-0",
                        style:
                          _vm.fetchingNotFriends ||
                          (!_vm.fetchingNotFriends &&
                            _vm.searchFriend.replace(/\s/g, "").length > 0 &&
                            _vm.notfriends.length == 0)
                            ? "height: 200px;"
                            : ""
                      },
                      [
                        _vm.fetchingNotFriends
                          ? _c("i", {
                              staticClass:
                                "fa fa-circle-notch spin text-primary",
                              staticStyle: { "font-size": "5rem" }
                            })
                          : !_vm.fetchingNotFriends &&
                            _vm.searchFriend.replace(/\s/g, "").length > 0 &&
                            _vm.notfriends.length == 0
                          ? _c("h4", [
                              _vm._v(
                                "\n                  No results were found\n                "
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.notfriends, function(user) {
                      return user != false
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("div", { staticClass: "srch_frd flex-row" }, [
                              _c("div", { staticClass: "col-lg-5 col-md-4" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex user-info align-items-center"
                                  },
                                  [
                                    user.is_influencer
                                      ? _c(
                                          "div",
                                          { staticClass: "influencer-badge" },
                                          [_vm._v("rR")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("img", {
                                      attrs: {
                                        src: _vm.getUserPhoto(user),
                                        alt: ""
                                      }
                                    }),
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(user.name) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-lg-3 col-md-4" }, [
                                _vm.isInfluencer(user)
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-outline-secondary",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.viewInfluencerDetails(
                                              $event,
                                              user
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    View Influencer Details\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-4" }, [
                                _c("div", { staticClass: "actions-box row" }, [
                                  _c("div", { staticClass: "col-md-6 p-1" }, [
                                    _vm.isInfluencer(user)
                                      ? _c(
                                          "div",
                                          {
                                            class: _vm.getFollowClass(user),
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleFollow(user)
                                              }
                                            }
                                          },
                                          [
                                            _vm.followInProgress.includes(
                                              user.id
                                            )
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-circle-notch spin"
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !user.already_followed
                                              ? _c("span", [_vm._v("Follow")])
                                              : _c("span", [_vm._v("Unfollow")])
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-6 p-1" }, [
                                    !user.request_sent
                                      ? _c(
                                          "div",
                                          {
                                            class: [
                                              _vm.requestInProgress.includes(
                                                user.id
                                              )
                                                ? "add_frd disabled"
                                                : "add_frd"
                                            ],
                                            on: {
                                              click: function($event) {
                                                return _vm.onAddFriend(user)
                                              }
                                            }
                                          },
                                          [
                                            _vm.requestInProgress.includes(
                                              user.id
                                            )
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-circle-notch spin"
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                        Add Friend\n                      "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "add_frd request_sent"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-check"
                                            }),
                                            _vm._v(
                                              "\n                        Request sent\n                      "
                                            )
                                          ]
                                        )
                                  ])
                                ])
                              ])
                            ])
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.showInfluencerDialog
                            ? "influencer-details show"
                            : "influencer-details"
                        ],
                        attrs: { id: "influencer-dialog" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "close",
                            attrs: { href: "#" },
                            on: { click: _vm.closeInfluencerDialog }
                          },
                          [_c("i", { staticClass: "fa fa-times" })]
                        ),
                        _vm._v(" "),
                        _c("p", [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Followers:")
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "text-underline",
                            domProps: {
                              textContent: _vm._s(
                                _vm.selectedInfluencer.followers
                              )
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Dollars created from posts:")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-underline" }, [
                            _vm._v(
                              "\n                  $" +
                                _vm._s(
                                  _vm.selectedInfluencer.dollars_from_posts
                                ) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._m(1),
                        _c("p", [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.selectedInfluencer.top_4_interests.join(
                                  ", "
                                )
                              ) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p")
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" })
                  ],
                  2
                )
              ])
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        staticStyle: { top: "30vh" },
        attrs: {
          id: "invite-modal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "modelTitleId",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass:
                    "modal-body d-flex justify-content-center flex-column pt-5"
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("h4", { staticClass: "mb-3" }, [
                    _vm._v(
                      "\n              Copy and paste this into an e-mail to friends you wish to invite:\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "textarea",
                    {
                      staticStyle: {
                        position: "absolute",
                        height: "0",
                        opacity: "0",
                        "z-index": "-1"
                      },
                      attrs: { id: "invitation-email" }
                    },
                    [
                      _vm._v(
                        "              <p>Hey!</p>\n              I'm using rRipple, a platform where we can see each other's reviews of products, movies, tv shows, etc - but only in areas both of us are interested in. \n               \t<a href=\"" +
                          _vm._s(_vm.referral_link) +
                          '">Click through to rRipple</a> (https://www.rRippled.com) and sign up using Facebook so it will automatically friend us!\n            '
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("blockquote", { staticClass: "blockquote" }, [
                    _c("p", { staticClass: "mb-1" }, [_vm._v("Hey!")]),
                    _vm._v(
                      "\n              I'm using rRipple, a platform where we can see each other's reviews of products, movies, tv shows, etc - but only in areas both of us are interested in. \n              "
                    ),
                    _c("a", { attrs: { href: _vm.referral_link } }, [
                      _vm._v("Click through to rRipple")
                    ]),
                    _vm._v(
                      " (https://www.rRippled.com) and sign up using Facebook so it will automatically friend us!\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: { click: _vm.copyEmailToClipboard }
                    },
                    [
                      _c("i", { class: _vm.emailCopyIcon }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.emailCopyText) }
                      })
                    ]
                  )
                ]
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-2" }, [
      _c(
        "div",
        {
          staticClass: "wrapbox",
          attrs: { "data-toggle": "modal", "data-target": "#invite-modal" }
        },
        [
          _c("img", {
            staticClass: "emailicon",
            attrs: { src: "/images/email_icon.png" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ml-3" }, [
            _vm._v("Invite friends via email")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v("Top 4 Interests Reviewed:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        staticStyle: { position: "absolute", top: "20px", right: "20px" },
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "discussion" } }, [
        _c("div", { staticClass: "discussion_container" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm.discussions.length === 0
            ? _c("div", { staticClass: "px-3 pb-3" }, [
                _vm._v("Go to my groups tab to create or join groups!")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.discussions.length > 0
            ? _c("h4", { staticClass: "ml-3 mt-2" }, [
                _vm._v("(click the group name below to enter that group chat)")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "discussion_overflow d-flex pt-2 pb-2" },
            _vm._l(_vm.discussions, function(item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "topic_box border-0",
                  style:
                    item == _vm.discussions[_vm.activeTopicIndex]
                      ? "transform: scale(.95);"
                      : "",
                  on: {
                    click: function($event) {
                      return _vm.makeActive(index)
                    }
                  }
                },
                [
                  item == _vm.discussions[_vm.activeTopicIndex]
                    ? _c(
                        "div",
                        {
                          staticClass: "selectedItem bg-primary",
                          staticStyle: { border: "1px outset #000 !important" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.topic) +
                              "\n            "
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "text-center m-0",
                              staticStyle: { "font-size": "14px" }
                            },
                            [_vm._v("(active below)")]
                          )
                        ]
                      )
                    : _c("span", { staticClass: "selectedItem" }, [
                        _vm._v(_vm._s(item.topic))
                      ])
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm.discussions.length > 0 && _vm.discussions[_vm.activeTopicIndex]
          ? _c("div", { staticClass: "discussion_container" }, [
              _c("div", { staticClass: "myfriend p-0" }, [
                _c("b", [
                  _vm._v(
                    "\n          Topic: " +
                      _vm._s(_vm.discussions[_vm.activeTopicIndex].topic) +
                      " (" +
                      _vm._s(
                        _vm.discussions[_vm.activeTopicIndex].posts.length
                      ) +
                      ")\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "discussion_overflow" },
                _vm._l(_vm.discussions[_vm.activeTopicIndex].posts, function(
                  post
                ) {
                  return _c(
                    "div",
                    { key: post.id, staticClass: "topic_chatbox" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-between"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "px-3 mb-2 d-flex align-items-center"
                            },
                            [
                              _vm.isInfluencer(post.user)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rr-badge rr-badge--small rr-badge--inline mr-2"
                                    },
                                    [_vm._v("rR")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "topic_img",
                                attrs: { src: post.user.avatar_url }
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(post.user.name) +
                                  "\n              "
                              ),
                              _vm.isInfluencer(post.user)
                                ? _c("InfluencerBadge", {
                                    attrs: { type: "super" }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.discussions[_vm.activeTopicIndex].is_owner
                            ? _c("div", { staticClass: "mr-2" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button button-sm",
                                    on: {
                                      click: function($event) {
                                        _vm.deleting = [
                                          _vm.discussions[_vm.activeTopicIndex]
                                            .id,
                                          post.id
                                        ]
                                      }
                                    }
                                  },
                                  [_vm._v("Delete Post")]
                                ),
                                _vm._v(" "),
                                !post.is_mine
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "button button-sm",
                                        on: {
                                          click: function($event) {
                                            _vm.kicking = [
                                              _vm.discussions[
                                                _vm.activeTopicIndex
                                              ].id,
                                              post.user_id
                                            ]
                                          }
                                        }
                                      },
                                      [_vm._v("Ban User")]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "col-lg-12 post-content",
                        domProps: { innerHTML: _vm._s(post.content) }
                      }),
                      _vm._v(" "),
                      post.youtube_url
                        ? _c(
                            "div",
                            { staticClass: "col-lg-12 pt-2" },
                            [
                              _c("youtube", {
                                attrs: {
                                  "video-id": _vm.getIdFromYouTubeURL(
                                    post.youtube_url
                                  ),
                                  "player-width": "300",
                                  "player-height": "180"
                                }
                              }),
                              _vm._v(" "),
                              _vm.isAdmin
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-right admin-delete-video-container"
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.adminDeleteVideo(post)
                                            }
                                          }
                                        },
                                        [_vm._v("Admin Delete Video")]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm.discussions.length > 0
          ? _c("div", { staticClass: "discussion_container" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("div", { staticClass: "row position-relative" }, [
                  _c("div", { staticClass: "col-lg-12" }, [
                    _c("div", { staticClass: "row dtlsHeader" }, [
                      _c("div", { staticClass: "col-lg-7" }, [
                        _c("b", [
                          _vm._v(
                            "Topic: " +
                              _vm._s(
                                _vm.discussions[_vm.activeTopicIndex].topic
                              )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "SeacrhBox col-lg-5" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchText,
                              expression: "searchText"
                            }
                          ],
                          attrs: {
                            type: "text",
                            id: "mySearch",
                            placeholder: "Tag a product in your post",
                            title: "Tag a product in your post",
                            "data-toggle": "tooltip"
                          },
                          domProps: { value: _vm.searchText },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.searchText = $event.target.value
                              },
                              function($event) {
                                return _vm.searchProducts($event.target.value)
                              }
                            ]
                          }
                        })
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.searchText.length ? "col-lg-7" : "col-lg-12" },
                    [
                      _c(
                        "div",
                        { staticClass: "text-container" },
                        [
                          _c("quill-editor", {
                            ref: "comment",
                            staticStyle: {
                              position: "relative",
                              height: "170px"
                            },
                            attrs: { options: _vm.quillOptions },
                            on: {
                              change: function($event) {
                                return _vm.onEditorChange($event)
                              }
                            },
                            model: {
                              value: _vm.comment,
                              callback: function($$v) {
                                _vm.comment = $$v
                              },
                              expression: "comment"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "reply-controls" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.youtubeUrl,
                                  expression: "youtubeUrl"
                                }
                              ],
                              staticClass: "reply-youtube-url",
                              attrs: {
                                id: "add-youtube-url",
                                placeholder:
                                  "Add YouTube video by entering URL here & submitting...",
                                title:
                                  "Add YouTube video by entering URL here & submitting...",
                                "data-toggle": "tooltip"
                              },
                              domProps: { value: _vm.youtubeUrl },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.youtubeUrl = $event.target.value
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "button py-1 button-blk",
                                on: { click: _vm.submitMessage }
                              },
                              [
                                _c("span", { staticClass: "d-md-none" }, [
                                  _vm._v("Send")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-md-inline-block" },
                                  [_vm._v("Submit Message")]
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.searchText.length,
                          expression: "searchText.length"
                        }
                      ],
                      staticClass: "col-lg-5 prdc_box-container"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "prdc_box",
                          attrs: { id: "product-search-results" }
                        },
                        [
                          _c(
                            "ul",
                            { attrs: { id: "myMenu" } },
                            [
                              _vm.searchText.length < 3
                                ? _c("div", [
                                    _vm._v("Please enter at least 3 characters")
                                  ])
                                : !_vm.products.length &&
                                  !_vm.fetchingNewProducts
                                ? _c("div", [_vm._v("No Results")])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.products.length
                                ? _vm._l(_vm.products, function(item, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: "product_" + index,
                                        staticClass: "mb-2 product",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function($event) {
                                            return _vm.insertProduct(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            src: item.photo_featured
                                              ? item.photo_featured
                                              : item.image_link
                                              ? item.image_link
                                              : "/icons/default.png",
                                            alt: ""
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("div", [_vm._v(_vm._s(item.name))])
                                      ]
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.fetchingNewProducts &&
                              _vm.products.length < _vm.productsExpectedResults
                                ? _c(
                                    "li",
                                    [
                                      _c(
                                        "vue-content-loading",
                                        { attrs: { width: 450, height: 30 } },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              x: "0",
                                              y: "0",
                                              rx: "4",
                                              ry: "4",
                                              width: "40",
                                              height: "30"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("rect", {
                                            attrs: {
                                              x: "50",
                                              y: "5",
                                              rx: "4",
                                              ry: "4",
                                              width: "200",
                                              height: "15"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      )
                    ]
                  )
                ])
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.kicking
        ? _c("confirm-modal", {
            attrs: {
              message: "Are you sure you want to kick this user from the group?"
            },
            on: {
              confirm: function($event) {
                return _vm.kickUser(_vm.kicking[0], _vm.kicking[1])
              },
              close: function($event) {
                _vm.kicking = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.deleting
        ? _c("confirm-modal", {
            attrs: {
              message:
                "Are you sure you want to delete this post from the group?"
            },
            on: {
              confirm: function($event) {
                return _vm.deletePost(_vm.deleting[0], _vm.deleting[1])
              },
              close: function($event) {
                _vm.deleting = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "myfriend p-0" }, [
      _c("i", {
        staticClass: "fa fa-plus-circle",
        staticStyle: {
          "font-size": "20px",
          color: "rgb(0, 174, 255)",
          "-webkit-text-stroke-width": ".5px",
          "-webkit-text-stroke-color": "black"
        }
      }),
      _vm._v(" "),
      _c("b", [_vm._v("Group Chat")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
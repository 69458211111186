<template>
    <div class="rating">
        <ul class="list">
            <li @click="rate(star)" v-for="star in maxStars" :class="{ 'active': star <=stars }" class="star">
                <!-- <icon :name="star <= stars ? 'star' : 'star-o'"/> -->
                <i :class="[star <= stars ? 'fa fa-star' : 'far fa-star']"></i>
            </li>
            <li @click="moon()" :class="{ 'active': moonIsActive }" class="moon">
                <!-- <icon name="moon-o"/> -->
                <i :class="[moonIsActive ? 'fa fa-moon' : 'far fa-moon']"></i>
            </li>
        </ul>
        <span v-if="moonIsActive">6 of {{ maxStars }}</span>
        <span v-else>{{ stars }} of {{ maxStars }}</span>
    </div>
</template>

<script>
    import 'vue-awesome/icons/star'
    import 'vue-awesome/icons/star-o'
    import 'vue-awesome/icons/moon-o'

    import Icon from 'vue-awesome/components/Icon'

    export default {
        components: {Icon},
        props: ['grade', 'maxStars', 'moonActive'],
        data() {
            return {
                stars: this.grade,
                moonIsActive: this.moonActive,
            }
        },
        methods: {
            rate(star) {
                if(this.stars === star) {
                    this.stars = this.stars - 1
                } else {
                    this.stars = star
                }
                if(this.moonIsActive) {
                    this.moonIsActive = !this.moonIsActive
                }
                this.$emit('rating-set', this.stars)
            },
            moon() {
                this.stars = 5;
                this.moonIsActive = !this.moonIsActive
                if(this.moonIsActive) {
                    this.$emit('rating-set', this.stars + 1)
                }
            }
        }
    }
</script>

<style>
    .rating {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        font-size: 14px;
        color: #a7a8a8;
    }

    .list {
        margin: 0 0 5px 0;
        padding: 0;
        list-style-type: none;
    }

    .list:hover .star {
        color: #f3d23e;
    }

    .star {
        display: inline-block;
        cursor: pointer;
    }

    .star:hover ~ .star:not(.active) {
        color: inherit;
    }

    .active {
        color: #f3d23e;
    }

    .moon {
        display: inline-block;
        cursor: pointer;
    }

</style>
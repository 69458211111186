var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { on: { click: _vm.hideSearch } }, [
    !_vm.loading
      ? _c("div", { staticClass: "group-container" }, [
          _vm.mode === "view"
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center flex-column flex-lg-row search-and-join"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchTerm,
                            expression: "searchTerm"
                          }
                        ],
                        staticClass: "form-control w-75 mb-2 mb-md-0",
                        attrs: { type: "text", placeholder: "Group Name" },
                        domProps: { value: _vm.searchTerm },
                        on: {
                          keyup: function($event) {
                            return _vm.triggerSearch()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.searchTerm = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "button-container ml-sm-0 mt-2 mt-lg-0 ml-lg-2"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button join dropdown-toggle",
                              attrs: {
                                id: "dropdownMenuButton",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.search(_vm.searchTerm)
                                }
                              }
                            },
                            [_vm._v("Search & Join Groups")]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown",
                      attrs: { id: "groups-dropdown" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "dropdownMenuButton" }
                        },
                        [
                          _vm._l(_vm.groupSearchResults, function(group) {
                            return _c(
                              "div",
                              { staticClass: "d-flex align-items-center px-4" },
                              [
                                _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "join-button",
                                      on: {
                                        click: function($event) {
                                          return _vm.joinGroup(group)
                                        }
                                      }
                                    },
                                    [_vm._v("Join")]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "flex-grow-1 ml-3" },
                                  [_vm._v(_vm._s(group.topic))]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _c("img", {
                                    staticClass: "rounded",
                                    attrs: {
                                      height: "30px",
                                      width: "30px",
                                      src: "/storage/" + group.image_url
                                    }
                                  })
                                ])
                              ]
                            )
                          }),
                          _vm._v(" "),
                          !_vm.groupSearchResults ||
                          _vm.groupSearchResults.length === 0
                            ? _c("div", { staticClass: "text-center" }, [
                                _c("p", [_vm._v("No Results")])
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 d-none d-md-flex" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button create-btn",
                      on: {
                        click: function($event) {
                          _vm.mode = "create"
                        }
                      }
                    },
                    [_vm._v("+ Create Group")]
                  )
                ])
              ])
            : _vm.mode === "create"
            ? _c("div", { staticClass: "d-flex justify-content-end" }, [
                _c(
                  "button",
                  {
                    staticClass: "button btn-block back",
                    on: {
                      click: function($event) {
                        _vm.mode = "view"
                      }
                    }
                  },
                  [_vm._v("Back to View")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.mode === "view"
            ? _c("div", { staticClass: "row mt-2" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "card box-card" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _vm.leaving
                          ? _c("confirm-modal", {
                              attrs: {
                                message:
                                  "Are you sure you want to leave this group?"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.leaveGroup(_vm.leaving)
                                },
                                close: function($event) {
                                  _vm.leaving = null
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.groups.groups && _vm.groups.groups.length
                          ? _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(_vm.groups.groups, function(group) {
                                return _c(
                                  "div",
                                  { staticClass: "col-md-6 mb-4" },
                                  [
                                    _c(
                                      "h4",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.discuss(group)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(group.topic))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "thumb mx-auto" },
                                      [
                                        _c("img", {
                                          staticClass: "img-fluid",
                                          attrs: {
                                            src: "/storage/" + group.image_url
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.discuss(group)
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "leave",
                                            on: {
                                              click: function($event) {
                                                _vm.leaving = group
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times"
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _c(
                              "div",
                              { staticClass: "d-flex justify-content-center" },
                              [_c("h5", [_vm._v("No Groups")])]
                            )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card box-card mt-4" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _vm.groups.invited_groups &&
                      _vm.groups.invited_groups.length
                        ? _c(
                            "div",
                            { staticClass: "row" },
                            _vm._l(_vm.groups.invited_groups, function(group) {
                              return _c(
                                "div",
                                { staticClass: "col-md-6 mb-4" },
                                [
                                  _c("h4", [_vm._v(_vm._s(group.topic))]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "thumb mx-auto" }, [
                                    _c("img", {
                                      staticClass: "img-fluid",
                                      attrs: {
                                        src: "/storage/" + group.image_url
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "accept",
                                        on: {
                                          click: function($event) {
                                            return _vm.acceptInvite(group)
                                          }
                                        }
                                      },
                                      [_vm._v("accept")]
                                    )
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            { staticClass: "d-flex justify-content-center" },
                            [_c("h5", [_vm._v("No Invites")])]
                          )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "d-md-none mt-4 mb-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button create-btn",
                        on: {
                          click: function($event) {
                            _vm.mode = "create"
                          }
                        }
                      },
                      [_vm._v("+ Create Group")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card box-card" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _vm.deleting
                          ? _c("confirm-modal", {
                              attrs: {
                                message:
                                  "Are you sure you want to delete this group?"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.deleteGroup(_vm.deleting)
                                },
                                close: function($event) {
                                  _vm.deleting = null
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.inviting
                          ? _c("invite-modal", {
                              attrs: { group: _vm.inviting },
                              on: {
                                close: function($event) {
                                  _vm.inviting = null
                                },
                                invited: _vm.handleInvited
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.groups.owned_groups &&
                        _vm.groups.owned_groups.length
                          ? _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(_vm.groups.owned_groups, function(group) {
                                return _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-6 mb-4 position-relative"
                                  },
                                  [
                                    _c(
                                      "h4",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.discuss(group)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(group.topic))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "thumb mx-auto" },
                                      [
                                        _c("img", {
                                          staticClass: "img-fluid",
                                          staticStyle: {
                                            height: "100%",
                                            width: "100%"
                                          },
                                          attrs: {
                                            src: "/storage/" + group.image_url
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.discuss(group)
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "leave",
                                            on: {
                                              click: function($event) {
                                                _vm.deleting = group
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times"
                                            })
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "invite",
                                          on: {
                                            click: function($event) {
                                              _vm.inviting = group
                                            }
                                          }
                                        },
                                        [_vm._v("Invite Users")]
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          : _c(
                              "div",
                              { staticClass: "d-flex justify-content-center" },
                              [_c("h5", [_vm._v("No Owned Groups")])]
                            )
                      ],
                      1
                    )
                  ])
                ])
              ])
            : _vm.mode === "create"
            ? _c("div", { staticClass: "d-flex justify-content-center" }, [
                _c("div", { staticClass: "card create mt-2" }, [
                  _c("div", { staticClass: "card-header" }, [
                    _vm._v("\n          Create a new Group\n        ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _vm.errors
                      ? _c(
                          "div",
                          { staticClass: "alert-danger alert" },
                          _vm._l(_vm.errors, function(err) {
                            return _c("div", [_vm._v(_vm._s(err[0]))])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Group Name")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.group.name,
                            expression: "group.name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.group.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.group, "name", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Group Description")]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.group.description,
                            expression: "group.description"
                          }
                        ],
                        staticClass: "form-control",
                        domProps: { value: _vm.group.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.group,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Group Thumbnail")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column align-items-center mb-2",
                          on: {
                            click: function($event) {
                              return _vm.browseFiles()
                            }
                          }
                        },
                        [
                          _vm.image
                            ? _c("img", {
                                staticClass: "img-thumbnail upload_image",
                                attrs: { src: _vm.image }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control-file",
                            attrs: { id: "upload-group-image", type: "file" },
                            on: { change: _vm.onFileChange }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-block back",
                            on: { click: _vm.save }
                          },
                          [_vm._v("Create Group")]
                        )
                      ]
                    )
                  ])
                ])
              ])
            : _vm._e()
        ])
      : _c("div", { staticClass: "my-5" }, [_c("h2", [_vm._v("Loading...")])])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("span", { staticClass: "text-pointer" }, [
        _c("i", {
          staticClass: "fa fa-plus-circle mr-1",
          staticStyle: {
            "font-size": "20px",
            color: "rgb(0, 174, 255)",
            "-webkit-text-stroke": "0.5px black"
          }
        }),
        _vm._v("groups I'm in")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("span", { staticClass: "text-pointer" }, [
        _c("i", {
          staticClass: "fa fa-plus-circle mr-1",
          staticStyle: {
            "font-size": "20px",
            color: "rgb(0, 174, 255)",
            "-webkit-text-stroke": "0.5px black"
          }
        }),
        _vm._v("groups I'm invited to")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("span", { staticClass: "text-pointer" }, [
        _c("i", {
          staticClass: "fa fa-plus-circle mr-1",
          staticStyle: {
            "font-size": "20px",
            color: "rgb(0, 174, 255)",
            "-webkit-text-stroke": "0.5px black"
          }
        }),
        _vm._v("groups I created")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
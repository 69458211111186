import Vue from 'vue'
import Vuex from 'vuex'

import media from './modules/media'
import product from './modules/product'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        media,
        product
    }
})

<template>
    <div>
        <form class="row checkout-wrapper" id="checkout-form" method="POST"
            @submit.prevent="pay" v-if="!succeeded">
            <div class="col-md-8 mx-auto pl-5 pr-5 pt-3 pb-3 bg-white">
                <div class="loading-overflow text-center flex-column" v-show="!cart || attempting">
                    <i class="fa fa-circle-notch spin"></i>
                    <h2 class="mt-3" v-text="loading_text"></h2>
                </div>
                
                <transition name="fade">
                    <div class="alert alert-danger alert-dismissible fade show text-left" role="alert" id="error-alert" v-show="error">
                        <button type="button" class="close" aria-label="Close" @click="error = null">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <p class="m-0" v-html="error">
                        </p>
                    </div>
                </transition>

                <div class="row justify-content-center">
                    <div class="title">                
                        <i class="fa fa-shopping-bag"></i>
                        Checkout
                    </div>
                </div>

                <address-form wrapper="billing" :autofill_data="billing_address" :on-change="onAddressFieldChange">
                    <template v-slot:append>
                        <div class="row">
                            <div class="col-12 text-left">
                                <div class="form-check">
                                <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" name="ship_is_bill" id="ship_is_bill"
                                        checked @click="toggleShippingForm()">
                                    Shipping address is the same as billing
                                </label>
                                </div>
                            </div>
                        </div>
                    </template>
                </address-form>

                <address-form id="shipping-address" hidden="true" css="margin-top: 1rem;"
                    title="Shipping Address"
                    wrapper="shipping"
                    :phone="false"
                    :autofill_data="shipping_address"
                    :on-change="onAddressFieldChange"></address-form>

                <card-form css="margin-top: 1rem;" :card-error="cardError"></card-form>

                <div class="row mb-2">
                    <div class="col-12 text-left">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" name="private"
                                value="true">
                            Private Purchase
                          </label>
                        </div>
                    </div>
                </div>

                <div class="row flex-column border border-dark m-2 p-2 shadow-sm">
                    <div class="col-12 text-left">
                        <strong>Cart Total:</strong> <span v-text="'$' + numeral(cartTotal).format('0,0.00')"></span>
                    </div>
                    <div class="col-12 text-left">
                        <strong>Shipping Rate:</strong> <span v-text="'$' + numeral(shipping_rate).format('0,0.00')"></span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <button type="button" class="btn btn-primary btn-block font-weight-bold"
                        :disabled="attempting" @click="fetchPaymentToken()">
                        <i class="fa fa-circle-notch spin" v-if="attempting"></i>
                            Pay <span v-if="cart && !attempting">${{ total }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <div v-else class="col-md-8 mx-auto pl-5 pr-5 pt-3 pb-3 bg-white justify-content-center align-items-center"
            style="height: 100%;"><i style="" class="fa fa-check-circle text-success display-3"></i>
            <h1>Order Confirmation</h1>
            <h5 class="text-muted">Your order has been successfully submitted.</h5>
            <h4 class="">Order Number: <span v-text="order_num"></span></h4>
            <a class="btn btn-primary text-white" href="/home">Return to homepage</a>
        </div>
    </div>
</template>

<style>
.divider {
	height: 1px;
	width: 80%;
	background: #b8bcbf;
	margin-top: -5px;
}

.checkout-wrapper .title {
	font-size: 28px;
	padding-bottom: 1rem;
	margin-top: 1rem;
	color: #5d5d5d;
}

.loading-overflow {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 2;
	background: #fff;
	height: 100%;
	opacity: .5;
	justify-content: center;
	align-items: center;
	font-size: 5rem;
}
</style>

<script>
import Swal from 'sweetalert2';
const numeral = require('numeral');
require('../notify');

export default {
    created() {
        this.getCheckout();
        // Configure CollectJS when the document is ready
        $(() => {
            CollectJS.configure({
                styleSniffer: true,
                callback: this.pay,
                validationCallback: (field, status, message) => {
                    if (status) {
                        this.cardError = null;
                    } else {
                        this.attempting = false;
                        this.cardError = `${this.field_names[field]} ${message.toLowerCase()}`;
                    }
                },
                placeholderCss: {
                    'font-weight': '400',
                    'font-size': '14.4px',
                    '@font-face': `{
                        font-family: 'levenim_mtregular';
                        src: url('https://rrippled.com/storage/fonts/lvnm_1.ttf') format('ttf'),
                            url('https://rrippled.com/storage/fonts/lvnm_1-webfont.woff2') format('woff2'),
                            url('https://rrippled.com/storage/fonts/lvnm_1-webfont.woff') format('woff');
                        font-weight: normal;
                        font-style: normal;
                    }`,
                    fontFamily: 'levenim_mtregular',
                },
                customCss: {
                    fontFamily: 'levenim_mtregular',
                    'font-weight': '400',
                    'font-size': '14.4px',
                    '@font-face': `{
                        font-family: 'levenim_mtregular';
                        src: url('https://rrippled.com/storage/fonts/lvnm_1.ttf') format('ttf'),
                            url('https://rrippled.com/storage/fonts/lvnm_1-webfont.woff2') format('woff2'),
                            url('https://rrippled.com/storage/fonts/lvnm_1-webfont.woff') format('woff');
                        font-weight: normal;
                        font-style: normal;
                    }`,
                    fontFamily: 'levenim_mtregular',
                },
                focusCss: {
                    'box-shadow': 'rgba(0, 123, 255, 0.25) 0px 0px 0px 0.2rem',
                }
            });
        });
    },
    data() {
        return {
            cart: null,
            billing_address: null,
            shipping_address: null,
            loading: true,
            loading_text: '',
            calculating_shipping: false,
            attempting: false,
            error: null,
            cardError: null,
            order_num: 0,
            succeeded: false,
            field_names: {
                ccnumber: 'Card Number',
                cvv: 'Secret Code',
                ccexp: 'Expiration Date'
            },
            shipping_rate: 0,
            shipping_request_source: null,
        };
    },
    computed: {
        checkoutForm() {
            return $('#checkout-form');
        },
        cartTotal() {
            let total = 0;

            if (!this.cart) {
                return '-';
            }
    
            for (let i = 0; i < this.cart.length; i++) {
                let item = this.cart[i];
                const basePrice = item.variant
                    ? item.variant.price
                    : item.product.product_price;

                total += basePrice * item.quantity;
            }

            return total;
        },
        total() {
            if (!this.cart) {
                return '-';
            }

            return numeral(this.cartTotal + this.shipping_rate).format('0,0.00');
        },
    },
    methods: {
        numeral,
        toggleShippingForm() {
            if (!this.shipping_address) {
                // Clear all values in the shopping address form
                $('#shipping-address').find('input').val('');
            }
            // Toggle the shipping address form
            $('#shipping-address').toggle();
            
            if ($('#shipping-address').is(':visible')) {
                // Scroll to the "shipping address" form
                this.scrollTo('#shipping-address');
            }
        },
        getCheckout() {
            axios.get('/checkout/start').then(({ data }) => {
                // If there are no cart items, redirect to the cart page
                if (!data.has_items) {
                    return this.$router.push({ name: 'shopping-cart'});
                }

                this.loading = false;
                this.cart = data.cart;

                if (data.latest_order) {
                    this.billing_address = JSON.parse(data.latest_order.billing_address);
                    this.shipping_address = JSON.parse(data.latest_order.shipping_address);

                    if (this.shipping_address) {
                        $('#ship_is_bill').prop('checked', false);
                        this.toggleShippingForm();
                    }
                }
            });
        },
        pay(response) {
            if (this.error || this.cardError) {
                return;
            }

            // Get the form inputs prepared to be sent to the server
            let requestData = this.checkoutForm.getFormData();
            // Add the payment token to the request data
            requestData.payment_token = response.token;
            // Add cart details (retrieved from Collect.js) to the request data
            requestData.card = response.card;

            // Send the request to the server with the prepared data
            axios.post('/checkout/pay', requestData).then(({ data }) => {
                if (data.success) {
                    this.order_num = data.displayable_id;
                    this.succeeded = true;
                    $('.cart-items-count').val(0);
                }
            })
            .catch((error) => { // On error
                // If the code is 422 and error.response.data.errors exists
                // then we know it is a validation error
                if (error.response.status == 422 && error.response.data.errors) {
                    // Therefore, format the errors and display them
                    this.error = Object.values(error.response.data.errors).join("<br/>");
                } else {
                    // Otherwise, display the default error message.
                    this.error = error.message;
                }

                // Scroll to the "error alert" form
                this.scrollTo('#error-alert');
            }).finally(() => {
                this.attempting = false;
            });
        },
        fetchPaymentToken() {
            // Set the page state to "attempting"
            this.attempting = true;
            // Clear any existing error
            this.error = null;
            // Fetch the payment token
            CollectJS.startPaymentRequest();
        },
        objectifyForm(formArray) {
            var returnArray = {};
            for (var i = 0; i < formArray.length; i++){
                returnArray[formArray[i]['name']] = formArray[i]['value'];
            }

            return returnArray;
        },
        scrollTo(selector, duration = 1000) {
            $('html, body').animate({
                scrollTop: $(selector).offset().top
            }, duration);
        },
        onAddressFieldChange(e) {
            let fields = [
                // 'first_name',
                // 'last_name',
                // 'company',
                // 'address1',
                // 'city',
                // 'state',
                'zip',
                'country',
            ];
            const wrapper = $('input[name=ship_is_bill]').is(':checked')
                ? 'billing'
                : 'shipping';

            for (let i in fields) {
                const name = fields[i];
                const field = $('[name="' + wrapper + '[' + name + ']"]')

                if (field.length > 0 && field.val().length < 3) {
                    return false;
                }
            }

            this.getShippingRate();
        },
        getShippingRate() {
            if (this.calculating_shipping) {
                return;
            }

            let data = this.checkoutForm.getFormData();
            this.attempting = true;
            this.calculating_shipping = true;
            this.loading_text = 'Calculating the shipping rate...';
            
            axios.post('/checkout/shipping-rate', data).then(({ data }) => {
                this.shipping_rate = data.total;
            }).catch(err => {
                if (axios.isCancel(err)) {
                    return;
                }

                err = err.response;

                let message = 'An error has occured. Please refresh the page and try again.';

                if ('data' in err) {
                    if ('message' in err.data) {
                        message = err.data.message
                    }

                    if ('errors' in err.data) {
                        message = '';
                        
                        Object.keys(err.data.errors).forEach(key => {
                            message += err.data.errors[key].join("\n") + "\n"
                        });
                    }
                }
                
                $.notify(message, 'error')

            }).finally(() => {
                this.attempting = false;
                this.calculating_shipping = false;
                this.loading_text = '';
            });
        }
    },
    // beforeRouteLeave(to, from, next) {
    //     if (this.order_num != 0 || this.loading) {
    //         return next();
    //     }

    //     Swal.fire({
    //         title: 'Do you really want to leave?!',
    //         text: 'You have unsaved changes!',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#d33',
    //         cancelButtonColor: '#3085d6',
    //         confirmButtonText: 'Yes',
    //         focusCancel: true,
    //     }).then(confirmed => {
    //         if (confirmed.value) {
    //             return next();
    //         }

    //         next(false);
    //     });
    // },
}
</script>
<template>
    <div :style="css">
        <div class="row justify-content-start pb-2 pl-3" v-show="title">
            <h3 class="text-muted">
                {{ title }}
            </h3>
            <div class="divider"></div>
            
            <div class="alert alert-danger w-100 mb-1 mt-1" role="alert" v-if="cardError">
                {{ cardError }}
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <div id="ccnumber"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <div id="ccexp"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <div id="cvv"></div>
                </div>
            </div>
        </div>
        <input type="hidden" name="payment_token">
    </div>
</template>

<script>
export default {
    mounted() {
        const styles = {
            default: {
                "display": "block",
                "width": "100%",
                "height": "calc(1.6em + 0.75rem + 2px)",
                "padding": "0.375rem 0.75rem",
                "font-size": "0.9rem",
                "font-weight": "400",
                "line-height": "1.6",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid #ced4da",
                "border-radius": "0.25rem",
                "transition": "border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out",
                "transition": "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                "transition": "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out",
            },
            focus: {
                "color": "#495057",
                "background-color": "#fff",
                "border-color": "#80bdff",
                "outline": "0",
                "box-shadow": "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
            },
            invalid: {
                "color": "red",
                "border-color": "red",
            }
        };

        CollectJS.configure({
            "variant": "inline",
            "styleSniffer": "true",
            "customCss": JSON.stringify(styles.default),
            "focusCss": JSON.stringify(styles.focus),
            "invalidCss": JSON.stringify(styles.focus),
            "fields": {
                "ccnumber": {
                    "placeholder": "Card Number",
                },
                "ccexp": {
                    "placeholder": "Expiration Date",
                },
                "cvv": {
                    "placeholder": "Secret Code (CVV)",
                },
            },
        });
    },
    props: {
        title: {
            required: false,
            default: 'Payment Details',
        },
        css: {
            required: false,
        },
        cardError: {
            required: false,
        },
    },
}
</script>
import { collect } from 'collect.js'
import { searchProductsSimple, getCachedResults } from '../../api/products'

const state = {
  productSearchQuery: '',
  productSearchCategory: '',
  products: [],
  searchInProgress: false,
  searchComplete: false,
  searchError: false,
  cacheRetrieved: false,
  page: 1,
  pagination: [],
  loadMore: true,
  isForceStopped: false,
  expected_results: 20,
  loadingText: 'Searching...',
  stillSearchingTimeout: null,
  almostDoneTimeout: null,
  reloadTimeout: null,
  progressBarPercent: 0,
  progressBarInterval: null,
  subscribedToPusher: false,
}

const getters = {
  productSearchQuery: state => state.productSearchQuery,
  productSearchCategory: state => state.productSearchCategory,
  getProducts: state => state.products ?? [],
  searchInProgress: state => state.searchInProgress && !state.cacheRetrieved,
  searchError: state => state.searchError,
  cacheRetrieved: state => state.cacheRetrieved,
  page: state => state.page,
  pagination: state => state.pagination,
  searchComplete: state => state.searchComplete,
  loadMore: state => state.loadMore,
  isForceStopped: state => state.isForceStopped,
  expectedResults: state => state.expected_results ?? 100,
  loadingText: state => state.loadingText,
  stillSearchingTimeout: state => state.stillSearchingTimeout,
  almostDoneTimeout: state => state.almostDoneTimeout,
  reloadTimeout: state => state.reloadTimeout,
  progressBarPercent: state => state.progressBarPercent,
  progressBarInterval: state => state.progressBarInterval,
  subscribedToPusher: state => state.subscribedToPusher,
}

const actions = {
  async searchProducts({ commit, dispatch, getters }, { productSearchQuery, productSearchCategory }) {
  
    if (state.page == 1) {
      commit('setProductQuery', { productSearchQuery })
      commit('setProductSearchCategory', { productSearchCategory })
      commit('setSearchInProgress', true);
      commit('setCacheRetrieved', false);
      commit('setSearchError', false);
      commit('setIsForceStopped', false);
      commit('setExpectedResults', 10000);
      commit('setProducts', { products: [] })
      commit('setProgressBarPercent', 0);
      dispatch('startTimeouts');
    }


    try {
      let data = await searchProductsSimple(productSearchQuery, productSearchCategory, state.page);
      let products = data.products;
      let expected_results = data.expected_results;

      if (products.length > 0) {
        clearInterval(state.progressBarInterval);
        commit('setProgressBarPercent', 100);

        dispatch('clearTimeouts');
      }

      commit('setExpectedResults', expected_results);
      commit('setSearchInProgress', true);
      // let products = data.products;
      // let images = data.images;

      // products.map(product => {
      //   product.image_link = images[product.amazon_asin] ? images[product.amazon_asin] : '';

      //   return product;
      // });

      if (state.page > 1) {
        return;
      }

      if (state.cacheRetrieved) {
        console.log('VueX: Product Module: Cache was retrieved before local results');
        products = collect(products.concat(state.products)).unique(product => {
          return product.id + '-' + product.amazon_asin;
        }).toArray();
      }
      commit('setProducts', { products });
      commit('setPage', state.page);
    } catch (error) {
      console.log('VueX: Product Module', error, state.products, products);
      commit('setSearchError', true);
      commit('setProducts', { products: [] });
    } finally {

      commit('setSearchInProgress', false);
    }
  },
  async retrieveCachedResults({ commit, getters, dispatch }, cacheKey) {
    try {
      let cachedResults = await getCachedResults(cacheKey);
      let cachedProducts = cachedResults.products.map(product => {
        product.image_link = cachedResults.images[product.amazon_asin];

        return product;
      });
      console.debug('VueX: Product Module', cachedProducts);

      let products = state.products.concat(cachedProducts);
      console.debug('VueX: Product Module', 'All Results', products);
      console.debug('VueX: Product Module', 'Cached Results', cachedProducts);
      console.debug('VueX: Product Module', 'Existing Results', state.products);
      commit('setProducts', { products });
      commit('setCacheRetrieved', true);
      commit('setPage', state.page);
      commit('setLoadMore', false)
    } catch (error) {
      console.error('VueX: Product Module', error, state.products);
    } finally {
      commit('setProgressBarPercent', 100);

      if (getters['getProducts'].length > 0) {
        dispatch('clearTimeouts');
      }

      commit('setSearchInProgress', false);
    }
  },
  setPagination: ({ commit }, { pagination }) => {
    commit('setPagination', pagination);
  },
  setLoadMore: ({ commit }, { loadMore }) => {
    commit('setLoadMore', loadMore);
  },
  setCacheRetrieved: ({ commit }, { cacheRetrieved }) => {
    commit('setCacheRetrieved', cacheRetrieved);
  },
  incrementPage: ({ commit }) => {
    commit('setPage', state.page + 1);
  },
  firstPage: ({ commit }) => {
    commit('setPage', 1);
  },
  forceStop: ({ commit }) => {
    console.warn('Force stopped search!');
    commit('setPagination', {
      number_of_pages: 1,
      current_page: 1,
    });
    commit('setPage', state.expectedResults);
    commit('setIsForceStopped', true);
    commit('setCacheRetrieved', true)
    commit('setLoadMore', false);
    commit('setExpectedResults', state.products)
  },
  startTimeouts({ commit, dispatch }) {
    dispatch('clearTimeouts')
    commit('setLoadingText', 'Searching...');
    commit('setStillSearchingTimeout')
    commit('setAlmostDoneTimeout')
    commit('setReloadTimeout')
    commit('setProgressBarInterval');
  },
  clearTimeouts({ getters, commit }) {
    console.debug('[VueX] Cleared timeouts')
    if (getters.stillSearchingTimeout) {
      clearTimeout(getters.stillSearchingTimeout)
    }

    if (getters.almostDoneTimeout) {
      clearTimeout(getters.almostDoneTimeout)
    }

    if (getters.reloadTimeout) {
      clearTimeout(getters.reloadTimeout)
    }

    commit('setLoadingText', 'Searching...');
    commit('resetProgressBar');
  },
}

const mutations = {
  setSubscribedToPusher (state, value) {
    state.subscribedToPusher = value
  },

  setProductQuery(state, { productSearchQuery }) {
    state.productSearchQuery = productSearchQuery;
  },

  setProducts(state, { products }) {
    state.products = products;
  },

  setSearchInProgress(state, value) {
    state.searchInProgress = value;
  },

  setSearchError(state, value) {
    state.searchError = value;
  },

  setCacheRetrieved(state, value) {
    state.cacheRetrieved = value;
  },

  setProductSearchCategory(state, value) {
    state.productSearchCategory = value;
  },

  setPage(state, value) {
    state.page = value;
  },

  setPagination(state, value) {
    state.pagination = value;
  },

  setLoadMore(state, value) {
    state.loadMore = value;
  },

  setIsForceStopped(state, value) {
    state.isForceStopped = value;
  },

  setExpectedResults(state, value) {
    state.expected_results = value;
  },

  setLoadingText(state, value) {
    state.loadingText = value;
  },
  
  setStillSearchingTimeout(state, value) {
    if (getters.stillSearchingTimeout) {
      clearTimeout(getters.stillSearchingTimeout)
    }

    state.stillSearchingTimeout = setTimeout(() => {
      state.loadingText = 'Still searching...'
    }, 7000);
  },

  setAlmostDoneTimeout(state) {
    if (getters.almostDoneTimeout) {
      clearTimeout(getters.almostDoneTimeout)
    }

    state.almostDoneTimeout = setTimeout(() => {
      state.loadingText = 'Almost done searching'
    }, 12000);
  },

  setReloadTimeout(state) {
    if (getters.reloadTimeout) {
      clearTimeout(getters.reloadTimeout)
    }

    state.reloadTimeout = setTimeout(() => {
      // window.location.reload();
    }, 16000);
  },

  setProgressBarPercent(state, value) {
    state.progressBarPercent = value;
  },

  incProgressBar(state, value) {
    state.progressBarPercent += value;
  },

  resetProgressBar(state) {
    state.progressBarPercent = 0;

    try {
      clearInterval(state.getters.progressBarInterval)
    } catch {
      //
    }
  },

  setProgressBarInterval(state) {
    console.log('setProgressBarInterval', state.progressBarPercent)

    state.progressBarPercent = 0;

    try {
      clearInterval(state.getters.progressBarInterval)
    } catch {
      //
    }

    state.progressBarInterval = setInterval(() => state.progressBarPercent+=10, 1000);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
<template>
  <div id="profile-interests" class="container pr-lg-1 pl-lg-1">
    <div class="row pr-lg-5 pl-lg-5">
      <div class="col-lg-4 col-sm-12 mx-sm-auto mb-2 mb-md-0">
        <div v-if="!settingUp" class="card box-card">
          <div class="card-header">
            <span class="text-pointer">
              <i
                class="fa fa-plus-circle"
                style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
              ></i> my info & settings
            </span>
          </div>
          <div class="card-body pl-0">
            <div class="profile-info" v-if="user">
              <div class="row m-0 ml-3 flex-column flex-xl-row justify-content-center
                align-items-center align-items-xl-start justify-content-xl-start">
                  <img :alt="user.name" class="profile-picture img-reponsive" :src="getUserPhoto(user)">

                  <h6 class="thumbnail-link ml-lg-3 mt-2 mt-xl-5"
                    @click="$('#picture-modal').modal('show')">
                    Upload New Thumbnail
                  </h6>
              </div>
              <div class="row align-items-center align-items-lg-start flex-column m-0 ml-3 mt-lg-3 font-weight-bolder">                  
                <div class="field">
                  <section v-if="editing.name">
                    <div class="form-group m-0">
                      <input type="text" v-model="update.name" class="form-control" placeholder="Name"
                        v-on:keyup.enter="updateName()" :disabled="editing.name === 1">
                    </div>
                    <button type="button" class="btn btn-primary btn-sm" @click="updateName()"
                      :disabled="editing.name === 1">
                      <i class="fa fa-circle-notch spin" v-if="editing.name === 1"></i>
                      Update
                    </button>
                    <button type="button" class="btn btn-secondary btn-sm" @click="editing.name = false"
                      v-if="editing.name !== 1">
                      <i class="fa fa-times"></i>
                    </button>
                  </section>
                  <section v-else>
                    <p v-text="user.name"></p>
                    <i class="fa fa-edit" @click="editing.name = true"></i>
                  </section>
                </div>
                <div class="field mt-xl-2">
                  <section v-if="editing.email">
                    <div class="form-group m-0">
                      <input type="email" v-model="update.email" class="form-control" placeholder="Email"
                        v-on:keyup.enter="updateEmail()" :disabled="editing.email === 1">
                    </div>
                    <button type="button" class="btn btn-primary btn-sm" @click="updateEmail()"
                      :disabled="editing.email === 1" v-if="editing.email !== 1">
                      <i class="fa fa-circle-notch spin" v-if="editing.email === 1"></i>
                      Update
                    </button>
                    <button type="button" class="btn btn-secondary btn-sm" @click="editing.email = false">
                      <i class="fa fa-times"></i>
                    </button>
                  </section>
                  <section v-else>
                    <p class="mt-0" v-text="user.email"></p>
                    <i class="fa fa-edit" @click="editing.email = true"></i>
                  </section>
                </div>
              </div>
            </div>
            <div class="profile-info-loader" v-else>
              <vue-content-loading :width="300" :height="150">
                <rect x="20" y="0" rx="4" ry="4" width="100" height="100" />
                <rect x="130" y="50" rx="4" ry="4" width="150" height="14" />
                <rect x="20" y="110" rx="4" ry="4" width="300" height="14" />
                <rect x="20" y="134" rx="4" ry="4" width="300" height="14" />
              </vue-content-loading>
            </div>
            <hr class="mb-2 mt-2"/>
            <div class="referral-box text-left pl-3 pt-2">              
              <h4>
                <i class="fa fa-plus-circle" style="font-size: 20px; color: rgb(0, 174, 255); -webkit-text-stroke: 0.5px black;"></i>
                money made by inviting others
              </h4>
              <p>
                - Users invited that made their first purchase:
                <span id="referral-users">0</span>
              </p>
              <p>
                - Dollars of sales from externally placed partner links:
                <span id="referral-external-sales">0</span>
              </p>
              <p>
                - Dollars of sales from influencer reviews and posts:
                <span id="referral-influencer">0</span>
              </p>
              <p>
                *Becoming an influencer requires inviting at least 15 users that make a purchase and $2000 sold via external links.
              </p>
              <button type="button" @click="getReferralLink($event)" id="registration-referral-link" class="btn btn-secondary btn-lg btn-block">
                <i class="fa fa-link"></i>
                click to get partner link
              </button>

            </div>

          </div>
          
        </div>
        <div v-else>
          <h3 class="bg-white p-3 mb-3 sidebox font-weight-bold" >
            add at least 3 interests
          </h3>
          <div class="bg-white p-3 mb-3 sidebox sidebox-sm font-weight-bold" >
            * rRipple uses interests so we never show you any products or
            services that don’t match your interests.<br>
            You also never see anything that isn’t from friends, your groups, or those you follow.<br>
            In this way, rRipple can also have no advertisements - ever
          </div>
        </div>

        <div class="p-3" v-if="user">
          <button class="btn btn-primary btn-block btn-lg"
            style="background-color: rgb(0, 174, 255);border: 4px solid white;font-size: 16px;font-weight:600;"
            @click="makeSeller"
            :disabled="user.is_seller || seller_request_loading">
            <i class="fa fa-circle-notch spin" v-show="seller_request_loading"></i>
            <p>Become A Seller -</p>
            <p>Click to Convert to a Seller</p>
            <p>Account & Sell Products</p> 
            <span>on rRipple</span></span>
            <small class="text-light">(Seller Acct's are Free)</small>
          </button>
        </div>
      </div>
      <div :class="[tabview == 'addinterest' ? 'col-8 col-lg-6 mx-sm-auto mb-2 mb-md-0 p-0' : 'col-12 col-lg-8 mx-sm-auto mb-2 mb-md-0 p-0']">
        <div class="card box-card interests-container">
          <div class="card-header">
            <span class="text-pointer" v-if="tabview=='myinterest'" @click="tabview='addinterest'">
              <i
                class="fa fa-plus-circle"
                style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
              ></i> add interests
            </span>
            <span class="text-pointer" v-else-if="tabview==='addinterest' && settingUp" @click="tabview='myinterest'">
              <i
                      class="fa fa-plus-circle"
                      style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
              ></i> add interests
            </span>
            <span class="text-pointer" v-else @click="tabview='myinterest'">
              <i
                class="fa fa-arrow-left"
                style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
              ></i> my interests
            </span>
            <span
              v-if="tabview=='addinterest' && current_parent"
              @click="upLevel"
              class="text-pointer float-right"
            >
              <i
                class="fa fa-arrow-up"
                style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
              ></i> Up a level
            </span>
          </div>

          <div class="card-body position-relative" id="interests-container">
            <template v-if="tabview=='myinterest'">
              <div class="row">
                <div
                  class="interest-item col-lg-4 col-md-6 col-sm-12 p-0"
                  v-for="(interest,index) in selected_interests"
                  :key="index"
                >
                  <div class="card">
                    <div class="card-body p-0 i-item">
                      <span @click="removeUserInterest(index)" class="text-pointer float-right position-absolute">
                        <i class="fa fa-times"></i>
                      </span>
                      <img class="img-responsive" :src="'/icons/' + interest.icon" style="height: 70px;margin-bottom: 5px;">
                      <h4 v-text="interest.name"></h4>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="interest-lists row" v-else-if="tabview=='addinterest'">
              <div class="interest-item col-lg-4 col-md-6 col-sm-12 p-0" v-for="interest in list" :key="interest.id">
                <div
                  class="card"
                  @click="checkInterest(interest)"
                >
                  <div class="card-body p-0 i-item">
                    <img class="img-responsive" :src="'/icons/' + interest.icon" style="height: 70px;margin-bottom: 5px;">
                    <h4 v-text="interest.name"></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 col-lg-2 mx-sm-auto p-0 pl-md -2" v-show="tabview == 'addinterest'">
        <div class="card box-card interests-container">
          <div class="card-header selected-interests-header">
            <span class="text-pointer">
              <i
                class="fa fa-plus-circle"
                style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
              ></i> selected interests
            </span>
          </div>
          <div class="card-body">
            <div class="selected-interest-lists row p-0">
              <div class="interest-item p-0" v-for="interest in tobe_saved" :key="interest.id">
                <div
                  class="card p-0"
                >

                  <div class="card">
                    <div class="card-body p-0">
                      <span @click="removeSelected(index)" class="text-pointer float-right position-absolute">
                        <i class="fa fa-times"></i>
                      </span>
                      <img class="img-responsive" :src="'/icons/' + interest.icon" style="height: 70px;margin-bottom: 5px;">
                      <h4 v-text="interest.name"></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class>
          <button
            @click="submitInterest"
            :disabled="!this.tobe_saved.length"
            class="button button-p7 float-right btn-lg submit-new-interests w-100"
          >submit new interests</button>
        </div>
      </div>
    </div>
    <referral-modal :link="referralLink"></referral-modal>

    <div class="modal" tabindex="-1" role="dialog" id="seller-modal">
      <div class="modal-dialog" role="document" style="max-width: 50%;">
        <div class="modal-content">
          <div class="modal-header d-block border-0 pb-2">
            <h2 class="modal-title text-center">Congratulations!</h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute;top: 1rem;right: 1.5rem;font-size: 2rem;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <p>
              <h2>You are now a rRipple Seller</h2>
              <h2 class="mb-3">To List a Product, click on this icon in the upper right:</h2>
              <h2 class="mb-3">Then the arrow next to your name, then “Create Product”</h2>
              <p>At this point shipping is charged by weight, $4 minimum and $4 for every 3 pounds after. International shipping is 1.5 times higher for Canada and 3 times higher for all other countries</p>
              <p class="text-underline">You wil be given order details via e-mail for fulfillment</p>
              <p>
                <span class="text-underline">*Please ship all orders within 3 days</span>, late shipment may result in cancelation. It is preferable to send the buyer a shipment notice and tracking code by e-mail, we will soon update the platform to allow you to provide tracking codes and we will do this automatically
                <span class="text-underline">rRipple charges 20% platform fees</span> as we do significant marketing for you (including influencer marketing, and
                <span class="text-underline">your products can go viral for free on rRipple</span>
              </p>
              <p>
                You are charged nothing for listing products or having a seller account - the 20% is deducted from your sales before payouts are provided. This amount also includes credit card charges)
              </p>
              <p>
                <span class="text-underline">At this time we payout biweekly by check</span>, please ensure your acount e-mail is
                correct so we can arrange payment
              </p>
              <p>
                rRipple reserves the right to ban or limit any sellers, or remove products for any
                reasons including late shipments, abuse, poor quality or customer service, or legality
              </p>
              <p></p>
              You are responsible for ensuring the legality of your products and that you are not shipping products illegal in the area you are shipping to</p>
              <p>rRipple reserves the right to ban product categories</p>
              <p>rRipple reserves the right to issue customer refunds for damage, late shipping,</p>
              <p>incorrect items, low quality, or other reasons</p>
              <p>Returns and replacements wil be sent to you</p>
              If you’d like to fulfill an item we already have for sale, e-mail <a href="mailto:sellers@rRippled.com">sellers@rRippled.com</a></a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="picture-modal" tabindex="-1" role="dialog" aria-hidden="true" v-if="user">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body row m-0 ml-3 flex-column flex-xl-row justify-content-center
                align-items-center align-items-xl-start justify-content-xl-start">
            <img id="picture-preview" :alt="user.name" class="profile-picture img-reponsive" :src="getUserPhoto(user)">

            <h5 class="thumbnail-link ml-lg-3 mt-2 mt-xl-5"
              @click="$('#picture-input').trigger('click')">
              Browse for New Pic
            </h5>
            <input name="picture-input" class="d-none" id="picture-input" type="file"
              @change="previewPicture($event.target)">
          </div>
          <div class="modal-footer">
            <button type="button" name="" id="" class="btn btn-primary btn-block" @click="updatePicture()"
              :disabled="editing.picture === 1">
              <i class="fa fa-circle-notch spin" v-if="editing.picture === 1"></i>
              Submit New Profile Pic
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueContentLoading from 'vue-content-loading';
import collect from 'collect.js';

export default {
  name: "profile",
  components: {
    VueContentLoading,
  },
  data() {
    return {
      tabview: this.settingUp ? 'addinterest' : "myinterest",
      current_parent: null,
      parents: [],
      list: [],
      selected_interests: [],
      tobe_saved: [],
      referralLink: null,
      user: null,
      seller_request_loading: false,
      editing: {
        picture: false,
        name: false,
        email: false,
      },
      update: {
        name: null,
        email: null,
        picture: null,
      },
    };
  },
  computed: {
    referredSpentAmount() {
      if (!this.user) {
        return 0;
      }

      return collect(this.user.referred_with_orders).sum('amount');
    },
    $() {
      return window.jQuery;
    },
  },
  props: {
    settingUp: Boolean,
  },
  created: function() {
    this.createProfile();
    this.getUserInfo();

    $(() => {
      $('#picture-modal').on('hide.bs.modal', e => {
        $('#picture-input').val('');
        $('#picture-preview').attr('src', this.getUserPhoto(this.user))
      })
    })
  },
  mounted() {
    $(() => {
      this.adjustSelectedInterestsCols();

      $(window).on('resize', e => {
        
        this.adjustSelectedInterestsCols();
      });

    })
  },
  methods: {
    jumpInterestsUp() {

      const element = $('#interests-container');

      this.$nextTick(() =>  {
        element[0].style.setProperty('overflow', 'hidden' ,'important');
        $('html, body').scrollTop(element.closest('.card').offset().top)
        element.scrollTop(0)
        element[0].style.setProperty('overflow', 'auto' ,'important');
      })
    },
    jumpInterestsDown() {
      const element = $('#interests-container');

      
      this.$nextTick(() =>  {
        $('html, body').scrollTop(element.closest('.card').offset().top + element.closest('.card').height())
        element[0].style.setProperty('overflow', 'hidden' ,'important');
        element.scrollTop(element[0].scrollHeight)
        element[0].style.setProperty('overflow', 'auto' ,'important');
      })

    },
    adjustSelectedInterestsCols() {
        if ($('.selected-interest-lists').length > 0) {
          const windowWidth = $(window).width();

          if (windowWidth <= 991) {
            $('.selected-interest-lists').addClass('align-content-start')
          }

          if (windowWidth > 991) {
            $('.selected-interest-lists .interest-item').removeClass(this.removeColClass).addClass('col-12');

            $('.selected-interest-lists').removeClass('align-content-start')
          } else if (windowWidth < 600) {
            $('.selected-interest-lists .interest-item').removeClass(this.removeColClass).addClass('col-12');
          } else {
            $('.selected-interest-lists .interest-item').removeClass(this.removeColClass).addClass('col-6');
          }
        }
    },
    removeColClass(index, className) {
        return (className.match (/(^|\s)col-\S+/g) || []).join(' ');
    },
    createProfile: function() {
      var self = this;
      axios
        .get("/profile/fetchinterest")
        .then(response => {
          self.parents = response.data.interests;
          self.list = self.parents;
          self.selected_interests = response.data.user_interests;
        })
        .catch(() => {})
        .then(() => {
          self.toggling = false;
        });
    },
    removeUserInterest(index) {
      axios
        .delete("/profile/interests/" + this.selected_interests[index].id)
        .then(response => {
          this.selected_interests = response.data;
        })
        .catch(() => {});
    },
    removeSelected(index) {
      this.tobe_saved.splice(index, 1);
    },
    upLevel() {
      if (!this.current_parent.parent) {
        this.createProfile();
        this.current_parent = null;
        return;
      }

      this.checkInterest(this.current_parent.parent);
    },
    checkInterest(interest) {
      let interest_id = typeof interest === "object" ? interest.id : interest;
      this.toggling = true;
      axios
        .get("/profile/interests/" + interest_id)
        .then(response => {
          let found_interest = response.data;
          if (found_interest.children.length) {
            this.current_parent = found_interest;
            this.list = found_interest.children;
            return;
          }

          if (
            !this.tobe_saved.find(element => {
              return element.id == found_interest.id;
            })
          ) {
            this.tobe_saved.push(found_interest);
          }

          this.$nextTick(() => {
            this.adjustSelectedInterestsCols();
          })
        })
        .catch(() => {})
        .then(() => {
          this.toggling = false;
        });
    },
    submitInterest() {
      var self = this;
      axios
        .post("/profile/interests", {
          interests: this.tobe_saved.map(interest => {
            return interest.id;
          })
        })
        .then(response => {
          this.current_parent = null;
          this.list = this.parents;
          this.tobe_saved = [];
          this.selected_interests = response.data;
          this.tabview = "myinterest";
        })
        .catch(() => {})
        .then(() => {})
        .finally(() => {
          if (self.settingUp) {
            window.location.href = '/home'
          }
        });
    },
    getReferralLink(event) {      
      const button = $(event.target);
      let self = this;

      if (this.referralLink !== null) {
        return $('#referral-modal').modal('show');
      }

      button.attr('disabled', true);
      button.find('i.fa').removeClass('fa-link').addClass('fa-circle-notch spin');

      axios
        .get("/referrals/link")
        .then(response => {
          self.referralLink = response.data.link;
          $('#referral-modal').modal('show');
        })
        .finally(() => {
          button.attr('disabled', false);
          button.find('i.fa').removeClass('fa-circle-notch spin').addClass('fa-link');
        });

    },
    getUserInfo() {
      axios.get('/whoami').then(({ data }) => {
        this.user = data.user;
        this.update.name = this.user.name;
        this.update.email = this.user.email;
      });
    },
    makeSeller() {
      this.seller_request_loading = true;
      axios.patch(`/users/${this.user.id}/make-seller`).then(({ data }) => {
        if (data.success) {
          this.user.is_seller = true;
          $('.seller-link').removeClass('d-none');
          return $('#seller-modal').modal('show');
        }

        $.notify('An unexpected error has occured. Please refresh the page and try again.', 'error');
      }).finally(() => {
        this.seller_request_loading = false;
      });
    },
    getUserPhoto(user) {
      if (!user) {
        return '/images/avatar.png';
      }

      if (user.new_avatar) {
        return user.new_avatar;
      }

      if (user.avatar) {
        return `/storage/images/${user.avatar}`;
      }

      if (user.facebook_profile_pic_url) {
        return user.facebook_profile_pic_url;
      }

      return '/images/avatar.png';
    },
    updateName() {
      if (this.editing.name === 1) {
        return;
      }

      this.editing.name = 1;

      axios.put('/profile/name', {name: this.update.name}).then(({ data }) => {
        if (data.updated) {
          this.user.name = this.update.name;

          return $.notify('Name updated successfully!', 'success');
        }
        
        $.notify('An unexpected error has occured. Please refresh the page and try again.', 'error');
      }).catch(err => {
        this.handleError(err);
      }).finally(() => {
          this.editing.name = false;
      });
    },
    updateEmail() {
      if (this.editing.email === 1) {
        return;
      }

      this.editing.email = 1;

      axios.put('/profile/email', {email: this.update.email}).then(({ data }) => {
        if (data.updated) {
          this.user.email = this.update.email;

          return $.notify('Email updated successfully!', 'success');
        }

        $.notify('An unexpected error has occured. Please refresh the page and try again.', 'error');
      }).catch(err => {
        this.handleError(err);
      }).finally(() => {
        this.editing.email = false;
      });
    },
    updatePicture() {
      if (this.editing.picture === 1) {
        return;
      }

      this.editing.picture = 1;

      const formData = new FormData();
      const picture = $('#picture-input')[0].files[0];
      formData.append('picture', picture)
      formData.append('_method', 'put')
      axios.post('/profile/picture', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      }).then(({ data }) => {
        if (data.updated) {
          $('#picture-modal').modal('hide');
          this.user.new_avatar = this.update.picture;

          return $.notify('Picture updated successfully!', 'success');
        }

        $.notify('An unexpected error has occured. Please refresh the page and try again.', 'error');
      }).catch(err => {
        this.handleError(err);
      }).finally(() => {
          this.editing.picture = false;
          $('#picture-modal').modal('hide');
      });
    },
    previewPicture(input) {
        if (input.files && input.files[0]) {
          const reader = new FileReader();
          
          reader.onload = e => {
            this.update.picture = e.target.result;
            $('#picture-preview').attr('src', this.update.picture);
          }
          
          reader.readAsDataURL(input.files[0]); // convert to base64 string
        }
    },
    handleError(err) {
        const response = err.response;
        let message = `Error: ${err}`;
        
        if (response.data && response.data.errors) {
          message = '';

          Object.keys(response.data.errors).forEach(field => {
            message += response.data.errors[field].join("<br/>");
          });
        }

        $.notify(message, 'error');
    },
  }
};
</script>
<style lang="scss" scoped>
  .sidebox {
    border-radius: 10px;
    border: 2px gray solid;
  }
  .sidebox-sm {
    font-size: 16px;
  }

  p {
    margin: 0;
  }
  .text-underline {
    text-decoration: underline;
  }

  .profile-info, #picture-modal {
    .profile-picture {
      height: 100px;
      width: 100px;
      padding: .2rem;
      border: 1px inset #5d5d5d;
    }

    .thumbnail-link {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: #5d5d5d;
      }
    }

    .field {
      width: 100%;

      section {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .fa:not(.spin):not(.fa-times) {
          cursor: pointer;
          margin-top: 2px;
          color: #00aeff;

          &:hover {
            color: #545455;
          }
        }
      }
    }
  }

  #picture-modal {
    .profile-picture {
      height: 120px;
      width: 150px;
    }
  }
</style>

<style>


  .ps__rail-y, .ps__rail-x {
    opacity: 1 !important;
    display: block !important;
    background-color: rgba(0, 0, 0, .1) !important;
  }

  .ps__thumb-y {
    width: 10px !important;
    min-height: 30px;
  }
</style>

<style lang="scss" scoped>
  .selected-interest-lists {
    .interest-item {
      .card {
        @media screen and (max-width: 992px) {
          height: auto !important;
        }
      }
    }
  }

  .selected-interests-header {
    @media screen and (max-width: 440px) {
      font-size: calc((35vw - 4.5rem) / 6);
    }
  }
</style>
<template>
    <div class="msg-container">
        <div class="row">
            <div v-if="!thread" class="col-md-6 order-last">
                <div class="card box-card">
                    <div class="card-header">
                        <span class="text-pointer"><i class="fa fa-plus-circle mr-1"
                                                      style="font-size: 20px; color: rgb(0, 174, 255); -webkit-text-stroke: 0.5px black;"></i>my convos</span>
                    </div>
                    <div class="card-body p-0">
                        <div @click="showThread(msg)" class="d-flex msg position-relative" v-for="msg in messages">
                            <div class="text-center avatar-col mr-3">
                                <img :src="getUserPhoto(msg.from.id == user.id ? msg.to : msg.from)" class="mx-auto avatar">
                                <div class="from-name">{{ msg.from.id == user.id ? msg.to.name : msg.from.name }}</div>
                                <div class="text-muted d-block d-lg-none" style="word-break: keep-all;">
                                  <span>{{ msg.date }}</span>

                                  <span>{{ msg.time }}</span>
                                </div>
                            </div>
                            <div class="flex-grow-1 text-left msg-txt">
                              <span class="text-muted font-weight-bolder"
                                v-text="msg.from.id == user.id ? 'You: ' : msg.from.name.split(' ')[0] + ': '">
                              </span>
                              {{ msg.content }}
                            </div>
                            
                              <button class="click-to-reply btn btn-primary position-relative position-md-absolute float-right mr-0 mr-md-5 rounded"
                                @click="showThread(msg)">
                                <strong v-text="msg.from.id == user.id ? 'message' : 'click to reply'" style="word-break: keep-all;"></strong>
                              </button>
                            <div class="text-muted d-none d-lg-block" style="word-break: keep-all;">
                                <span>{{ msg.date }}</span>
                                <br>
                                <span>{{ msg.time }}</span>
                            </div>
                        </div>
                        
                        <div class="d-flex justify-content-center py-5" v-if="!loaded">
                            <h3>Loading...</h3>
                        </div>
                        <div class="d-flex justify-content-center py-5" v-else>
                            <h3 v-if="friends.length == 0">Add some friends to start chatting</h3>
                            <h3 v-if="Object.keys(messages).length == 0">Select a friend from search box to start chatting</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="col-md-6 order-last">
                <div class="card box-card">
                    <div class="card-header">
                        <span class="text-pointer">
                          <i class="fa fa-plus-circle mr-1"
                              style="font-size: 20px; color: rgb(0, 174, 255); -webkit-text-stroke: 0.5px black;"></i>
                          {{ thread.user.name }}
                        </span>
                        <button type="button" class="btn btn-primary float-right rounded" @click="returnToAllConvos()">
                          <i class="fa fa-arrow-left"></i>
                          <strong>Return to all Convos</strong>
                        </button>
                    </div>
                    <div class="card-body p-0 ">
                        <div class="text-center py-5" v-if="!thread.messages.length">No messages yet</div>
                        <div ref="thread" class="thread" v-else>
                            <div v-for="msg in thread.messages" class="d-flex mx-2 my-3"
                                 :class="msg.is_mine ? 'justify-content-end' : 'justify-content-start'">
                                <div class="thread-msg" :class="msg.is_mine ? 'msg-me' : 'msg-other'">{{ msg.content
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-1 border-top">
                            <input @keyup.enter="send" class="flex-grow-1 no-border" placeholder="type message..." type="text" v-model="reply">
                            <div>
                                <button @click="send" class="button">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div v-if="thread" class="my-2 d-flex justify-content-start">
                    <button @click="returnToAllConvos()" class="button">Back to Inbox</button>
                </div>
                <div>
                    <input v-model="search" @input="searchUsers(search)" class="form-control friend-search my-2 my-md-2"
                           placeholder="Search friends to message">
                </div>
                <div v-if="loading || results !== null" class="results">
                    <div v-if="loading">
                        <p>Loading...</p>
                    </div>
                    <div v-else-if="results && results.length === 0">
                        <p>No Results</p>
                    </div>
                    <div v-else-if>
                        <div @click="showThread(user);results=null" v-for="user in results" class="d-flex mb-2 results-row">
                            <img :src="getUserPhoto(user)" class="mr-3 avatar">
                            <p>{{ user.name }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import collect from 'collect.js';

  export default {
    name: 'Messages',
    props: ['user_interests', 'interests'],
    data () {
      return {
        friends: [],
        recipientFriend: {},
        messages: [],
        user: {},
        newMessage: '',
        activeId: null,
        loading: false,
        results: null,
        search: '',
        hasFriends: true,
        thread: null,
        reply: null,
        loaded: false,
      }
    },
    created () {
      this.user = window.User;
      this.loadConversations();
    },
    methods: {
      loadConversations() {
        axios.get('/api/messages').then(res => {
          this.loaded = true;
          this.friends = res.data.friends
          let messages = collect(res.data.inbox.concat(res.data.outbox))
          let filteredMessages = {};

          messages.sortByDesc('created_at').each(message => {
            if (message.from.id in filteredMessages || message.to.id in filteredMessages) {
              return;
            }

            filteredMessages[message.from.id] = message;
          });

          this.messages = filteredMessages
        })
      },
      searchUsers (q) {
        if (!q) {
          this.results = null
          return
        }

        axios.get(`/api/users?q=${q}&ignore-self=1&friends=1`).then(res => {
          this.results = res.data
        })
      },
      showThread (message) {
        let user = message;
        
        if (message.from && message.to) {
          user = message.from.id == this.user.id
            ? message.to
            : message.from;
        }

        axios.get(`/api/messages/${user.id}`).then(res => {
          this.thread = res.data
          this.scrollThread()
        })
      },
      send() {
        if(!this.reply) return;
        const message = this.reply
        this.reply = null

        axios.post(`/api/messages/${this.thread.user.id}`, {message}).then(res => {
          this.thread = res.data
          this.scrollThread()
        })
      },

      scrollThread() {
        this.$nextTick(() => {
          const thread = this.$refs.thread;

          if (!thread) {
            return;
          }

          thread.scrollTop = thread.scrollHeight;
        })
      },

      fetchMessagDetails: function (friend_id) {
        var self = this
        axios.get('/messagedetails/' + friend_id).then(({ data }) => {
          console.log('Start detailes')
          console.info(data)
          self.messages = data.messages
          self.recipientFriend = data.recipientFriend
        })
      },
      submitMessageEnter: function (event) {
        if (event.keyCode === 13) {
          this.submitMessage()
        }
      },
      submitMessage: function () {
        var content = document.getElementsByName('csrf-token')[0].getAttribute('content')

        var self = this
        console.info('start')
        axios.post('/send_message_user', {
          content: this.newMessage,
          authorId: this.user.id,
          recipientId: this.recipientFriend.id,
          _token: content,
        }).then(({ data }) => {
          console.log('Then')
          console.info(data)
          console.info(self.recipientFriend.id)
          this.newMessage = ''
          if (data == self.recipientFriend.id) {
            this.fetchMessagDetails(data)
          }
        })
      },
      returnToAllConvos() {
        this.thread = null;
        this.loadConversations();
      },
      getUserPhoto(user) {
        if (!user) {
          return '/images/avatar.png';
        }

        if (user.avatar) {
          return `/storage/images/${user.avatar}`;
        }

        if (user.facebook_profile_pic_url) {
          return user.facebook_profile_pic_url;
        }

        return '/images/avatar.png';
      },

      // fetchFeeds: function() {
      //   alert("Fetch Feeds");
      // }
    },
  }
</script>
<style scoped>
    .msg-container {
        width: 100%;
        margin: auto;
    }
    
    @media screen and (min-width: 576px) {
      .msg-container {
        width: 85%;
      }
    }

    .avatar {
        width: 40px;
        border-radius: 50%;
        height: 40px;
    }

    .from-name {
        font-size: 0.8em;
        margin-top: 5px;
    }

    .box-card .card-header {
        text-align: left !important;
        font-weight: bold;
        font-size: 1.7em;
        background-color: white;
        border-bottom: 2px solid lightgray;
    }

    .results {
        background: white;
        border: black 2px solid;
        padding: 10px;
    }

    .results-row {
        cursor: pointer;
    }

    .results-row:hover {
        background: #e3e3e3;
    }

    .avatar-col {
        min-width: 100px;
    }

    .msg {
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 5px;
        border-bottom: 2px solid lightgray;
    }

    .msg-txt {
        color: gray;
    }

    .thread-msg {
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 1.2em;
    }

    .msg-other {
        background: #309fd8;
        color: white;
    }

    .msg-me {
        background: #e6e6e6;
        color: black;
    }

    .no-border {
      border: none;
    }

  .thread {
    max-height: 500px;
    overflow-y: scroll;
  }

  
  .click-to-reply {
    margin-top: .5rem;
    border-radius: 10px !important;
    flex-wrap: content;
    height: 100%;
  }

  @media (min-width:766px) {
    .click-to-reply {
      margin-right: 5% !important;
    }
  }
</style>

<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="d-flex mb-1 justify-content-between">
                        <p>Invite to {{ group.topic }}</p>
                        <a class="modal-default-button text-muted" @click="$emit('close')">
                            Close
                        </a>
                    </div>
                    <div v-if="!loading" class="flex">
                        <input v-model="userSearch" @input="searchUsers($event.target.value)" placeholder="Search" class="form-control w-100">
                        <div class="mt-3" v-if="userSearch.length === 0">
                            Type to Search
                        </div>
                        <div class="py-2">
                            <div class="" v-for="u in users.slice(0,10)">
                                <div class="d-flex mb-1">
                                    <div>
                                        <button @click="invite(u)" class="invite-button">+ invite</button>
                                    </div>
                                    <div class="ml-2 text-left flex-grow-1">{{ u.name }}</div>
                                    <div>
                                        <img style="height: 20px;" :src="u.avatar_url">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        Loading...
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
  export default {
    name: 'InviteModal',
    props: {
      group: Object
    },
    data() {
      return {
        userSearch: '',
        users: [],
        loading: false,
      }
    },
    methods: {
      searchUsers(q) {
        axios.get(`/api/users?q=${q}&ignore-self=1`).then(res => {
          this.users = res.data
        })
      },
      invite(user) {
        this.loading = true
        axios.post(`/groups/${this.group.id}/invite/${user.id}`).then(res => {
          this.$emit('invited', user)
          this.loading = true;
        })
      }
    }
  }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        max-height: 600px;
        margin: 0 20px;
        padding: 20px 30px;
        background: white;
        border: black 2px solid;
        border-radius: 5px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    @media screen and (min-width: 576px) {
        .modal-container {
            width: 400px;
            margin: 0 auto;
        }
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .invite-button {
        background-color: white;
        border-radius: 30px;
        padding: 0px 10px 0px 10px;
        font-size: 1em;
    }
</style>

export async function searchProductsSimple(keyword, category = null, page = 1, clientId = null) {
  let params = {
    q: keyword,
    category: category,
    page: page,
    'download-only': page > 1,
    products_search_id: clientId,
  }

  if (Env != 'production') {
    const query = new URLSearchParams(window.location.search);
    
    params['no-checks'] = query.get('no-checks');
    params['no-nodes'] = query.get('no-nodes');
    params['no-images'] = query.get('no-images');
  }

  let searchResultsResponse = await axios.get('/api/products/search', {
    params
  });

  return searchResultsResponse.data;
}

export async function getCachedResults(cacheKey) {
  let cachedResults = await axios.get(`/api/products/search?cache=${cacheKey}`);
  let { data } = cachedResults;

  return data;
}
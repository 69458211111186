<template>
    <!-- Referral Link Modal -->
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="referral-modal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Referral Link</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
            <input class="form-control" type="text" id="referral-link" :value="link" readonly>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="copyReferralLink($event)"
              id="copy-referral">

              <i class="far fa-copy"></i>
              Copy to clipboard
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'referral-modal',
    props: {
        link: {
            type: String,
        },
        id: {
            type: String,
            default: 'referral-modal'
        }
    },
    methods: {
      copyReferralLink(event) {
        console.log('copyReferralLink');
        const linkInput = $('#referral-link');
      
        linkInput.focus();
        linkInput[0].setSelectionRange(0, linkInput.val().length);
        
        document.execCommand("copy");
        
        $(event.target).html('<i class="fa fa-clipboard"></i> Copied!');
      },
    },
    mounted () {
      $('#' + this.id).on('hidden.bs.modal', function () {
        $('#copy-referral').html('<i class="far fa-copy"></i> Copy to clipboard');
      })
    }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.searchError
      ? _c("div", [
          _vm._v("There was an error with your search. Please try again.")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.searchInProgress ? _c("div", [_vm._v("Loading...")]) : _vm._e(),
    _vm._v(" "),
    _vm.isEmptyQuery
      ? _c("div", [_vm._v("Search for movies/tv shows...")])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasNoResults ? _c("div", [_vm._v("No results")]) : _vm._e(),
    _vm._v(" "),
    _vm.hasResults
      ? _c(
          "div",
          { staticClass: "media-search-results" },
          _vm._l(_vm.mediaItems, function(item) {
            return _c("div", { key: item.imdb_id, staticClass: "box" }, [
              _c("div", { staticClass: "row" }, [
                !item.review
                  ? _c("div", { staticClass: "col-sm-3" }, [
                      _c("div", { staticClass: "no-reviews-text" }, [
                        _vm._v(
                          "\n            Your Friends Haven't Rated This Yet -\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("div", { staticClass: "no-reviews-text" }, [
                        _vm._v(
                          "\n            Click on title and scroll down to be the first\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.review
                  ? _c("div", { staticClass: "col-sm-3" }, [
                      _c("div", { staticClass: "rr-badge-container" }, [
                        _c("div", { staticClass: "review" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.review.rating) +
                              "\n                "
                          ),
                          _c("img", {
                            attrs: { src: "/images/star1.png", width: "40px" }
                          })
                        ]),
                        _vm._v(" "),
                        _vm.isInfluencer(item.user)
                          ? _c("div", { staticClass: "rr-badge" }, [
                              _vm._v("rR")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "userimg",
                          attrs: {
                            src:
                              item.user && item.user.avatar_url
                                ? "" + item.user.avatar_url
                                : "/images/avatar.png"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      item.review
                        ? _c("div", { staticClass: "tagname" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  item.user
                                    ? item.user.name
                                    : "A friend reviewed"
                                ) +
                                "\n            \n            "
                            ),
                            _vm.isInfluencer(item.user)
                              ? _c(
                                  "div",
                                  { staticClass: "influencer-badge-container" },
                                  [_c("InfluencerBadge")],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !item.review
                  ? _c("div", { staticClass: "line col-sm-4" }, [
                      _c("div", { staticClass: "mt-4 imdb-rating" }, [
                        _vm._v(
                          "\n            IMDB Rating: " +
                            _vm._s(item.rating) +
                            "/10 - " +
                            _vm._s(_vm._f("localNumber")(item.votes)) +
                            " IMDB reviews\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(0, true)
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.review
                  ? _c("div", { staticClass: "line col-sm-4" }, [
                      item.review
                        ? _c("div", [
                            item.user
                              ? _c("div", { staticClass: "tagname" }, [
                                  _vm._v(_vm._s(item.user.name) + " says:")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", { staticClass: "contentDesc" }, [
                              _vm._v(_vm._s(item.review.content))
                            ])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-5" }, [
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-sm-8 col-md-10" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "tagname prodlink",
                              attrs: {
                                to: {
                                  name: "media-details",
                                  params: { id: item.imdb_id }
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(item.title) +
                                  "\n                  "
                              ),
                              _c("div", { staticClass: "tagname" }, [
                                _vm._v(
                                  _vm._s(
                                    item.type === "movie"
                                      ? "Movie"
                                      : "TV Series"
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "prothumb",
                                attrs: { src: item.poster }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2 col-md-1 rate" }, [
                        _c("b", [_vm._v(_vm._s(item.average_rating))]),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "irate",
                          attrs: { src: "/images/moon.png" }
                        }),
                        _vm._v(" "),
                        _c(
                          "section",
                          { staticClass: "d-flex flex-column-reverse" },
                          _vm._l(5, function(star, index) {
                            return _c("span", { key: star }, [
                              star > item.average_rating
                                ? _c("img", {
                                    staticClass: "irate",
                                    attrs: { src: "/images/star2.png" }
                                  })
                                : _c("img", {
                                    staticClass: "icons irate",
                                    attrs: { src: "/images/star1.png" }
                                  })
                            ])
                          }),
                          0
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  item.reviewingFriends
                    ? _c(
                        "div",
                        { staticClass: "frd_viewer" },
                        [
                          _vm._l(item.reviewingFriends[0], function(
                            item3,
                            index2
                          ) {
                            return index2 <= 6
                              ? _c("img", {
                                  key: index2,
                                  staticClass: "icons",
                                  attrs: { src: item3.user.avatar_url }
                                })
                              : _vm._e()
                          }),
                          _vm._v(" "),
                          item.reviewingFriends > 6
                            ? _c("a", { attrs: { href: "" } }, [
                                _c("b", { staticClass: "counterFriends" }, [
                                  _vm._v("+12")
                                ])
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ])
              ])
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "review" }, [
      _c("img", { attrs: { src: "/images/star1.png", width: "40px" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-search-results" }, [
    _vm.searchError
      ? _c("div", { staticClass: "text-white p-5" }, [
          _vm._v("There was an error with your search. Please try again.")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isEmptyQuery && _vm.isEmptyCategory
      ? _c("div", { staticClass: "text-white p-5" }, [
          _vm._v("The search query and category are empty.")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasNoResults
      ? _c("div", { staticClass: "text-white p-5" }, [_vm._v("No results")])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasResults
      ? _c(
          "div",
          { attrs: { id: "product-results-container" } },
          [
            _vm.page == 1 && _vm.showLoader && _vm.getProducts.length < 1
              ? _c(
                  "div",
                  {
                    staticClass:
                      "loadingBar d-flex justify-content-between align-items-center mb-2",
                    staticStyle: {
                      "margin-bottom": "10px",
                      width: "70%",
                      margin: "0 auto"
                    }
                  },
                  [
                    _c("div", { attrs: { id: "loading" } }, [
                      _c("h1", {
                        staticClass: "text-white",
                        domProps: { textContent: _vm._s(_vm.loadingText) }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "progress",
                        staticStyle: {
                          width: "300px",
                          "margin-left": "10px",
                          height: "30px",
                          "border-radius": "50px"
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "progress-bar bg-primary",
                          style: "width: " + _vm.progressBarPercent + "%;",
                          attrs: {
                            role: "progressbar",
                            "aria-valuenow": _vm.progressBarPercent,
                            "aria-valuemin": "0",
                            "aria-valuemax": "100"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "transition-group",
              { attrs: { transition: "", name: "slide-fade" } },
              _vm._l(_vm.getProducts, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "box product-box" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "section",
                        {
                          staticClass: "d-mobile-block d-min-tablet-none",
                          staticStyle: { margin: "0 auto" }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "tagname prodlink",
                              attrs: { to: _vm.getProductLink(item.id) }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  item.name.length > 70
                                    ? item.name.substr(0, 70) + "..."
                                    : item.name
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "tagname" }, [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm
                                    .numeral(item.product_price)
                                    .format("0,0.00")
                                )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      (_vm.review = _vm.getBestFriendReview(item))
                        ? _c("div", { staticClass: "col-sm-3" }, [
                            _c("div", { staticClass: "rr-badge-container" }, [
                              _c("div", { staticClass: "review" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.review.rating) +
                                    "\n                  "
                                ),
                                _c("img", {
                                  attrs: {
                                    src: "/images/star1.png",
                                    width: "40px"
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _vm.isInfluencer(item)
                                ? _c("div", { staticClass: "rr-badge" }, [
                                    _vm._v("rR")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "userimg",
                                attrs: {
                                  src: _vm.getUserPhoto(_vm.review.user)
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "tagname" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.review.user
                                      ? _vm.review.user.name
                                      : "A friend reviewed"
                                  ) +
                                  "\n\n              "
                              ),
                              _vm.isInfluencer(item)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "influencer-badge-container"
                                    },
                                    [_c("InfluencerBadge")],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ])
                        : _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-3 text-left d-md-block d-tablet-block d-mobile-none"
                            },
                            [
                              _c("h3", [
                                _vm._v("No friend reviews yet - Be the First")
                              ]),
                              _vm._v(" "),
                              _c("h3", { staticClass: "mt-4" }, [
                                _vm._v(
                                  "(Click the Title then Review at the Bottom)"
                                )
                              ])
                            ]
                          ),
                      _vm._v(" "),
                      _c("div", { staticClass: "line col-sm-4" }, [
                        (_vm.review = _vm.getBestFriendReview(item))
                          ? _c("section", [
                              _vm.review.user
                                ? _c("div", { staticClass: "tagname" }, [
                                    _vm._v(
                                      _vm._s(_vm.review.user.name) + " says:"
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("p", { staticClass: "contentDesc" }, [
                                _vm._v(_vm._s(_vm.review.content))
                              ])
                            ])
                          : _c("section", [
                              _c("h3", [_vm._v("About this product")]),
                              _vm._v(" "),
                              item.description && item.description != 0
                                ? _c("p", {
                                    staticClass: "product-description",
                                    domProps: {
                                      innerHTML: _vm._s(item.description)
                                    }
                                  })
                                : _c("p", [
                                    _vm._v(
                                      "This product does not have a description yet."
                                    )
                                  ])
                            ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c("div", { staticClass: "container" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-8 col-md-8 col-lg-10" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "tagname prodlink d-none d-min-tablet-block",
                                    attrs: { to: _vm.getProductLink(item.id) }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.name.length > 70
                                          ? item.name.substr(0, 70) + "..."
                                          : item.name
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tagname d-none d-min-tablet-block"
                                  },
                                  [
                                    _vm._v(
                                      "$" +
                                        _vm._s(
                                          _vm
                                            .numeral(item.product_price)
                                            .format("0,0.00")
                                        )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "thumb-container" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: _vm.getProductLink(item.id)
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "prothumb",
                                          staticStyle: {
                                            "max-height": "400px"
                                          },
                                          attrs: {
                                            src: _vm.getFeaturedPhoto(item)
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-2 col-md-1 rate" },
                              [
                                _c("b", [_vm._v(_vm._s(item.average_rating))]),
                                _vm._v(" "),
                                _c(
                                  "section",
                                  { staticClass: "avg-review-graphic" },
                                  [
                                    _c("img", {
                                      staticClass: "irate",
                                      attrs: {
                                        src: [
                                          item.average_rating > 5
                                            ? "/images/moon-filled.png"
                                            : "/images/moon.png"
                                        ]
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "section",
                                      { staticClass: "review-stars" },
                                      _vm._l(5, function(star, index) {
                                        return _c("span", { key: star }, [
                                          star > item.average_rating
                                            ? _c("img", {
                                                staticClass: "icons irate",
                                                attrs: {
                                                  src: "/images/star2.png"
                                                }
                                              })
                                            : _c("img", {
                                                staticClass: "icons irate",
                                                attrs: {
                                                  src: "/images/star1.png"
                                                }
                                              })
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        item.id &&
                        Object.keys(item).includes("friends_reviews") &&
                        item.friends_reviews.length > 0
                          ? _c(
                              "div",
                              { staticClass: "frd_viewer" },
                              [
                                _vm._l(item.friends_reviews, function(
                                  item3,
                                  index2
                                ) {
                                  return index2 <= 6
                                    ? _c("img", {
                                        key: index2,
                                        staticClass: "icons",
                                        attrs: {
                                          src: _vm.getUserPhoto(item3.user)
                                        }
                                      })
                                    : _vm._e()
                                }),
                                _vm._v(" "),
                                item.friends_reviews.length > 6
                                  ? _c("a", { attrs: { href: "" } }, [
                                      _c(
                                        "b",
                                        { staticClass: "counterFriends" },
                                        [_vm._v("+12")]
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      !_vm.getBestFriendReview(item)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-3 text-left d-none d-mobile-block text-center mt-4"
                            },
                            [
                              _c("hr", { staticClass: "d-sm-none" }),
                              _vm._v(" "),
                              _c("h3", [
                                _vm._v("No friend reviews yet - Be the First")
                              ]),
                              _vm._v(" "),
                              _c("h3", { staticClass: "mt-4" }, [
                                _vm._v(
                                  "(Click the Title then Review at the Bottom)"
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.showLoader
              ? _c(
                  "transition-group",
                  { attrs: { name: "slide-fade" } },
                  _vm._l(_vm.placeholdersPerPage, function(i) {
                    return _c(
                      "div",
                      {
                        key: _vm.page + i,
                        staticClass:
                          "row flex-column justify-content-center align-items-center\n        box product-box pt-4 pr-4 pl-4 pb-2 bg-light border-0 rounded shadow",
                        style: _vm.getLoaderStyle(i)
                      },
                      [
                        _c(
                          "vue-content-loading",
                          { attrs: { width: 300, height: 100 } },
                          [
                            _c("circle", {
                              attrs: { cx: "32", cy: "30", r: "30" }
                            }),
                            _vm._v(" "),
                            _c("rect", {
                              attrs: {
                                x: "0",
                                y: "70",
                                rx: "4",
                                ry: "4",
                                width: "70",
                                height: "15"
                              }
                            }),
                            _vm._v(" "),
                            _c("rect", {
                              attrs: {
                                x: "90",
                                y: "3",
                                rx: "4",
                                ry: "4",
                                width: "100",
                                height: "15"
                              }
                            }),
                            _vm._v(" "),
                            _c("rect", {
                              attrs: {
                                x: "90",
                                y: "30",
                                rx: "4",
                                ry: "4",
                                width: "100",
                                height: "55"
                              }
                            }),
                            _vm._v(" "),
                            _c("rect", {
                              attrs: {
                                x: "207",
                                y: "3",
                                r: "30",
                                rx: "4",
                                ry: "4",
                                width: "70",
                                height: "72"
                              }
                            }),
                            _vm._v(" "),
                            _c("rect", {
                              attrs: {
                                x: "207",
                                y: "70",
                                rx: "4",
                                ry: "4",
                                width: "70",
                                height: "15"
                              }
                            }),
                            _vm._v(" "),
                            _c("rect", {
                              attrs: {
                                x: "290",
                                y: "0",
                                rx: "4",
                                ry: "4",
                                width: "7",
                                height: "85"
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
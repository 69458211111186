<template>
  <div @click="hideSearch">
    <div v-if="!loading" class="group-container">
      <div v-if="mode === 'view'" class="row">
        <div class="col-md-6">
          <div class="d-flex align-items-center flex-column flex-lg-row search-and-join">
            <input type="text" v-model="searchTerm" class="form-control w-75 mb-2 mb-md-0" placeholder="Group Name" @keyup="triggerSearch()">
            <div class="button-container ml-sm-0 mt-2 mt-lg-0 ml-lg-2">
              <button @click="search(searchTerm)" class="button join dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Search & Join Groups</button>
            </div>
          </div>
          <div class="dropdown" id="groups-dropdown">
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div v-for="group in groupSearchResults" class="d-flex align-items-center px-4">
                <div>
                  <button @click="joinGroup(group)" class="join-button">Join</button>
                </div>
                <span class="flex-grow-1 ml-3">{{ group.topic }}</span>
                <div>
                  <img height="30px" width="30px" class="rounded"  :src="'/storage/' + group.image_url">
                </div>
              </div>
              <div v-if="!groupSearchResults || groupSearchResults.length === 0" class="text-center">
                <p>No Results</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-flex">
          <button @click="mode = 'create'" class="button create-btn">+ Create Group</button>
        </div>
      </div>
      <div v-else-if="mode === 'create'" class="d-flex justify-content-end">
        <button @click="mode = 'view'" class="button btn-block back">Back to View</button>
      </div>
      <div v-if="mode === 'view'" class="row mt-2">
        <div class="col-md-6">
          <div class="card box-card">
            <div class="card-header">
              <span class="text-pointer"><i class="fa fa-plus-circle mr-1" style="font-size: 20px; color: rgb(0, 174, 255); -webkit-text-stroke: 0.5px black;"></i>groups I'm in</span>
            </div>
            <div class="card-body">
              <confirm-modal v-if="leaving" message="Are you sure you want to leave this group?" @confirm="leaveGroup(leaving)" @close="leaving = null"></confirm-modal>
              <div v-if="groups.groups && groups.groups.length" class="row">
                <div v-for="group in groups.groups" class="col-md-6 mb-4">
                  <h4 @click="discuss(group)">{{ group.topic }}</h4>
                  <div class="thumb mx-auto">
                    <img @click="discuss(group)" class="img-fluid" :src="'/storage/' + group.image_url">
                    <button @click="leaving = group" class="leave"><i class="fa fa-times"></i></button>
                  </div>
                </div>
              </div>
              <div v-else class="d-flex justify-content-center">
                <h5>No Groups</h5>
              </div>
            </div>
          </div>
          <div class="card box-card mt-4">
            <div class="card-header">
              <span class="text-pointer"><i class="fa fa-plus-circle mr-1" style="font-size: 20px; color: rgb(0, 174, 255); -webkit-text-stroke: 0.5px black;"></i>groups I'm invited to</span>
            </div>
            <div class="card-body">
              <div v-if="groups.invited_groups && groups.invited_groups.length" class="row">
                <div v-for="group in groups.invited_groups" class="col-md-6 mb-4">
                  <h4>{{ group.topic }}</h4>
                  <div class="thumb mx-auto">
                    <img class="img-fluid" :src="'/storage/' + group.image_url">
                    <button @click="acceptInvite(group)" class="accept">accept</button>
                  </div>
                </div>
              </div>
              <div v-else class="d-flex justify-content-center">
                <h5>No Invites</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-md-none mt-4 mb-2">
            <button @click="mode = 'create'" class="button create-btn">+ Create Group</button>
          </div>
          <div class="card box-card">
            <div class="card-header">
              <span class="text-pointer"><i class="fa fa-plus-circle mr-1" style="font-size: 20px; color: rgb(0, 174, 255); -webkit-text-stroke: 0.5px black;"></i>groups I created</span>
            </div>
            <div class="card-body">
              <confirm-modal v-if="deleting" message="Are you sure you want to delete this group?" @confirm="deleteGroup(deleting)" @close="deleting = null"></confirm-modal>
              <invite-modal v-if="inviting" :group="inviting" @close="inviting = null" @invited="handleInvited"></invite-modal>
              <div v-if="groups.owned_groups && groups.owned_groups.length" class="row">
                <div v-for="group in groups.owned_groups" class="col-md-6 mb-4 position-relative">
                  <h4 @click="discuss(group)">{{ group.topic }}</h4>
                  <div class="thumb mx-auto">
                    <img @click="discuss(group)" class="img-fluid" style="height: 100%; width: 100%"
                      :src="'/storage/' + group.image_url">
                    <button @click="deleting = group" class="leave"><i class="fa fa-times"></i></button>
                  </div>
                  <div class="mt-2">
                    <a class="invite" @click="inviting = group">Invite Users</a>
                  </div>
                </div>
              </div>
              <div v-else class="d-flex justify-content-center">
                <h5>No Owned Groups</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center" v-else-if="mode === 'create'">
        <div class="card create mt-2">
          <div class="card-header">
            Create a new Group
          </div>
          <div class="card-body">
            <div class="alert-danger alert" v-if="errors">
              <div v-for="err in errors">{{ err[0] }}</div>
            </div>
            <div class="form-group">
              <label>Group Name</label>
              <input type="text" class="form-control" v-model="group.name">
            </div>
            <div class="form-group">
              <label>Group Description</label>
              <textarea class="form-control" v-model="group.description"></textarea>
            </div>
            <div class="form-group">
              <label>Group Thumbnail</label>
              <div class="d-flex flex-column align-items-center mb-2" @click="browseFiles()">
                <img v-if="image" class="img-thumbnail upload_image" :src="image">
              <input id="upload-group-image" type="file" class="form-control-file" @change="onFileChange">
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <button @click="save" class="btn btn-primary btn-block back">Create Group</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="my-5">
      <h2>Loading...</h2>
    </div>
  </div>
</template>
<script>
  import InviteModal from './InviteModal'
  import ConfirmModal from './ConfirmModal'
  require('../notify');

  export default {
  name: "groups",
  components: { ConfirmModal, InviteModal },
  data() {
    return {
      loading: true,
      mode: 'view',
      errors: null,
      groups: null,
      searchTerm: null,
      groupSearchResults: null,
      group: {
        name: '',
        description: '',
        image: null,
      },
      leaving: null,
      deleting: null,
      inviting: null,
      image: 'http://placehold.jp/200.png?text=Upload%20Image',
      searchTimeout: null,
    };
  },
  created: function() {
    this.loadGroups()
  },
  methods: {
    loadGroups() {
      axios.get('/groups').then(res => {
        this.groups = res.data
        this.loading = false
      })
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
      this.group.image = files[0];
    },
    createImage(file) {
      let image = new Image();
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    save() {
      this.loading = true
      let formData = new FormData();
      formData.append('image', this.group.image);
      formData.append('topic', this.group.name);
      formData.append('description', this.group.description);

      axios.post('/groups', formData).then(res => {
        this.loadGroups();
        this.mode = 'view';
      }).catch(err => {
        this.loadGroups();
        this.mode = 'view';
        this.loading = false;
        this.errors = err.response.data.errors;
        let message = '';
        $.each(this.errors, (index, error) => {
          message = error.join('<br/>');
        });


        $.notify(message, 'error');
      })
    },
    handleInvited () {
      this.inviting = null;
      this.loadGroups()
    },
    joinGroup(group) {
      axios.post(`/groups/${group.id}/join`).then(res => {
        this.loadGroups()
      })
    },
    acceptInvite(group) {
      axios.post(`/groups/${group.id}/accept`).then(res => {
        this.loadGroups()
      })
    },
    leaveGroup(group) {
      axios.post(`/groups/${group.id}/leave`).then(res => {
        this.loadGroups()
      })
    },
    deleteGroup(group) {
      axios.post(`/groups/${group.id}/delete`).then(res => {
        this.loadGroups()
      })
    },
    search(q) {
      this.groupSearchResults = null
      axios.get(`/groups/search?q=${q}`).then(res => {
        this.groupSearchResults = res.data
        $('#groups-dropdown').dropdown('show')
      })
    },
    triggerSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.search(this.searchTerm);
        $('#groups-dropdown').dropdown('show')
      }, 300);
    },
    hideSearch()
    {
      $('#groups-dropdown').dropdown('hide')
    },
    discuss(group) {
      this.$router.push(`/discussions?group=${group.id}`)
    },
    browseFiles() {
      $('#upload-group-image').click();
    },
  }
};
</script>

<style lang="scss" scoped>
  .group-container {
    width: 100%;
    margin: auto;
  }

  .form-control-file {
    cursor: pointer;
  }

  @media screen and (min-width: 576px) {
    .group-container {
      width: 85%;
    }
  }

  .back {
    max-width: 12em;
  }

  @media screen and (min-width: 576px) {
    .create {
      min-width: 650px;
    }
  }

  .join {
    min-width: 12em;
  }

  .upload_image {
    width: 200px;
  }

  .img-thumbnail {
    max-width: 200px;
    max-height: 200px;
  }

  .card-header {
    text-align: left !important;
    font-weight: bold;
    font-size: 1.7em;
  }

  .button {
    background-color: rgb(0, 174, 255);
    border: solid white 1px;
    border-radius: 5px;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }

  .box-card .card-header {
    background-color: white;
  }

  .thumb {
    position: relative;
    width: 200px;
    height: 200px;
    background-color: black;
  }

  .thumb .accept {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    font-size: 1.2em;
  }

  .thumb .leave {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: white;
    border-radius: 30px;
    font-size: 1.25em;
    height: 1.5em;
  }

  .thumb .leave i {
    margin-top: 1px;
    display: block;
  }

  .invite {
    font-weight: 900;
    font-size: 1.2em;
    color: black;
    border-bottom: black 2px solid;
  }

  .join-button {
    background-color: white;
    border-radius: 5px;
    padding: 0px 10px 0px 10px;
    font-size: 1em;
  }

  .dropdown-menu {
    width: 100%;
    background: white;
    border: black 2px solid;
  }

  #upload-group-image {
    width: auto;
    margin-top: .5rem;
      margin-left: 4rem;
  }

  @media (max-width: 991px) {
    .search-and-join {
      .form-control, .button-container, button {
        width: 100% !important;
      }
    }
    
    .create-btn {
      width: 100% !important;
    }

    .back {
      max-width: 100%;
    }
  }
</style>


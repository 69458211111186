<template>
    <form class="text-left" method="POST" action="/register" enctype="multipart/form-data">
        <div class="alert-danger alert" v-if="errors.length">
            <div v-for="(err, key) in errors">{{ err }} <hr v-if="key != errors.length - 1"/></div>
        </div>

        <input type="hidden" name="_token" :value="csrf">

        <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" class="form-control" id="name" name="name" required>
        </div>

        <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" class="form-control" id="email" name="email" required>
        </div>

        <div class="row">
            <div class="form-group col-md-6">
                <label>Profile pic</label>
                <div class="d-flex justify-content-center mb-2">
                    <img v-if="image" class="w-100 upload_image" :src="image">
                </div>
            </div>
            <div class="col-md-6">
                <label class="btn btn-outline-dark btn-avatar">
                    <i class="fa fa-image"></i> Upload Profile Photo<input name="avatar" type="file" class="form-control-file" @change="onFileChange" style="display: none;">
                </label>

                <p class="mt-4">*Helps friends & family connect with your proper profile.
                </p>
                <p>(rRipple allows you to share great finds and great products/services with friends & family)</p>
            </div>
        </div>

        <div class="form-group">
            <label for="password">Password:</label>
            <input type="password" class="form-control" id="password" name="password" required>
        </div>

        <div class="form-group">
            <label for="password_confirmation">Password Confirmation:</label>
            <input type="password" class="form-control" id="password_confirmation" name="password_confirmation" required>
        </div>

        <div class="d-flex justify-content-between flex-column flex-lg-row">
            <vue-recaptcha :sitekey="siteKey" :loadRecaptchaScript="true"></vue-recaptcha>
            <div>
                <button type="submit" class="button-p7 button btn-block">Register</button>
            </div>
        </div>
    </form>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';
  export default {
    created() {
        if (this.imgSizeError) {
            window.alert(this.imgSizeError);
        }
    },
    components: {
      VueRecaptcha
    },
    name: 'Register',
    data() {
     return {
       image: '/images/avatar.png',
     }
    },
    props: {
      csrf: String,
      siteKey: String,
      errors: Array,
      imgSizeError: {
          type: String,
          required: false,
      },
    },
    methods: {
      onFileChange(e) {
        const file = e.target.files[0];
        this.image = URL.createObjectURL(file);
      },
    },
  }
</script>

<style scoped>
    .btn-avatar {
        font-size: 20px;
        border-radius: 10px;
    }

    form {
        max-width: 70%;
    }

    .upload_image {
        border: 2px solid gray;
        border-radius: 5px;
    }
</style>

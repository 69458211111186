<template>
    <div class="row">
      <div class="col-md-6">
        <div class="card box-card">
          <div class="card-header">
            <span
              v-if="current_parent"
              @click="upLevel"
              class="text-pointer float-right"
            >
              <i
                class="fa fa-arrow-up"
                style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
              ></i> Up a level
            </span>
            <span v-else>
                Interests
            </span>
          </div>
          <div class="card-body">
            <div class="interest-lists row" style="height: 200px;">
              <div class="interest-item col-lg-4 col-md-6 col-sm-12 p-0" v-for="interest in list" :key="interest.id">
                <div
                  class="card"
                  @click="checkInterest(interest)"
                >
                  <div class="card-body">
                    <img class="img-responsive" :src="'/icons/' + interest.icon" style="height: 70px;margin-bottom: 5px;">
                    <h4 v-text="interest.name"></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card box-card">
          <div class="card-header">
            <span class="text-pointer">
              <i
                class="fa fa-plus-circle"
                style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
              ></i> selected interests
            </span>
          </div>

          <div class="card-body">
            <div class="selected-interest-lists row p-0">
              <div class="interest-item p-0" v-for="interest in tobe_saved" :key="interest.id">
                <input type="hidden" name="interests[]" :value="interest.id" v-if="input">
                <div
                    class="card p-0"
                >

                  <div class="card">
                    <div class="card-body p-0">
                      <span @click="removeSelected(index)" class="text-pointer float-right position-absolute">
                        <i class="fa fa-times"></i>
                      </span>
                      <img class="img-responsive" :src="'/icons/' + interest.icon" style="height: 70px;margin-bottom: 5px;">
                      <h4 v-text="interest.name"></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'interests-select',
    props: {
        interests: {
            required: true,
        },
        old: {
          required: false,
          default: [],
        },
        input: {
          required: false,
          type: Boolean,
          default: false,
        }
    },
    data() {
        return {
            list: null,
            current_parent: null,
            parents: [],
            selected_interests: [],
            tobe_saved: [],
            toggling: false,
        };
    },
    created() {
       this.list = this.interests;
       this.tobe_saved = this.old ? this.old : [];
    },
    methods: {
    upLevel() {
      console.log("upLevel");
      console.info(this.current_parent.parent);
      if (!this.current_parent.parent) {
        axios.get('/interests?parents-only=true').then(({ data }) => {
            this.list = data;
            this.current_parent = null;
        });
        return;
      }

      this.checkInterest(this.current_parent.parent);
    },
    checkInterest(interest) {
      console.log("checkInterest");
      let interest_id = typeof interest === "object" ? interest.id : interest;
      this.toggling = true;
      axios.get("/profile/interests/" + interest_id)
        .then(response => {
          let found_interest = response.data;
          if (found_interest.children.length) {
            this.current_parent = found_interest;
            this.list = found_interest.children;
            return;
          }

          if (
            !this.tobe_saved.find(element => {
              return element.id == found_interest.id;
            })
          ) {
            this.tobe_saved.push(found_interest);
          }
        })
        .catch(() => {})
        .then(() => {
          this.toggling = false;
        });
    },
    submitInterest() {
      console.log("submitInterest");
      var self = this;
      axios
        .post("/profile/interests", {
          interests: this.tobe_saved.map(interest => {
            return interest.id;
          })
        })
        .then(response => {
          this.current_parent = null;
          this.list = this.parents;
          this.tobe_saved = [];
          this.selected_interests = response.data;
          this.tabview = "myinterest";
        })
        .catch(() => {})
        .then(() => {})
        .finally(() => {
          console.log("Finally");
          if (self.settingUp) {
            window.location.href = '/home'
          }
        });
    },
    removeSelected(index) {
      console.log("removeSelected");
      this.tobe_saved.splice(index, 1);
    },
    },
}
</script>

<style lang="scss" scoped>
  .sidebox {
    border-radius: 10px;
    border: 2px gray solid;
  }
  .sidebox-sm {
    font-size: 16px;
  }

  p {
    margin: 0;
  }
  .text-underline {
    text-decoration: underline;
  }
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "profile-interests" } },
    [
      _c(
        "div",
        {
          staticClass:
            "navbar navbar-default flex-column flex-lg-row position-relative justify-content-between nav_bg border p-0 p-lg-3",
          attrs: { role: "navigation", id: "navigation" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex d-lg-none w-100 justify-content-between flex-row p-3 mobile-nav navbar-light align-items-center",
              staticStyle: { background: "#5d5d5d" }
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "rRbutton navbar-brand justify-content-center",
                  attrs: { to: "/" }
                },
                [_vm._v("\n        rRipple\n      ")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "btn myrr_btn btn-light d-flex align-items-center",
                  attrs: { to: "/profile" }
                },
                [_vm._v("my rR")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "nav-link mr-1 text-white d-lg-none",
                  staticStyle: { "font-size": "18px" },
                  attrs: { to: "/cart" }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-shopping-cart",
                    attrs: { "aria-hidden": "true" }
                  }),
                  _vm._v("\n\n          ("),
                  _c("span", {
                    staticClass: "cart-items-count badge-pill p-0",
                    domProps: { textContent: _vm._s(_vm.cartItemsCount) }
                  }),
                  _vm._v(")\n      ")
                ]
              ),
              _vm._v(" "),
              _vm._m(0)
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "btn myrr_btn btn-light d-none d-lg-inline",
              attrs: { to: "/profile" }
            },
            [_vm._v("my rR")]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass:
                "rRbutton navbar-brand justify-content-center d-none d-lg-inline",
              attrs: { to: "/" }
            },
            [_vm._v("\n      rRipple\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.searchSectionClass, attrs: { id: "search-section" } },
            [
              _vm.searchType == _vm.searchTypes.PRODUCT
                ? _c("div", {}, [
                    _vm._v(
                      '\n        *Terms like "Biographies" or "Surfing" are searched as categories\n      '
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-lg-row flex-column" }, [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _vm.searchType === _vm.searchTypes.PRODUCT
                    ? _c(
                        "form",
                        {
                          staticClass: "form-inline",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.searchProducts()
                            }
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.productSearchQuery,
                                expression: "productSearchQuery"
                              }
                            ],
                            staticClass: "form-control mr-0 mr-md-2 srch_box",
                            attrs: { type: "text", placeholder: "Search" },
                            domProps: { value: _vm.productSearchQuery },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.productSearchQuery = $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.searchType === _vm.searchTypes.MEDIA
                    ? _c(
                        "form",
                        {
                          staticClass: "form-inline",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.searchMedia()
                            }
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.mediaSearchQuery,
                                expression: "mediaSearchQuery"
                              }
                            ],
                            staticClass: "form-control mr-0 mr-md-2 srch_box",
                            attrs: {
                              type: "text",
                              placeholder: "tv/movie search"
                            },
                            domProps: { value: _vm.mediaSearchQuery },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.mediaSearchQuery = $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.searchType == _vm.searchTypes.PRODUCT
                    ? _c("div", { staticStyle: { "z-index": "5" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: function() {
                                  _vm.categoriesListOpen = false
                                },
                                expression:
                                  "() => { categoriesListOpen = false; }"
                              }
                            ]
                          },
                          [
                            _c(
                              "a",
                              {
                                class: [
                                  _vm.categoriesListOpen
                                    ? "category-toggle text-primary active"
                                    : "category-toggle text-primary"
                                ],
                                staticStyle: { "text-decoration": "underline" },
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.toggleCategorySearch($event)
                                  }
                                }
                              },
                              [
                                _c("strong", [
                                  _vm._v("Search by Category/Add Category")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.categoriesListOpen,
                                    expression: "categoriesListOpen"
                                  }
                                ],
                                staticClass:
                                  "category-search bg-white pt-3 pb-3 pl-1 pr-1 position-absolute shadow"
                              },
                              [
                                _c("div", { staticClass: "pl-4" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.categoryQuery,
                                        expression: "categoryQuery"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "Type to search categories..."
                                    },
                                    domProps: { value: _vm.categoryQuery },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.categoryQuery =
                                            $event.target.value
                                        },
                                        _vm.filterCategories
                                      ]
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("hr"),
                                  _vm._v(" "),
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "text-muted font-weight-bold m-0"
                                    },
                                    [_vm._v("Click the Category You Want")]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "\n                    (You can add keywords in the box above to refine within the category, or search the category alone to see\n                      what your friends have reviewed in that category!)\n                  "
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("hr"),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  { staticClass: "categories-list" },
                                  _vm._l(_vm.categoriesResults, function(
                                    category
                                  ) {
                                    return _c(
                                      "li",
                                      {
                                        class: [
                                          _vm.selectedCategory ==
                                          category.node_id
                                            ? "selected"
                                            : ""
                                        ],
                                        attrs: {
                                          "data-value": category.node_id
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectCategory(
                                              category.node_id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm.categoryQuery
                                          ? _c("span", [
                                              _c(
                                                "strong",
                                                {
                                                  staticClass: "category-query"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      category.name.substring(
                                                        0,
                                                        _vm.categoryQuery.length
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "autocomplete-text"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      category.name.substring(
                                                        _vm.categoryQuery
                                                          .length,
                                                        category.name.length
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(category.name) +
                                                  "\n                    "
                                              )
                                            ])
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "section",
                  {
                    staticClass:
                      "search-buttons d-flex flex-row justify-content-center"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "button mt-2 mt-lg-0 mr-lg-2 pr-4 pl-4 mb-lg-4",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.invokeSearch()
                          }
                        }
                      },
                      [_vm._v("Search")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button mt-2 mt-lg-0 mb-lg-4",
                        on: {
                          click: function($event) {
                            return _vm.toggleSearchType()
                          }
                        }
                      },
                      [
                        _vm._v("\n            Switch to\n            "),
                        _vm.searchType === _vm.searchTypes.PRODUCT
                          ? _c("span", [_vm._v("TV/Movie Search")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.searchType === _vm.searchTypes.MEDIA
                          ? _c("span", [_vm._v("Product Search")])
                          : _vm._e()
                      ]
                    )
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.showMobileSearchButton
            ? _c("div", {
                class: [
                  _vm.searchVisible
                    ? "mobile-search-icon active"
                    : "mobile-search-icon"
                ],
                attrs: {
                  "data-original-title": _vm.searchVisible
                    ? "Close Search"
                    : "Search",
                  "data-toggle": "tooltip",
                  "data-placement": "bottom",
                  id: "mobile-search-button"
                },
                on: {
                  click: function($event) {
                    return _vm.toggleSearch()
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showMobileSearchButton,
                expression: "!showMobileSearchButton"
              }
            ],
            class: _vm.searchRowClass,
            attrs: { id: "mobile-search-row" },
            on: {
              click: function($event) {
                return _vm.toggleSearch(true)
              }
            }
          },
          [
            _c("div", { staticClass: "search-button" }, [
              _c("div", { staticClass: "label" }, [_vm._v("Search")]),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn btn-primary bg-primary text-white"
              })
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pt-1 pt-md-3", attrs: { id: "cwrap" } }, [
        _c("div", { staticClass: "row menubar" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.arrMenu, function(item, index) {
                return _c(
                  "router-link",
                  {
                    key: item.id,
                    staticClass: "item",
                    class: ["col-md-" + item.size],
                    attrs: {
                      tag: "div",
                      "exact-active-class": "active",
                      to: item.path
                    }
                  },
                  [
                    _c("span", { staticClass: "item-label" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _vm._v(" "),
                    item.isactive
                      ? _c("div", { staticClass: "triangle-down" })
                      : _vm._e()
                  ]
                )
              }),
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.$route.path.startsWith("/products")
              ? _vm._t("default")
              : _vm._e(),
            _vm._v(" "),
            _c("router-view")
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler bg-white text-secondary pt-1 pb-1",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
import Axios from 'axios';
import Echo from 'laravel-echo';
import Swal from 'sweetalert2';
import bootbox from 'bootbox';
require('../notify');

$(function () {
  $('body .bootbox').css('z-index', 999999999);
  let refreshDatabaseConsoleOuput = '';
  let refreshDatabaseTitle = '';

  function bootBoxModal(title, message, type = 'warning') {
      bootbox.dialog({
          message: message,
          title: title,
          alertType: type,
      });
  }

  window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.MIX_PUSHER_APP_KEY,
      forceTLS: false,
  });
  window.Echo.private(`users.${window.User.id}`)
    .listen('.admin.database.refresh', e => {
      refreshDatabaseConsoleOuput += e.type == 'out'
        ? `<p class="text-success">${e.output}</p>`
        : `<p class="text-danger">${e.output}</p>`;

      $('#db-refresh-output').html(refreshDatabaseConsoleOuput);
      $('#db-refresh-output').scrollTop($('#db-refresh-output')[0].scrollHeight);
    })
    .listen('.admin.database.refresh.complete', e => {
      if (e.success) {
        refreshDatabaseTitle = "<p class='text-success p-0 m-0' id='db-refresh-title'><i class='fa fa-check mr-2'></i>"
        + "Database refreshed successfully!</p>";
      } else {
        refreshDatabaseTitle = "<p class='text-danger p-0 m-0' id='db-refresh-title'><i class='fa fa-times mr-2'></i>"
        + "Database refresh failed!</p>";
      }
      refreshDatabaseConsoleOuput = '';
      $('#db-refresh-title').replaceWith(refreshDatabaseTitle);
    });

  $('#refresh-database').on('click', e => {
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.preventDefault();

    Swal.fire({
        title: 'Do you really want to refresh the database?',
        text: 'This is irreversible! The database will be cleared and re-seeded.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
        focusCancel: true,
    }).then(confirmed => {
        if (confirmed.value) {
          return Axios.post('/dashboard/database/refresh').then(({ data }) => {
            let message = data.message;
            refreshDatabaseTitle = "<p class='text-primary p-0 m-0' id='db-refresh-title'><i class='fa fa-sync spin mr-2'></i>" + message + "</p>";

            if (data.dispatched) {
              bootBoxModal(
                refreshDatabaseTitle, "<code id='db-refresh-output'><strong>Please do not close this page!</strong></code>"
              );

              $('#db-refresh-output').css({
                display: 'inline-block',
                height: '200px',
                width: '100%',
                'overflow-y': 'auto',
              })
            } else {

              $.notify(message, 'error');
            }
          }).catch(err => {
            $.notify(`<i class="fa fa-times"></i> ${err}`, 'error');
          });
        }
    });
  })
});

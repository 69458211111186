<template>
    <span :class="['influencer-badge', `influencer-badge--${type}`]">Influencer</span>
</template>

<script>
export default {
    props: ['type']
}
</script>

<style lang="scss">
    .influencer-badge {
        color: #00aeff;
        font-size: 16px;
        line-height: 1;

        &.influencer-badge--super {
            position: relative;
            top: -0.5rem;
            margin-left: 0.5rem;
        }
    }
</style>
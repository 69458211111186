<template>
    <div :id="id" :style="style">
        <div class="row justify-content-start pb-2 pl-3" v-show="title">
            <h3 class="text-muted">
                {{ title }}
            </h3>
            <div class="divider"></div>
        </div>

        <slot name="prepend"></slot>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <input type="text" :name="getName('first_name')" id="firstName" class="form-control"
                        placeholder="First Name" :value="autofill('first_name')">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <input type="text" :name="getName('last_name')" id="lastName" class="form-control"
                        placeholder="Last Name" :value="autofill('last_name')">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <input type="text" :name="getName('company')" class="form-control"
                        placeholder="Company (optional)" :value="autofill('company')">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <input type="text" :name="getName('address1')" class="form-control"
                        placeholder="Address line 1"
                        :value="autofill('address1')">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <input type="text" :name="getName('address2')" class="form-control"
                        placeholder="Address line 2 (optional)"
                        :value="autofill('address2')">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <input type="text" :name="getName('city')" class="form-control" placeholder="City"
                        :value="autofill('city')">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <input type="text" :name="getName('state')" class="form-control" placeholder="State"
                        :value="autofill('state')">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <input type="text" :name="getName('zip')" class="form-control" placeholder="Postal Code"
                        :value="autofill('zip')" @change="onChangeHandler($event)">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <select class="form-control" :name="getName('country')" v-model="country"
                        @change="onChangeHandler($event)">
                        <option v-for="listCountry in countries" :key="listCountry" :value="listCountry">
                            {{ listCountry }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row" v-if="phone">
            <div class="col-md-12">
                <div class="form-group">
                    <input type="text"
                        :name="getName('phone')"
                        class="form-control"
                        placeholder="Phone number (optional)"
                        :value="autofill('phone')"
                       >
                </div>
            </div>
        </div>

        <slot name="append"></slot>
    </div>

</template>

<script>
// import 'select2/dist/js/select2.full';
// import 'select2/dist/css/select2.css';
// import 'select2-bootstrap-theme/dist/select2-bootstrap.css';
import { CountrySelect } from 'vue-country-region-select'
import countries from '../countries';

export default {
    components: {
        CountrySelect
    },
    props: {
        wrapper: {
            required: false,
            default: 'address',
        },
        title: {
            required: false,
            default: 'Billing Address',
        },
        id: {
            required: false,
            default: 'address',
        },
        hidden: {
            required: false,
            default: false,
        },
        'css': {
            required: false,
        },
        'phone': {
            required: false,
            type: Boolean,
            default: true,
        },
        autofill_data: {
            required: false,
            type: Object,
        },
        onChange: {
            required: false,
            type: Function
        },
    },
    mounted() {
        // $('select[name="shipping[country]"], select[name="billing[country]"]').select2({
        //     theme: 'bootstrap',
        //     containerCssClass: 'mb-2',
        // });
    },
    data() {
        return {
            countries: countries,
            _country: null,
        }
    },
    methods: {
        getName(name) {
            // e.g. address[first_name]
            return this.wrapper + '[' + name + ']';
        },
        autofill(input) {
            if (!this.autofill_data || !input in this.autofill_data) {
                return '';
            }

            return this.autofill_data[input];
        },
    },
    computed: {
        style () {
            const customCss = this.css;

            return this.hidden ? 'display: none;' + customCss : customCss;
        },
        country: {
            get () {
                if (this._country) {
                    return this._country;
                }

                return this.autofill_data && this.autofill_data.country ? this.autofill_data.country : 'United States';
            },
            set: function(value) {
                this._country = value;
            }
        },
        onChangeHandler() {
            if (this.onChange) {
                return this.onChange;
            }

            return () => {};
        },
    }
}
</script>
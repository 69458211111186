var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "profile-interests" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { attrs: { id: "cwrap" } }, [
      _c(
        "div",
        { staticClass: "row menubar" },
        _vm._l(_vm.arrMenu, function(item, index) {
          return _c(
            "a",
            {
              key: item.id,
              staticClass: "item active",
              class: ["col-md-" + item.size]
            },
            [
              _vm._v(
                "\n                " + _vm._s(item.name) + "\n                "
              ),
              item.isactive
                ? _c("div", { staticClass: "triangle-down" })
                : _vm._e()
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", [_c("interesets", { attrs: { "setting-up": true } })], 1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "navbar navbar-default justify-content-between nav_bg border",
        attrs: { role: "navigation" }
      },
      [
        _c(
          "a",
          {
            staticClass: "rRbutton navbar-brand justify-content-center",
            attrs: { href: "http://webclient.test/profile2#" }
          },
          [_vm._v("rRipple")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "navbar-right" }, [
          _c(
            "form",
            {
              staticClass: "form-inline",
              attrs: { action: "/action_page.php" }
            },
            [
              _c("input", {
                staticClass: "form-control mr-sm-2 srch_box",
                attrs: { type: "text", placeholder: "Search" }
              })
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="news-feed-page">
    <div v-if="isShowFeed">
      <div class="m-2 container mx-auto fetching-feed" v-if="fetchingFeeds">
        <div class="card box-card">
          <div class="card-body">
            <h4 class="py-5">Loading Newsfeed</h4>
          </div>
        </div>
      </div>
      <div class="m-2 container mx-auto"
        v-else-if="!fetchingFeeds && (!feeds || feeds.length < 1)">
        <div class="card box-card">
          <div class="card-body">
            <h4 class="py-5">No feed items were found</h4>
          </div>
        </div>
      </div>
      <section id="news-feed-container">
        <div v-for="(item,index) in feeds" :key="index" class="box item-box">
          <div class="row">
            <!-- First Panel -->
            <section class="d-min-tablet-none" style="margin: 0 auto;">
              <template v-if="item.feed_item_type === 'MediaItem'">
                <router-link
                        :to="{ name: 'media-details', params: { id: item.imdb_id }}"
                        class="tagname prodlink"
                      >
                        {{ item.title }}
                      </router-link>
                <div class="tagname">{{ item.type === 'movie' ? 'Movie' : 'TV Series' }}</div>
              </template>
              <template v-else>
                <router-link
                  :to="getProductLink(item, item.user, user)"
                  class="tagname prodlink"
                >{{ item.name }}</router-link>
                <div class="tagname">${{ numeral(item.product_price).format('0,0.00') }}</div>
              </template>
            </section>
            <div class="col-sm-3">
              <div class="rr-badge-container">

                  <div class="review">
                    <div class="font-weight-bolder">
                        {{ item.review.rating > 5 ? 5 : item.review.rating }}
                        <i class="fa fa-star text-warning"></i>
                        <span v-if="item.review.rating > 5">
                            + <i class="fa fa-moon text-warning"
                                style="transform: rotate(200deg);"></i>!
                        </span>
                    </div>
                  </div>
                  <div v-if="isInfluencer(item.user)" class="rr-badge">rR</div>
                  <img class="userimg" :src="getUserPhoto(item.user)" />
              </div>
              <div class="tagname">
                {{ item.user ? item.user.name : 'A friend reviewed' }}
                
                <div class="influencer-badge-container" v-if="isInfluencer(item.user)">
                  <InfluencerBadge></InfluencerBadge>
                </div>
              </div>
            </div>
            <!-- Second Panel -->
            <div class="line col-sm-4">
              <div v-if="item.user" class="tagname d-none d-min-tablet-block">{{ item.user.name }} says:</div>
              <p class="contentDesc">{{ item.review.content }}</p>

              <div class="mt-4 imdb-rating" v-if="item.feed_item_type === 'MediaItem'">
                IMDB Rating: {{ item.rating }}/10 - {{ item.votes | localNumber }} IMDB reviews
              </div>
            </div>

            <div class="col-sm-5">
              <div class="container">
                <div class="row">
                  <!-- Product Feed Item -->
                  <div class="col-sm-8 col-md-8 col-lg-10" v-if="item.feed_item_type === 'Product'">
                    <router-link
                      :to="getProductLink(item, item.user, user)"
                      class="tagname prodlink d-none d-min-tablet-block"
                    >{{ item.name }}</router-link>
                    <div class="tagname d-none d-min-tablet-block">${{ numeral(item.product_price).format('0,0.00') }}</div>
                    <router-link :to="getProductLink(item, item.user, user)">
                      <img
                        class="prothumb"
                        v-if="Object.keys(item).includes('photos') && item.photos.length > 0"
                        :src="'/storage/product_images/' + item.photos[0].server_path"
                      />
                    </router-link>
                  </div>
                  
                  <!-- MediaItem Feed Item -->
                  <div class="col-sm-8 col-md-10" v-if="item.feed_item_type === 'MediaItem'">
                    <router-link
                      :to="{ name: 'media-details', params: { id: item.imdb_id }}"
                      class="tagname prodlink"
                    >
                      {{ item.title }}
                      <div class="tagname">{{ item.type === 'movie' ? 'Movie' : 'TV Series' }}</div>
                      <img
                        class="prothumb"
                        :src="item.poster"
                      />
                    </router-link>
                  </div>

                  <div class="col-sm-2 col-md-1 rate">
                    <b>{{ item.average_rating }}</b>
                    <section class="avg-review-graphic">
                    <img class="irate" :src="[item.average_rating > 5 ? '/images/moon-filled.png' : '/images/moon.png']"/>

                    <section  class="review-stars">
                      <span v-for="(star,index) in 5" :key="star">
                        <img v-if="star > item.average_rating" class="icons irate" src="/images/star2.png" />
                        <img v-else class="icons irate" src="/images/star1.png" />
                      </span>
                    </section>
                    </section>
                  </div>
                </div>
              </div>

              <div class="frd_viewer">
                <img
                  v-for="(item3,index2) in item.reviewingFriends[0]"
                  :key="index2"
                  class="icons"
                  v-if="index2 <= 6"
                  :src="getUserPhoto(item3.user)"
                />

                <a v-if="item.reviewingFriends > 6" href>
                  <b class="counterFriends">+12</b>
                </a>
              </div>
              <!-- end of col-sm-5 -->
            </div>
          </div>
        </div>
      </section>

      <div class="m-2 container mx-auto"
        v-if="!fetchingFeeds && no_match">
        <div class="card box-card">
          <div class="card-body">
            <h4 class="py-5">Please add more interests on your profile page to see more reviews from your friends, family, and influencers!</h4>
          </div>
        </div>
      </div>
      <div class="row flex-column justify-content-center align-items-center"
        v-if="showLoader">
          <div class="lds-facebook"><div></div><div></div><div></div></div>
          <span class="text-white">Loading...</span>
      </div>
      <!-- Box -->
    </div>
    <div v-else></div>
  </div>
</template>
<script>
import InfluencerBadge from './InfluencerBadge';
import { isInfluencer } from '../services/user';
import collect from 'collect.js';
const numeral = require('numeral');

export default {
  name: "interesets",
  props: ["user_interests", "interests"],
  components: { InfluencerBadge },

  data() {
    return {
      tabview: "myinterest",
      current_parent: null,
      list: this.interests,
      selected_interests: this.user_interests,
      tobe_saved: [],
      feeds: [],
      isShowFeed: true,
      fetchingFeeds: false,
      feedPage: 1,
      totalPages: null,
      loadMore: false,
      page: 1,
      no_match: false,
    };
  },
  created: function() {
    this.fetchFeeds();
  },
  mounted() {
    $(window).on('scroll', e => {
        const feedContainer = $('#news-feed-container');

        if (feedContainer.length > 0) {
            if (this.isOnScreen('.item-box:last')) {
              console.log('Reached last result!');
              if (!this.loadMore && this.canLoadMore) {
                this.loadMore = true;
                console.log('Loading a new page...')
                this.fetchFeeds(true)
              }
            }
        }
      })
  },
  methods: {
    isInfluencer,
    fetchFeeds: function(next = false) {
      let url = '/fetchfeeds';
      let page = null;


      if (this.$route.query.page) {
        page = this.$route.query.page;
      } else if (next) {
        page = this.page + 1;
      }

      if (page) {
        url += `?page=${page}`;
      } else {      
        this.fetchingFeeds = true;
      }

      axios.get(url).then(({ data }) => {
        if (page && page > 1) {
          console.log(data.feeds);
          this.feeds = this.feeds.concat(Object.values(data.feeds));
          console.log(this.feeds);
          this.page = this.page + 1;
        } else {
          this.feeds = Object.values(data.feeds);
        }
        // The authenticated user
        this.user = data.user;
        this.totalPages = data.total_pages;
        this.fetchingFeeds = false;
        this.no_match = data.no_match;
      }).finally(() => {
        this.loadMore = false;
      });
    },
    setActiveProduct: function(item) {
      this.$parent.activeItem = item;
      this.$parent.showModalProfile();
      this.$parent.isShowPanel = false;
      this.$parent.isInterest = false;
    },
    getProductLink: function(product, reviewer, authenticatedUser) {
      const productId = product.id;

      if (!reviewer || !reviewer.hasOwnProperty('id')) {
        return { name: 'product-details', params: { id: productId } };
      }


      if (reviewer.id == authenticatedUser.id || product.already_referred) {
        return { name: 'product-details', params: { id: productId } };
      }

      // Convert the "reviewer"'s name to a slug
      const nameSlug = reviewer.name.split(' ').join('-').toLowerCase();

      return { name: 'product-details', params: { id: productId }, query: { referral: nameSlug + '-' + reviewer.id } };

      // return '/products/' + productId + '?referral=' + nameSlug + '-' + reviewer.id;
    },
    isOnScreen(elem) {
      elem = $(elem);
      // if the element doesn't exist, abort
      if( elem.length == 0 ) {
        return;
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (top >= viewport_top && top < viewport_bottom) ||
      (bottom > viewport_top && bottom <= viewport_bottom) ||
      (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
    },
    numeral(args) {
      return numeral(args);
    },
    getUserPhoto(user) {
      if (!user) {
        return '/images/avatar.png';
      }

      if (user.avatar) {
        return `/storage/images/${user.avatar}`;
      }

      if (user.facebook_profile_pic_url) {
        return user.facebook_profile_pic_url;
      }

      return '/images/avatar.png';
    },
  },
  computed: {
    canLoadMore() {
      return this.page + 1 <= this.totalPages;
    },
    showLoader() {
      return this.canLoadMore && this.loadMore;
    },
  }
};
</script>
<style lang="scss" scoped>
  .prodlink {
    color: #212529;
  }

  .influencer-badge-container {
    line-height: 1;
    margin-top: -7px;
  }

  .imdb-rating {
    font-size: 23px;
    font-weight: bold;
  }

  .rr-badge {
    /* top: 0;
    left: 0; */
  }

  .fetching-feed {
    @media (max-width: 991px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  
  .icons.irate {
    @media screen and (max-width: 928px) {
      height: 5vh;
      max-width: 5vw;
    }
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.succeeded
      ? _c(
          "form",
          {
            staticClass: "row checkout-wrapper",
            attrs: { id: "checkout-form", method: "POST" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.pay($event)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "col-md-8 mx-auto pl-5 pr-5 pt-3 pb-3 bg-white" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.cart || _vm.attempting,
                        expression: "!cart || attempting"
                      }
                    ],
                    staticClass: "loading-overflow text-center flex-column"
                  },
                  [
                    _c("i", { staticClass: "fa fa-circle-notch spin" }),
                    _vm._v(" "),
                    _c("h2", {
                      staticClass: "mt-3",
                      domProps: { textContent: _vm._s(_vm.loading_text) }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.error,
                          expression: "error"
                        }
                      ],
                      staticClass:
                        "alert alert-danger alert-dismissible fade show text-left",
                      attrs: { role: "alert", id: "error-alert" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: {
                            click: function($event) {
                              _vm.error = null
                            }
                          }
                        },
                        [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("×")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Close")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "m-0",
                        domProps: { innerHTML: _vm._s(_vm.error) }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("address-form", {
                  attrs: {
                    wrapper: "billing",
                    autofill_data: _vm.billing_address,
                    "on-change": _vm.onAddressFieldChange
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 text-left" }, [
                                _c("div", { staticClass: "form-check" }, [
                                  _c(
                                    "label",
                                    { staticClass: "form-check-label" },
                                    [
                                      _c("input", {
                                        staticClass: "form-check-input",
                                        attrs: {
                                          type: "checkbox",
                                          name: "ship_is_bill",
                                          id: "ship_is_bill",
                                          checked: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleShippingForm()
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                Shipping address is the same as billing\n                            "
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1889552166
                  )
                }),
                _vm._v(" "),
                _c("address-form", {
                  attrs: {
                    id: "shipping-address",
                    hidden: "true",
                    css: "margin-top: 1rem;",
                    title: "Shipping Address",
                    wrapper: "shipping",
                    phone: false,
                    autofill_data: _vm.shipping_address,
                    "on-change": _vm.onAddressFieldChange
                  }
                }),
                _vm._v(" "),
                _c("card-form", {
                  attrs: {
                    css: "margin-top: 1rem;",
                    "card-error": _vm.cardError
                  }
                }),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "row flex-column border border-dark m-2 p-2 shadow-sm"
                  },
                  [
                    _c("div", { staticClass: "col-12 text-left" }, [
                      _c("strong", [_vm._v("Cart Total:")]),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            "$" + _vm.numeral(_vm.cartTotal).format("0,0.00")
                          )
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 text-left" }, [
                      _c("strong", [_vm._v("Shipping Rate:")]),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            "$" +
                              _vm.numeral(_vm.shipping_rate).format("0,0.00")
                          )
                        }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary btn-block font-weight-bold",
                        attrs: { type: "button", disabled: _vm.attempting },
                        on: {
                          click: function($event) {
                            return _vm.fetchPaymentToken()
                          }
                        }
                      },
                      [
                        _vm.attempting
                          ? _c("i", { staticClass: "fa fa-circle-notch spin" })
                          : _vm._e(),
                        _vm._v("\n                        Pay "),
                        _vm.cart && !_vm.attempting
                          ? _c("span", [_vm._v("$" + _vm._s(_vm.total))])
                          : _vm._e()
                      ]
                    )
                  ])
                ])
              ],
              1
            )
          ]
        )
      : _c(
          "div",
          {
            staticClass:
              "col-md-8 mx-auto pl-5 pr-5 pt-3 pb-3 bg-white justify-content-center align-items-center",
            staticStyle: { height: "100%" }
          },
          [
            _c("i", {
              staticClass: "fa fa-check-circle text-success display-3"
            }),
            _vm._v(" "),
            _c("h1", [_vm._v("Order Confirmation")]),
            _vm._v(" "),
            _c("h5", { staticClass: "text-muted" }, [
              _vm._v("Your order has been successfully submitted.")
            ]),
            _vm._v(" "),
            _c("h4", {}, [
              _vm._v("Order Number: "),
              _c("span", { domProps: { textContent: _vm._s(_vm.order_num) } })
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-primary text-white",
                attrs: { href: "/home" }
              },
              [_vm._v("Return to homepage")]
            )
          ]
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "title" }, [
        _c("i", { staticClass: "fa fa-shopping-bag" }),
        _vm._v("\n                    Checkout\n                ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-12 text-left" }, [
        _c("div", { staticClass: "form-check" }, [
          _c("label", { staticClass: "form-check-label" }, [
            _c("input", {
              staticClass: "form-check-input",
              attrs: { type: "checkbox", name: "private", value: "true" }
            }),
            _vm._v(
              "\n                        Private Purchase\n                      "
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="product-search-results">
    <div v-if="searchError" class="text-white p-5">There was an error with your search. Please try again.</div>

    <div v-if="isEmptyQuery && isEmptyCategory" class="text-white p-5">The search query and category are empty.</div>

    <div v-if="hasNoResults" class="text-white p-5">No results</div>

    <div v-if="hasResults" id="product-results-container">
      <div class="loadingBar d-flex justify-content-between align-items-center mb-2" style="margin-bottom: 10px;width: 70%;margin: 0 auto;" v-if="page == 1 && showLoader && getProducts.length < 1">
        <div id="loading">
          <h1 class="text-white" v-text="loadingText"></h1>
        </div>
        <div class="progress" style="width: 300px; margin-left: 10px; height: 30px; border-radius: 50px;">
          <div class="progress-bar bg-primary" role="progressbar" :style="'width: ' + progressBarPercent + '%;'" :aria-valuenow="progressBarPercent" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <transition-group transition name="slide-fade">
        <div v-for="(item,index) in getProducts" :key="index" class="box product-box">
          <div class="row">
            <section class="d-mobile-block d-min-tablet-none" style="margin: 0 auto;">
              <router-link
                :to="getProductLink(item.id)"
                class="tagname prodlink"
              >{{ item.name.length > 70 ? item.name.substr(0, 70) + '...' : item.name }}</router-link>
              <div class="tagname">${{ numeral(item.product_price).format('0,0.00') }}</div>
            </section>
            <!-- First Panel -->
            <div class="col-sm-3" v-if="review = getBestFriendReview(item)">
              <div class="rr-badge-container">
                  <div class="review">
                    {{ review.rating }}
                    <img src="/images/star1.png" width="40px" />
                  </div>
                  <div v-if="isInfluencer(item)" class="rr-badge">rR</div>
                  <img class="userimg"
                    :src="getUserPhoto(review.user)"/>
              </div>
              <div class="tagname">
                {{ review.user ? review.user.name : 'A friend reviewed' }}

                <div class="influencer-badge-container" v-if="isInfluencer(item)">
                  <InfluencerBadge></InfluencerBadge>
                </div>
              </div>
            </div>

            <div class="col-sm-3 text-left d-md-block d-tablet-block d-mobile-none" v-else>
              <h3>No friend reviews yet - Be the First</h3>
              <h3 class="mt-4">(Click the Title then Review at the Bottom)</h3>
            </div>
            <!-- Second Panel -->
            <div class="line col-sm-4">
              <section v-if="review = getBestFriendReview(item)">
                <div v-if="review.user" class="tagname">{{ review.user.name }} says:</div>
                <p class="contentDesc">{{ review.content }}</p>
              </section>
              <section v-else>
                <h3>About this product</h3>
                <p class="product-description" v-if="item.description && item.description != 0" v-html="item.description"></p>
                <p v-else>This product does not have a description yet.</p>
              </section>
            </div>

            <div class="col-sm-5">
              <div class="container">
                <div class="row">
                  <!-- Product Feed Item -->
                  <div class="col-sm-8 col-md-8 col-lg-10">
                    <router-link
                      :to="getProductLink(item.id)"
                      class="tagname prodlink d-none d-min-tablet-block"
                    >{{ item.name.length > 70 ? item.name.substr(0, 70) + '...' : item.name }}</router-link>
                    <div class="tagname d-none d-min-tablet-block">${{ numeral(item.product_price).format('0,0.00') }}</div>
                    <div class="thumb-container">
                      <router-link :to="getProductLink(item.id)">
                        <img
                          class="prothumb"
                          style="max-height: 400px;"
                          :src="getFeaturedPhoto(item)"
                        />
                      </router-link>
                    </div>
                  </div>

                  <div class="col-sm-2 col-md-1 rate">
                    <b>{{ item.average_rating }}</b>
                    <section class="avg-review-graphic">
                      <img class="irate" :src="[item.average_rating > 5 ? '/images/moon-filled.png' : '/images/moon.png']"/>
                      <section class="review-stars">
                        <span v-for="(star,index) in 5" :key="star">
                          <img v-if="star > item.average_rating" class="icons irate" src="/images/star2.png" />
                          <img v-else class="icons irate" src="/images/star1.png" />
                        </span>
                      </section>
                    </section>
                  </div>
                </div>
              </div>

              <div class="frd_viewer" v-if="item.id && Object.keys(item).includes('friends_reviews') && item.friends_reviews.length > 0">
                <img
                  v-for="(item3,index2) in item.friends_reviews"
                  :key="index2"
                  class="icons"
                  v-if="index2 <= 6"
                  :src="getUserPhoto(item3.user)"
                />

                <a v-if="item.friends_reviews.length > 6" href>
                  <b class="counterFriends">+12</b>
                </a>
              </div>
              <!-- end of col-sm-5 -->
            </div>


            <div class="col-sm-3 text-left d-none d-mobile-block text-center mt-4" v-if="!getBestFriendReview(item)">
              <hr class="d-sm-none"/>
              <h3>No friend reviews yet - Be the First</h3>
              <h3 class="mt-4">(Click the Title then Review at the Bottom)</h3>
            </div>
          </div>
        </div>
      </transition-group>
      <transition-group name="slide-fade" v-if="showLoader" >
        <div v-for="i in placeholdersPerPage" class="row flex-column justify-content-center align-items-center
          box product-box pt-4 pr-4 pl-4 pb-2 bg-light border-0 rounded shadow" :key="page + i" :style="getLoaderStyle(i)">
            <!-- <div class="lds-facebook"><div></div><div></div><div></div></div>
            <span class="text-white">Loading...</span> -->
            <vue-content-loading :width="300" :height="100">
              <circle cx="32" cy="30" r="30" />
              <rect x="0" y="70" rx="4" ry="4" width="70" height="15" />
              <rect x="90" y="3" rx="4" ry="4" width="100" height="15" />
              <rect x="90" y="30" rx="4" ry="4" width="100" height="55" />
              <rect x="207" y="3" r="30" rx="4" ry="4" width="70" height="72"/>
              <rect x="207" y="70" rx="4" ry="4" width="70" height="15" />
              <rect x="290" y="0" rx="4" ry="4" width="7" height="85" />
            </vue-content-loading>
        </div>
      </transition-group>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, commit } from 'vuex';
import { isInfluencer } from '../services/user';
import collect from 'collect.js';
import Pusher from "pusher-js"
import Echo from "laravel-echo";
import VueContentLoading from 'vue-content-loading';
const numeral = require('numeral');

export default {
  components: {
    VueContentLoading,
  },
  created() {
    console.debug(`Pusher Key: ${process.env.MIX_PUSHER_APP_KEY}`)
  },
  mounted() {
    $(() => {

      if (this.subscribedToPusher) {
        console.debug('Already subscribed to Pusher.')
        
        if((!this.isEmptyQuery || !this.isEmptyCategory) && !this.searchInProgress) {
          this.searchProducts({ productSearchQuery: this.$route.query.q, productSearchCategory: this.$route.query.category })
        } else {
          console.log('Did not invoke search')
        }
      }

      window.Echo = new Echo({
          broadcaster: 'pusher',
          key: process.env.MIX_PUSHER_APP_KEY,
          cluster: process.env.MIX_PUSHER_APP_CLUSTER,
          encrypted: false
      });

      window.Echo.private(`users.${window.User.id}`)
          .listen('.paapi.products.imported', (event) => {
            this.setPagination({ pagination: event.pagination })
            if (event.keywords == this.query && event.nodeId == this.category) {
              this.retrieveCachedResults(event.cache_key);
            } else {
              console.warn('Resutls from an old search will not be retrieved.', {
                Query: event.keywords,
                Category: event.nodeId,
              });
            }
          })
          .listen('.paapi.products.failed', event => {
            console.error('Search failed:', event.exception);
            this.forceStop();
          }).on('pusher:subscription_succeeded', member => {
            console.debug('Pusher subscription succeeded')
            if((!this.isEmptyQuery || !this.isEmptyCategory) && !this.searchInProgress) {
              console.debug('Started search after Pusher subscription')
              this.searchProducts({ productSearchQuery: this.$route.query.q, productSearchCategory: this.$route.query.category })
              this.setSubscribedToPusher(true)
            }
          }).on('pusher:subscription_error', () => {
            console.warn('Pusher subscription failed!');
            window.alert('An error has occured! Please refresh the page and try again.');
          })

      $(window).on('scroll', e => {
        const resultsContainer = $('#product-results-container');
        if (resultsContainer.length > 0) {
            if (this.isOnScreen('.product-box')) {

              // console.debug(this.loadMore, this.canLoadMore)
              if (!this.loadMore && this.canLoadMore) {
                console.debug('Loading a new page...')
                this.incrementPage();
                this.setCacheRetrieved({ cacheRetrieved: false });
                this.setLoadMore({ loadMore: true });
                console.debug('Started search after scroll')
                this.searchProducts({
                  productSearchQuery: this.$route.query.q,
                  productSearchCategory: this.$route.query.category,
                });
              }
            }
        }
      })

      $('html, body').animate({
          scrollTop: $("#product-results-container").offset().top
      }, 1500);
    })
  },
  updated() {
    this.$nextTick(() => {
      $('.product-description').each(function () {
        if ($(this).height() < $(this)[0].scrollHeight) {
          $(this).addClass('read-more');
        }
      })
    })
  },
  data() {
    return {
      env: window.Env,
      settingsExpanded: true,
    }
  },
  watch: {
    query(oldValue, newValue) {
      if (oldValue == newValue) {
        return
      }

      console.debug('Started search after Query change')
      this.searchProducts({ productSearchQuery: this.$route.query.q, productSearchCategory: this.$route.query.category })
    },
    category(oldValue, newValue) {
      if (oldValue == newValue) {
        return
      }

      console.debug('Started search after Category change')
      this.searchProducts({ productSearchQuery: this.$route.query.q, productSearchCategory: this.$route.query.category })
    }
  },
  methods: {
    isInfluencer,
    ...mapActions('product', [
      'searchProducts',
      'retrieveCachedResults',
      'setPagination',
      'setLoadMore',
      'setCacheRetrieved',
      'incrementPage',
      'forceStop',
      'startTimeouts',
      'clearTimeouts',
    ]),
    ...mapMutations('product', [
      'setSubscribedToPusher',
    ]),
    getProductLink(productId) {
      return { name: 'product-details', params: { id: productId } };
    },
    getFeaturedPhoto(product) {
      return product.photo_featured ?? product.image_link;
    },
    hasFeaturedPhoto(product) {
      return Object.keys(product).includes('photo_featured')
        || Object.keys(product).includes('image_link');
    },
    getBestFriendReview(product) {
      const friendsReviews = collect(product.friends_reviews);

      if (friendsReviews.isEmpty()) {
        return false;
      }

      return friendsReviews.sortByDesc('rating').first();;
    },
    getMostRecentReview(product) {
      const reviews = collect(product.reviews);

      if (reviews.isEmpty()) {
        return false;
      }

      return reviews.sortByDesc('created_at').first();
    },
    getItemDescription(item) {
      return item.description.substr(0, 100) + (item.description.length > 100 ? '...' : '');
    },
    isOnScreen(elem) {
      const elementNumber = this.getProducts.length > 6 ? this.getProducts.length - 6 : (this.getProducts.length - Math.ceil(this.getProducts.length / 2));

      elem = $(elem).eq(elementNumber);
      // console.debug(`Result N°${elementNumber} is now on screen!`, elem);

      // if the element doesn't exist, abort
      if( elem.length == 0 ) {
        return false;
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (top >= viewport_top) ||
      (bottom > viewport_top && bottom <= viewport_bottom) ||
      (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
    },
    getLoaderStyle(scale) {
      return {
        transform: 'scale(' + (1 - (.01 * scale)) + ')',
        opacity: (1 - (.05 * scale)),
      };
    },
    numeral(args) {
      return numeral(args);
    },
    getUserPhoto(user) {
      if (!user) {
        return '/images/avatar.png';
      }

      if (user.avatar) {
        return `/storage/images/${user.avatar}`;
      }

      if (user.facebook_profile_pic_url) {
        return user.facebook_profile_pic_url;
      }

      return '/images/avatar.png';
    },
  },
  computed: {
    ...mapGetters('product', [
      'getProducts',
      'searchInProgress',
      'searchComplete',
      'searchError',
      'cacheRetrieved',
      'pagination',
      'loadMore',
      'page',
      'isForceStopped',
      'expectedResults',
      'loadingText',
      'progressBarPercent',
      'subscribedToPusher',
    ]),
    placeholdersPerPage() {
      return this.page > 1 ? 1 : 2;
    },
    hasNoResults() {
      return !this.searchInProgress && !this.isEmptyQuery && this.getProducts.length === 0 && this.cacheRetrieved;
    },
    hasResults() {
      return this.getProducts;
    },
    isEmptyQuery() {
      return !this.query;
    },
    isEmptyCategory() {
      return !this.category;
    },
    query() {
      return this.$route.query.q ?? "";
    },
    category() {
      return this.$route.query.category ?? 0;
    },
    savedProducts() {
      return collect(this.getProducts).filter(product => {
        return 'id' in product && Number.isInteger(product.id) && product.id > 0;
      }).toArray();
    },
    showLoader() {
      if (this.hasNoResults || this.searchError) {
        return false;
      }

      if (this.isEmptyQuery && this.isEmptyCategory) {
        return false;
      }

      if (this.searchInProgress) {
        return true;
      }

      if (this.savedProducts < this.expectedResults && !this.cacheRetrieved) {
        return true;
      }

      if (this.savedProducts.length >= this.expectedResults) {
        return false;
      }

      if (this.canLoadMore && this.loadMore) {
        return true;
      }

      return false;
    },
    canLoadMore() {
      console.debug('Number of Pages:', this.pagination.number_of_pages);
      console.debug('Current Page:', this.page);
      console.debug('Expected Results:', this.expectedResults);
      console.debug('All Results:', this.getProducts.length);
      console.debug('Saved Results:', this.savedProducts.length);

      return (
          (this.savedProducts.length < this.expectedResults)
          && !this.searchInProgress
        )
        && !this.isForceStopped;
    },
  },
  beforeDestroy() {
    this.clearTimeouts()
  }
}
</script>

<style lang="scss" scoped>
  .no-reviews-text {
    font-size: 22px;
    line-height: 1.35;
    font-weight: bold;
  }

  .prodlink {
    color: #212529;
    text-decoration: underline;
  }

  .search-settings {
    position: fixed;
    top: 30vh;
    right: 1rem;
    background: #404142;
    min-height: 300px;
    min-width: 300px;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
.thumb-container {
    position: relative;
    min-height: 150px;

    &::after {
      content: "\f03e";
      font-weight: 900;
      font-family: 'Font Awesome 5 Free';
      position: absolute;
      top: 15px;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      font-size: 7rem;
      color: #c4c3c3;
    }


    .prothumb {
      position: relative;
      object-fit: contain;
      max-height: 100%;
      width: 100%;
      z-index: 2;
    }
}


  .icons.irate {
    @media screen and (max-width: 928px) {
      height: 5vh;
      max-width: 5vw;
    }
  }
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c("h3", [_vm._v("Confirm")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.message))]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "button",
              {
                staticClass: "button mr-3",
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    _vm.$emit("confirm")
                    _vm.$emit("close")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.action || "Yes"))]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export async function searchMedia(keyword) {
  let searchResultsResponse = await axios.get('/api/media-items/search-simple', {
    params: {
      q: keyword
    }
  });

  let { data } = searchResultsResponse;

  return data;
}

export async function searchMediaByFriends(keyword) {
  let searchResultsResponse = await axios.get('/api/media-items/search-by-friends', {
    params: {
      q: keyword
    }
  });

  let { data } = searchResultsResponse;

  return data;
}

export async function getMediaItem(imdbId) {
  let mediaItemResponse = await axios.get(`/api/media-items/${imdbId}`);

  let { data } = mediaItemResponse;

  return data;
}

export async function addReview(id, data) {
  return await axios.post(`/api/media-items/${id}/reviews`, data);
}
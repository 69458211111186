<template>
    <div>
        <div id="cart">
            <!-- START OF CART -->

            <div id="root">
                <div id="profile-interests">
                    <div id="cwrap">
                        <div class="cart_container">
                            <div class="cart_overflow">
                                <table class="cart-table">
                                    <thead class="cart_fix_header">
                                    <tr>
                                        <th class="wide">
                                            <i
                                                    class="fa fa-plus-circle"
                                                    style="font-size:20px;color: rgb(0, 174, 255);-webkit-text-stroke-width: .5px; -webkit-text-stroke-color: black;"
                                            ></i>
                                            <b>Cart</b>
                                        </th>
                                        <th class="narrow"><b>QTY</b></th>
                                        <th class="normal"><b>Unit Price</b></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="border-bottom border-dark" v-for="(item, index) in cart_items" :key="item.product_id + index">
                                            <td v-if="item.variant"
                                                :title="[item.variant.title.length > 50 ? item.variant.title : '']">
                                                <a :href="'/products/'+ item.product.slug" target="_blank">
                                                    {{ item.variant.title.substr(0, 50) }}
                                                    <span v-if="item.variant.title.length > 50">
                                                        ...
                                                    </span>
                                                 </a>
                                            </td>
                                            <td v-else 
                                                :title="[item.product.name.length > 50 ? item.product.name : '']">
                                                <a :href="'/products/'+ item.product.slug" target="_blank">
                                                    {{ item.product.name.substr(0, 50) }}
                                                    <span v-if="item.product.name.length > 50">
                                                        ...
                                                    </span>
                                                 </a>
                                            </td>
                                            <td class="quantity">{{ item.quantity }}</td>
                                                <td v-if="item.variant">${{ numeral(item.variant.price).format('0,0.00') }}</td>

                                                <td v-else> ${{ numeral(item.product.product_price).format('0,0.00') }}</td>
                                            <td>
                                                <button @click="reduce($event, item)" class="btn btn-sm btn-secondary item__action">
                                                    <i class="fa fa-minus"></i>
                                                </button>
                                                <button @click="increase($event, item)" class="btn btn-sm btn-primary item__action"
                                                    style="background: #00aeff;">
                                                    <i class="fa fa-plus"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-sm text-danger item__action"
                                                    title="Remove"
                                                    @click="remove($event, item)">
                                                    
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr v-show="(!cart_items || cart_items.length == 0)">
                                            <td colspan="4" class="text-center">
                                                <div v-show="!fetching_items">
                                                    <p class="p-2">You have No Items in your Cart</p>
                                                </div>
                                                <div v-show="fetching_items">
                                                    <p class="p-2">Loading</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- end cart_overflow -->

                            <div class="receipt">
                                <div class="receipt_hdr">
                                    <b>Total</b>
                                </div>
                                <div class="rc_wrap">
                                    <div class="breakdown">
                                        <p>Subtotal: ${{ totalPrice }}</p>

                                        <p>
                                            <b>Total: ${{ totalPrice }}</b>
                                        </p>
                                    </div>
                                    <!-- breakdown -->

                                    <div class="w-100 d-flex align-items-center justify-content-end">
                                        <div class="button button-p7 mb-2 ml-2 mr-2"
                                            @click="checkout"
                                            :disabled="cart_items && cart_items.length > 0">Checkout!</div>
                                    </div>
                                    <!-- checkout_btn -->
                                </div>
                                <!-- rc_wrap -->
                            </div>
                            <!-- end receipt -->
                        </div>
                        <!-- end cart_container -->
                    </div>
                </div>
            </div>
        </div>
        <!-- END OF CART -->
    </div>
</template>
<script>
require('../notify');
import Swal from 'sweetalert2';
const numeral = require('numeral');

  export default {
    name: 'interesets',
    components: {},
    data () {
      return {
        cart_items: [],
        fetching_items: true,
        private: false,
      }
    },
    created() {
      axios.get('/api/cart').then(({ data }) => {
        this.cart_items = data;
        this.updateItemsCount();
      }).finally(() => {
        this.fetching_items = false;
      });
    },
    methods: {
      updateItemsCount() {
          window.totalItemsCount = this.totalQuantity;
          $('.cart-items-count').text(this.totalQuantity)
      },
      remove(e, item) {
        const target = $(e.target);
        const defaultHtml = target.html();
        const url = `/api/cart/${item.product_id}/${item.variant_id ? item.variant_id : ''}`;

        this.confirmationAlert().then(confirmed => {
            if (confirmed.value) {
              this.displayLoading(target);

              axios.delete(url, {'_method': 'DELETE'}).then(({ data }) => {
                if (data.success) {  
                    this.cart_items = data.items;
                }

                $.notify(data.message, data.success ? 'success' : 'error');
                this.updateItemsCount();
              }).finally(() => {
                  this.resetActionButton(target, defaultHtml);
              });
            }
        });
      },
      increase(e, item) {
        const target = $(e.target);
        const defaultHtml = target.html();
        const url = `/api/cart/${item.product_id}/${item.variant_id ? item.variant_id : ''}`;

        this.displayLoading(target);

        axios.put(url, {'_method': 'PUT'}).then(({ data }) => {
          if (data.success) {  
            this.cart_items = data.items;
          }
          
          $.notify(data.message, data.success ? 'success' : 'error');
          this.updateItemsCount();

        }).finally(() => {
            this.resetActionButton(target, defaultHtml);
        });
      },
      reduce(e, item) {
        const target = $(e.target);
        const defaultHtml = target.html();
        const url = `/api/cart/${item.product_id}/${item.variant_id ? item.variant_id : ''}`;

        // If the item quantity is 1
        if (item.quantity === 1) {
          // Invoke the "remove" method
          return this.remove(e, item);
        }

        this.displayLoading(target);

        axios.patch(url, {'_method': 'PATCH'}).then(({ data }) => {
          if (data.success) {  
            this.cart_items = data.items;
          }

          $.notify(data.message, data.success ? 'success' : 'error');
          this.updateItemsCount();
        }).finally(() => {
            this.resetActionButton(target, defaultHtml);
        });
      },
      displayLoading(target) {
        target.html('<i class="fa fa-circle-notch spin"></i>');

        $('.item__action').attr('disabled', true);
      },
      resetActionButton(target, original) {
        target.html(original);

        $('.item__action').attr('disabled', false);
      },
      confirmationAlert(message = null, description = null) {
        return Swal.fire({
          title: message == null ? 'Are you sure?' : message,
          text: description == null ? "You won't be able to revert this!" : description,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        });
      },
      checkout() {
          // Redirect to the checkout page
          this.$router.push({name: 'checkout'});
      },
      numeral(args) {
          return numeral(args);
      }
    },
    computed: {
        totalPrice() {
            let total = 0;

            if (!this.cart_items) {
                return '-';
            }
        
            for (let i = 0; i < this.cart_items.length; i++) {
                let item = this.cart_items[i];
                const basePrice = item.variant
                    ? item.variant.price
                    : item.product.product_price;

                total += basePrice * item.quantity;
            }

            return numeral(total).format('0,0.00');
        },
        totalQuantity() {
            let total = 0;

            for (let i = 0; i < this.cart_items.length; i++) {
                let item = this.cart_items[i];

                total += item.quantity;
            }

            return total;
        },
    },
  }
</script>
<style lang="scss" scoped>
    .cart-table {
        width: 100%;
    }

    .cart-table thead {
        border-bottom: 2px solid gray;
    }

    .cart-table thead th:first-child {
        padding-left: 10px;
    }

    .cart-table thead th:last-child {
        padding-right: 10px;
    }

    .cart-table td:first-child {
        padding-left: 10px;
    }

    .cart-table td:last-child {
        padding-right: 10px;
    }

    .cart-table td {
        padding: 3px 0;
        border-bottom: 1px solid gray;
    }

    .cart-table tbody tr:last-of-type {
        border-bottom: none;
    }

    .cart-table thead th {
        padding: 5px 0;
    }

    @media screen and (max-width: 576px) {
        .cart-table {
            overflow: scroll;
        }

        .cart-table thead th.normal {
            min-width: 150px;
        }

        .cart-table thead th.wide {
            min-width: 200px;
        }

        .cart-table thead th.narrow {
            min-width: 100px;
        }

        @media screen and (min-width: 576px) {
            .cart-table thead th {
                min-width: 0;
            }
        }
    }

    @media screen and (max-width: 501px) {
            
        .cart-table {
            thead {
                th {
                    &.normal {
                        min-width: 60px !important;
                    }
                    
                    &.wide {
                        min-width: 75px !important;
                    }

                    &.narrow {
                        min-width: 50px !important;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &.quantity {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .cart_overflow {
        overflow-x: auto;
    }

    @media screen and (min-width: 576px) {
        .cart_overflow {
            overflow-x: hidden;
        }
    }
</style>

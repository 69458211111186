<template>
    <div>
        <ul>
            <li>star</li>
            <li>star</li>
            <li>star</li>
            <li>star</li>
            <li>star</li>
        </ul>
        <span>3 of 5</span>
    </div>
</template>

<script>
    export default {
        name: "stars"
    }
</script>

<style scoped>

</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "news-feed-page" }, [
    _vm.isShowFeed
      ? _c("div", [
          _vm.fetchingFeeds
            ? _c(
                "div",
                { staticClass: "m-2 container mx-auto fetching-feed" },
                [_vm._m(0)]
              )
            : !_vm.fetchingFeeds && (!_vm.feeds || _vm.feeds.length < 1)
            ? _c("div", { staticClass: "m-2 container mx-auto" }, [_vm._m(1)])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "section",
            { attrs: { id: "news-feed-container" } },
            _vm._l(_vm.feeds, function(item, index) {
              return _c("div", { key: index, staticClass: "box item-box" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "section",
                    {
                      staticClass: "d-min-tablet-none",
                      staticStyle: { margin: "0 auto" }
                    },
                    [
                      item.feed_item_type === "MediaItem"
                        ? [
                            _c(
                              "router-link",
                              {
                                staticClass: "tagname prodlink",
                                attrs: {
                                  to: {
                                    name: "media-details",
                                    params: { id: item.imdb_id }
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(item.title) +
                                    "\n                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "tagname" }, [
                              _vm._v(
                                _vm._s(
                                  item.type === "movie" ? "Movie" : "TV Series"
                                )
                              )
                            ])
                          ]
                        : [
                            _c(
                              "router-link",
                              {
                                staticClass: "tagname prodlink",
                                attrs: {
                                  to: _vm.getProductLink(
                                    item,
                                    item.user,
                                    _vm.user
                                  )
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "tagname" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm
                                      .numeral(item.product_price)
                                      .format("0,0.00")
                                  )
                              )
                            ])
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("div", { staticClass: "rr-badge-container" }, [
                      _c("div", { staticClass: "review" }, [
                        _c("div", { staticClass: "font-weight-bolder" }, [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                item.review.rating > 5 ? 5 : item.review.rating
                              ) +
                              "\n                      "
                          ),
                          _c("i", { staticClass: "fa fa-star text-warning" }),
                          _vm._v(" "),
                          item.review.rating > 5
                            ? _c("span", [
                                _vm._v("\n                          + "),
                                _c("i", {
                                  staticClass: "fa fa-moon text-warning",
                                  staticStyle: { transform: "rotate(200deg)" }
                                }),
                                _vm._v("!\n                      ")
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.isInfluencer(item.user)
                        ? _c("div", { staticClass: "rr-badge" }, [_vm._v("rR")])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "userimg",
                        attrs: { src: _vm.getUserPhoto(item.user) }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tagname" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            item.user ? item.user.name : "A friend reviewed"
                          ) +
                          "\n              \n              "
                      ),
                      _vm.isInfluencer(item.user)
                        ? _c(
                            "div",
                            { staticClass: "influencer-badge-container" },
                            [_c("InfluencerBadge")],
                            1
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "line col-sm-4" }, [
                    item.user
                      ? _c(
                          "div",
                          { staticClass: "tagname d-none d-min-tablet-block" },
                          [_vm._v(_vm._s(item.user.name) + " says:")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", { staticClass: "contentDesc" }, [
                      _vm._v(_vm._s(item.review.content))
                    ]),
                    _vm._v(" "),
                    item.feed_item_type === "MediaItem"
                      ? _c("div", { staticClass: "mt-4 imdb-rating" }, [
                          _vm._v(
                            "\n              IMDB Rating: " +
                              _vm._s(item.rating) +
                              "/10 - " +
                              _vm._s(_vm._f("localNumber")(item.votes)) +
                              " IMDB reviews\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "row" }, [
                        item.feed_item_type === "Product"
                          ? _c(
                              "div",
                              { staticClass: "col-sm-8 col-md-8 col-lg-10" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "tagname prodlink d-none d-min-tablet-block",
                                    attrs: {
                                      to: _vm.getProductLink(
                                        item,
                                        item.user,
                                        _vm.user
                                      )
                                    }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tagname d-none d-min-tablet-block"
                                  },
                                  [
                                    _vm._v(
                                      "$" +
                                        _vm._s(
                                          _vm
                                            .numeral(item.product_price)
                                            .format("0,0.00")
                                        )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: _vm.getProductLink(
                                        item,
                                        item.user,
                                        _vm.user
                                      )
                                    }
                                  },
                                  [
                                    Object.keys(item).includes("photos") &&
                                    item.photos.length > 0
                                      ? _c("img", {
                                          staticClass: "prothumb",
                                          attrs: {
                                            src:
                                              "/storage/product_images/" +
                                              item.photos[0].server_path
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.feed_item_type === "MediaItem"
                          ? _c(
                              "div",
                              { staticClass: "col-sm-8 col-md-10" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "tagname prodlink",
                                    attrs: {
                                      to: {
                                        name: "media-details",
                                        params: { id: item.imdb_id }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(item.title) +
                                        "\n                    "
                                    ),
                                    _c("div", { staticClass: "tagname" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.type === "movie"
                                            ? "Movie"
                                            : "TV Series"
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "prothumb",
                                      attrs: { src: item.poster }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2 col-md-1 rate" }, [
                          _c("b", [_vm._v(_vm._s(item.average_rating))]),
                          _vm._v(" "),
                          _c("section", { staticClass: "avg-review-graphic" }, [
                            _c("img", {
                              staticClass: "irate",
                              attrs: {
                                src: [
                                  item.average_rating > 5
                                    ? "/images/moon-filled.png"
                                    : "/images/moon.png"
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "section",
                              { staticClass: "review-stars" },
                              _vm._l(5, function(star, index) {
                                return _c("span", { key: star }, [
                                  star > item.average_rating
                                    ? _c("img", {
                                        staticClass: "icons irate",
                                        attrs: { src: "/images/star2.png" }
                                      })
                                    : _c("img", {
                                        staticClass: "icons irate",
                                        attrs: { src: "/images/star1.png" }
                                      })
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "frd_viewer" },
                      [
                        _vm._l(item.reviewingFriends[0], function(
                          item3,
                          index2
                        ) {
                          return index2 <= 6
                            ? _c("img", {
                                key: index2,
                                staticClass: "icons",
                                attrs: { src: _vm.getUserPhoto(item3.user) }
                              })
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        item.reviewingFriends > 6
                          ? _c("a", { attrs: { href: "" } }, [
                              _c("b", { staticClass: "counterFriends" }, [
                                _vm._v("+12")
                              ])
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ])
              ])
            }),
            0
          ),
          _vm._v(" "),
          !_vm.fetchingFeeds && _vm.no_match
            ? _c("div", { staticClass: "m-2 container mx-auto" }, [_vm._m(2)])
            : _vm._e(),
          _vm._v(" "),
          _vm.showLoader
            ? _c(
                "div",
                {
                  staticClass:
                    "row flex-column justify-content-center align-items-center"
                },
                [
                  _vm._m(3),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-white" }, [
                    _vm._v("Loading...")
                  ])
                ]
              )
            : _vm._e()
        ])
      : _c("div")
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card box-card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", { staticClass: "py-5" }, [_vm._v("Loading Newsfeed")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card box-card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", { staticClass: "py-5" }, [_vm._v("No feed items were found")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card box-card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", { staticClass: "py-5" }, [
          _vm._v(
            "Please add more interests on your profile page to see more reviews from your friends, family, and influencers!"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lds-facebook" }, [
      _c("div"),
      _c("div"),
      _c("div")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "feed" } }, [
        _c("div", { attrs: { id: "root" } }, [
          _vm.product
            ? _c("div", { attrs: { id: "profile-interests" } }, [
                _c(
                  "div",
                  { staticClass: "rvw_wraper box" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "back_btn",
                        attrs: { to: { name: "home" } }
                      },
                      [_vm._v("Back")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-xl-6 " }, [
                        _c("div", { staticClass: "d-flex" }, [
                          _c("div", { staticClass: "details_prod" }, [
                            _c("div", { staticClass: "tagname" }, [
                              _vm._v(_vm._s(_vm.product.name))
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "tagname",
                              domProps: {
                                textContent: _vm._s(_vm.currentPriceDisplay)
                              }
                            }),
                            _vm._v(" "),
                            _vm.product.photos && _vm.product.photos[0]
                              ? _c("img", {
                                  staticClass: "prothumb",
                                  staticStyle: { "max-height": "400px" },
                                  attrs: {
                                    src:
                                      "/storage/product_images/" +
                                      _vm.product.photos[0].server_path
                                  }
                                })
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "rv_box" }, [
                            _c("div", { staticClass: "rvrate" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "avg-rating font-weight-bolder"
                                },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.product.average_rating > 5
                                          ? 5
                                          : _vm.product.average_rating
                                      ) +
                                      "\n                                            "
                                  ),
                                  _c("i", {
                                    staticClass: "fa fa-star text-warning"
                                  }),
                                  _vm._v(" "),
                                  _vm.product.average_rating > 5
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                                + "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "fa fa-moon text-warning",
                                          staticStyle: {
                                            transform: "rotate(200deg)"
                                          }
                                        }),
                                        _vm._v(
                                          "!\n                                            "
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "stars-box d-flex flex-column-reverse"
                                },
                                [
                                  _vm._l(5, function(star) {
                                    return _c("span", { key: star }, [
                                      star > _vm.product.average_rating
                                        ? _c("img", {
                                            staticClass: "icons irate",
                                            attrs: { src: "/images/star2.png" }
                                          })
                                        : _c("img", {
                                            staticClass: "icons irate",
                                            attrs: { src: "/images/star1.png" }
                                          })
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass: "icons irate mb-2",
                                    attrs: {
                                      src: [
                                        _vm.product.average_rating > 5
                                          ? "/images/moon-filled.png"
                                          : "/images/moon.png"
                                      ]
                                    }
                                  })
                                ],
                                2
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.product.variations.length > 0
                          ? _c("div", { staticClass: "cart_box" }, [
                              _c("div", [
                                _c(
                                  "section",
                                  {
                                    key: _vm.attributesKey,
                                    attrs: { id: "attributes-section" }
                                  },
                                  _vm._l(_vm.variableAttributes, function(
                                    attribute
                                  ) {
                                    return _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.selectedOptions[
                                                attribute.name
                                              ],
                                            expression:
                                              "selectedOptions[attribute.name]"
                                          }
                                        ],
                                        key: attribute.id,
                                        staticClass:
                                          "form-control mb-2 select2-control",
                                        attrs: {
                                          "data-placeholder": attribute.label,
                                          "data-attribute": attribute.name
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.selectedOptions,
                                              attribute.name,
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      _vm._l(
                                        _vm.getAttributeValues(attribute),
                                        function(value, index) {
                                          return _c("option", {
                                            key: index * value.length,
                                            domProps: {
                                              value: value,
                                              textContent: _vm._s(value)
                                            },
                                            on: { change: _vm.updateOptions }
                                          })
                                        }
                                      ),
                                      0
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _vm.shippingDays
                                  ? _c(
                                      "h5",
                                      {
                                        staticClass: "font-weight-normal mb-3"
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        Usually ships within " +
                                            _vm._s(_vm.shippingDays) +
                                            " days\n                                    "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("ul", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.productDescription)
                                  }
                                })
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.imported_from_amazon &&
                        !_vm.product.amz_fullfillment_enabled
                          ? _c(
                              "div",
                              {
                                staticClass: "cart_box flex-column text-center"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "p-2",
                                    staticStyle: { "font-size": "1.1rem" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    We've had so many orders we're currently out of stock, buy on Amazon Here:\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-primary btn-lg cart m-0",
                                    attrs: { href: _vm.product.sellers_website }
                                  },
                                  [
                                    _vm._v(
                                      "Order from Amazon - We've temporarily sold our stock"
                                    )
                                  ]
                                )
                              ]
                            )
                          : _c("div", { staticClass: "cart_box" }, [
                              _c("ul", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.productDescription)
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "qty_cart" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "h5 text-dark",
                                      attrs: { for: "quantity" }
                                    },
                                    [_vm._v("Qty:")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.productQty,
                                        expression: "productQty"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { id: "quantity" },
                                    domProps: { value: _vm.productQty },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.productQty = $event.target.value
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cart m-0",
                                    attrs: { id: "add-to-cart" },
                                    on: { click: _vm.addToCart }
                                  },
                                  [
                                    _c("i", { class: _vm.addToCartIcon }),
                                    _vm._v(
                                      "\n                                        add to cart\n                                    "
                                    )
                                  ]
                                )
                              ])
                            ]),
                        _vm._v(" "),
                        _c(
                          "section",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.product.can_refer,
                                expression: "product.can_refer"
                              }
                            ],
                            attrs: { id: "referral-section" }
                          },
                          [
                            _c("hr", { staticClass: "mb-2" }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary btn-lg btn-block mt-1 mb-1",
                                attrs: { type: "button", id: "get-referral" },
                                on: {
                                  click: function($event) {
                                    return _vm.getReferralLink($event)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-link" }),
                                _vm._v(
                                  "\n                                    Get partner link for this product\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("h6", { staticClass: "font-weight-bold mb-3" }, [
                              _vm._v(
                                "\n                                    *Partner links let you make money by posting off-rRipple\n                                "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.product.youtube_video_url || _vm.product.can_edit
                          ? _c(
                              "section",
                              { staticClass: "product-video-container" },
                              [
                                _c("h3", [_vm._v("Seller Added Video:")]),
                                _vm._v(" "),
                                _vm.product.youtube_video_url
                                  ? _c(
                                      "div",
                                      { staticClass: "product-video" },
                                      [
                                        _c("youtube", {
                                          attrs: {
                                            "video-id": _vm.getIdFromYouTubeURL(
                                              _vm.product.youtube_video_url
                                            ),
                                            "player-width": "100%"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.isAdmin
                                          ? _c(
                                              "div",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: { href: "" },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.adminDeleteVideo()
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Admin Delete Video")]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.product.can_edit
                                  ? _c("div", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.videoUrl,
                                            expression: "videoUrl"
                                          }
                                        ],
                                        staticClass: "product-video-input",
                                        attrs: {
                                          placeholder:
                                            "Add video to this Page with YouTube URL..."
                                        },
                                        domProps: { value: _vm.videoUrl },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.videoUrl = $event.target.value
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "text-right" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "button-submit-video",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.submitVideo()
                                              }
                                            }
                                          },
                                          [_vm._v("Submit Video")]
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "section",
                          { staticClass: "product-video-container" },
                          [
                            _c("h1", { staticClass: "h2" }, [
                              _vm._v("Community Added Videos")
                            ]),
                            _vm._v(" "),
                            !_vm.product.community_videos.length
                              ? _c("div", [_vm._v("No videos yet.")])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "community-videos-overflow" },
                              _vm._l(_vm.product.community_videos, function(
                                video
                              ) {
                                return _c(
                                  "div",
                                  { key: video.id },
                                  [
                                    _c("youtube", {
                                      attrs: {
                                        "video-id": _vm.getIdFromYouTubeURL(
                                          video.url
                                        ),
                                        "player-width": "100%"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text-right" }, [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.flagVideoAsInappropriate(
                                                video
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Flag as inappropriate")]
                                      ),
                                      _vm._v(" "),
                                      _vm.isAdmin
                                        ? _c("span", [_vm._v("|")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isAdmin
                                        ? _c(
                                            "a",
                                            {
                                              attrs: { href: "" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.adminDeleteCommunityVideo(
                                                    video
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Admin Delete Video")]
                                          )
                                        : _vm._e()
                                    ])
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            !_vm.product.can_edit &&
                            _vm.product.community_videos.length < 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "add-community-video-container"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.communityVideoUrl,
                                          expression: "communityVideoUrl"
                                        }
                                      ],
                                      staticClass: "product-video-input",
                                      attrs: {
                                        placeholder:
                                          "Add video to this Page with YouTube URL..."
                                      },
                                      domProps: {
                                        value: _vm.communityVideoUrl
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.communityVideoUrl =
                                            $event.target.value
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text-right" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button-submit-video",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.submitCommunityVideo()
                                            }
                                          }
                                        },
                                        [_vm._v("Submit Video")]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xl-6" }, [
                        _c(
                          "div",
                          { staticClass: "Review_overflow" },
                          [
                            _c("h3", { staticClass: "m-0 d-lg-none" }, [
                              _vm._v("Reviews")
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "review_flexbox mb-3 pt-0 d-lg-none"
                            }),
                            _vm._v(" "),
                            _vm.product.reviews.length === 0
                              ? _c("div", { staticClass: "text-center" }, [
                                  _c("p", { staticClass: "py-10" }, [
                                    _vm._v(
                                      "No reviews for this Product yet. Be the first!"
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              _vm.product.reviews.slice(0, _vm.reviewLimit),
                              function(r) {
                                return _c(
                                  "div",
                                  { staticClass: "review_flexbox" },
                                  [
                                    _c("div", { staticClass: "col-md-4 p-0" }, [
                                      _c(
                                        "div",
                                        { staticClass: "rr-badge-container" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "review_star font-weight-bolder"
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    r.rating > 5 ? 5 : r.rating
                                                  ) +
                                                  "\n                                                "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-star text-warning"
                                              }),
                                              _vm._v(" "),
                                              r.rating > 5
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                                    + "
                                                    ),
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-moon text-warning",
                                                      staticStyle: {
                                                        transform:
                                                          "rotate(200deg)"
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "!\n                                                "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          r.user && _vm.isInfluencer(r.user)
                                            ? _c(
                                                "div",
                                                { staticClass: "rr-badge" },
                                                [_vm._v("rR")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          r.user
                                            ? _c("img", {
                                                staticClass: "userimg",
                                                attrs: {
                                                  src: _vm.getUserPhoto(r.user)
                                                }
                                              })
                                            : _c("img", {
                                                staticClass: "userimg",
                                                attrs: {
                                                  src: "/images/avatar.png"
                                                }
                                              })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        r.user
                                          ? _c("strong", [
                                              _vm._v(_vm._s(r.user.name))
                                            ])
                                          : _c("strong", [_vm._v("Anonymous")]),
                                        _vm._v(" "),
                                        r.user && _vm.isInfluencer(r.user)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "influencer-badge-container"
                                              },
                                              [_c("InfluencerBadge")],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-md-8" }, [
                                      _c("div", [
                                        r.user
                                          ? _c("i", [
                                              _vm._v(
                                                _vm._s(
                                                  r.user.name.split(" ")[0]
                                                ) + " says:"
                                              )
                                            ])
                                          : _c("i", [_vm._v("Anonymous says:")])
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(r.content) +
                                            "\n                                        "
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _vm.product.reviews.length > _vm.reviewLimit
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center my-2"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "button",
                                        on: {
                                          click: function($event) {
                                            _vm.reviewLimit =
                                              _vm.reviewLimit + 10
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "see more\n                                        reviews\n                                    "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.product.friend_reviews.length
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "frd_viewer" },
                                [
                                  _vm._l(_vm.product.friend_reviews, function(
                                    item3,
                                    index2
                                  ) {
                                    return index2 <= 6
                                      ? _c("img", {
                                          key: index2,
                                          staticClass: "icons",
                                          attrs: {
                                            src: _vm.getUserPhoto(item3.user)
                                          }
                                        })
                                      : _vm._e()
                                  }),
                                  _vm._v(" "),
                                  _vm.product.friend_reviews.length > 6
                                    ? _c("a", { attrs: { href: "" } }, [
                                        _c(
                                          "b",
                                          { staticClass: "counterFriends" },
                                          [
                                            _vm._v(
                                              "+" +
                                                _vm._s(
                                                  _vm.product.friend_reviews
                                                    .length - 6
                                                )
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                2
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.product.can_edit
                  ? _c(
                      "div",
                      {
                        staticClass: "rvw_wraper box mt-3 text-left",
                        staticStyle: { "font-size": "1rem" }
                      },
                      [
                        _c("h3", { staticClass: "my-3" }, [
                          _vm._v("Manage Product")
                        ]),
                        _vm._v(" "),
                        _c("h5", { staticClass: "featurette-heading" }, [
                          _vm._v(
                            "\n                        Existing variations\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-responsive-md" }, [
                          _c(
                            "table",
                            {
                              staticClass:
                                "table table-striped table-bordered table-hover"
                            },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.product.variations, function(
                                  variant
                                ) {
                                  return _c("tr", [
                                    _c("td", [
                                      _vm._v(" " + _vm._s(variant.name))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        " " + _vm._s(variant.price_cents / 100)
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        " " + _vm._s(variant.ship_weight_oz)
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        " " + _vm._s(variant.ship_time_days)
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(" " + _vm._s(variant.count))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(" " + _vm._s(variant.image_path))
                                    ])
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("h5", { staticClass: "featurette-heading" }, [
                          _vm._v(
                            "\n                        Create Variant\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "form",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              action: "/create_variant",
                              method: "post",
                              enctype: "multipart/form-data"
                            }
                          },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { type: "hidden", name: "_token" },
                              domProps: { value: _vm.csrf() }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { type: "hidden", name: "product_id" },
                              domProps: { value: _vm.product.id }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "button",
                              attrs: { type: "submit", value: "Add Variant" }
                            })
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "rvw_wraper box mt-3 text-left",
                    staticStyle: { "font-size": "1rem" }
                  },
                  [
                    _vm.product.can_review
                      ? _c("div", [
                          _c("h3", { staticClass: "my-3" }, [
                            _vm._v("Add Review")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("rating", {
                                attrs: {
                                  grade: 0,
                                  "max-stars": 5,
                                  "moon-ctive": false
                                },
                                on: {
                                  "rating-set": function($event) {
                                    _vm.reviewForm.newRating = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "title" } }, [
                              _vm._v("Title")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reviewForm.title,
                                  expression: "reviewForm.title"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "title",
                                placeholder: "Awesome title",
                                name: "title"
                              },
                              domProps: { value: _vm.reviewForm.title },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.reviewForm,
                                    "title",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "body" } }, [
                              _vm._v("Content")
                            ]),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reviewForm.body,
                                  expression: "reviewForm.body"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { id: "body", name: "body" },
                              domProps: { value: _vm.reviewForm.body },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.reviewForm,
                                    "body",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reviewForm.is_private,
                                    expression: "reviewForm.is_private"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.reviewForm.is_private
                                  )
                                    ? _vm._i(_vm.reviewForm.is_private, null) >
                                      -1
                                    : _vm.reviewForm.is_private
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.reviewForm.is_private,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.reviewForm,
                                            "is_private",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.reviewForm,
                                            "is_private",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.reviewForm,
                                        "is_private",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v(
                                "\n                                Make review anonymous\n                            "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                on: { click: _vm.submitReview }
                              },
                              [_vm._v("Add Review")]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.user_review
                      ? _c("div", [
                          _c("h3", { staticClass: "my-3" }, [
                            _vm._v("Edit Review")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.product.user_review.is_private,
                                    expression: "product.user_review.is_private"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.product.user_review.is_private
                                  )
                                    ? _vm._i(
                                        _vm.product.user_review.is_private,
                                        null
                                      ) > -1
                                    : _vm.product.user_review.is_private
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.product.user_review.is_private,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.product.user_review,
                                            "is_private",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.product.user_review,
                                            "is_private",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.product.user_review,
                                        "is_private",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v(
                                "\n                                Make review anonymous\n                            "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                on: {
                                  click: function($event) {
                                    return _vm.updateReview(
                                      _vm.product.user_review.is_private
                                    )
                                  }
                                }
                              },
                              [_vm._v("Update Review")]
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _vm.product
        ? _c("referral-modal", { attrs: { link: _vm.referralLink } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.product
        ? _c("h4", {
            staticClass: "text-white p-5",
            domProps: { textContent: _vm._s(_vm.noProductText) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Price (USD)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Weight (Oz)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Time to ship (days)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Count")]),
        _vm._v(" "),
        _c("th", [_vm._v("Image name")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "name" } }, [_vm._v("Name")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "text",
              placeholder: "Variant Name",
              name: "variant_name"
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "Price" } }, [
            _vm._v("Price (Dollars and Cents)")
          ]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "number",
              step: "any",
              placeholder: "Price",
              name: "price"
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "weight" } }, [_vm._v("Weight (Oz)")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "number", placeholder: "Weight", name: "weight" }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "TimeToShip" } }, [
            _vm._v("Time to Ship (days)")
          ]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "number", placeholder: "Days", name: "time_to_ship" }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "Count" } }, [_vm._v("Variant Count")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "number",
              placeholder: "Count",
              name: "variant_count"
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "Photos" } }, [
            _vm._v("Choose variant image")
          ]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control-file",
            attrs: { type: "file", name: "variant_photo[]", required: "" }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "text-left",
      attrs: {
        method: "POST",
        action: "/register",
        enctype: "multipart/form-data"
      }
    },
    [
      _vm.errors.length
        ? _c(
            "div",
            { staticClass: "alert-danger alert" },
            _vm._l(_vm.errors, function(err, key) {
              return _c("div", [
                _vm._v(_vm._s(err) + " "),
                key != _vm.errors.length - 1 ? _c("hr") : _vm._e()
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "_token" },
        domProps: { value: _vm.csrf }
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c("label", [_vm._v("Profile pic")]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-center mb-2" }, [
            _vm.image
              ? _c("img", {
                  staticClass: "w-100 upload_image",
                  attrs: { src: _vm.image }
                })
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("label", { staticClass: "btn btn-outline-dark btn-avatar" }, [
            _c("i", { staticClass: "fa fa-image" }),
            _vm._v(" Upload Profile Photo"),
            _c("input", {
              staticClass: "form-control-file",
              staticStyle: { display: "none" },
              attrs: { name: "avatar", type: "file" },
              on: { change: _vm.onFileChange }
            })
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mt-4" }, [
            _vm._v(
              "*Helps friends & family connect with your proper profile.\n            "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "(rRipple allows you to share great finds and great products/services with friends & family)"
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between flex-column flex-lg-row"
        },
        [
          _c("vue-recaptcha", {
            attrs: { sitekey: _vm.siteKey, loadRecaptchaScript: true }
          }),
          _vm._v(" "),
          _vm._m(4)
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "name" } }, [_vm._v("Name:")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "text", id: "name", name: "name", required: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "email" } }, [_vm._v("Email:")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "email", id: "email", name: "email", required: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "password" } }, [_vm._v("Password:")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "password",
          id: "password",
          name: "password",
          required: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "password_confirmation" } }, [
        _vm._v("Password Confirmation:")
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "password",
          id: "password_confirmation",
          name: "password_confirmation",
          required: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        {
          staticClass: "button-p7 button btn-block",
          attrs: { type: "submit" }
        },
        [_vm._v("Register")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
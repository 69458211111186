var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { class: ["influencer-badge", "influencer-badge--" + _vm.type] },
    [_vm._v("Influencer")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
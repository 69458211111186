<template>
    <div id="profile-interests">
        <div role="navigation" class="navbar navbar-default justify-content-between nav_bg border">
            <a
                    href="http://webclient.test/profile2#"
                    class="rRbutton navbar-brand justify-content-center"
            >rRipple</a>
            <!-- added a new class -->
            <div class="navbar-right">
                <form action="/action_page.php" class="form-inline">
                    <input type="text" placeholder="Search" class="form-control mr-sm-2 srch_box"/>
                </form>
            </div>
        </div>

        <div id="cwrap">
            <div class="row menubar">
                <a
                        v-for="(item,index) in arrMenu"
                        class="item active"
                        :key="item.id"
                        v-bind:class="['col-md-'+item.size]"
                >
                    {{ item.name }}
                    <div v-if="item.isactive" class="triangle-down"></div>
                </a>
            </div>
            <div>
                <interesets :setting-up="true"></interesets>
            </div>
        </div>
    </div>
</template>
<script>

  import message from './Messages.vue'
  import ProductFeedItem from './ProductFeedItem'
  import Interesets from './Interests'

  export default {
    components: {
      Interesets,
      ProductFeedItem,
    },
    props: {
      product: Object,
    },
    data () {
      return {
        arrMenu: [
          { id: 0, name: 'register for rRipple', isactive: true, size: 12 },
        ],
      }
    },
  }
</script>
<style>
    .item {
        font-size: 24px;
    }
</style>

<template>
    <home>
    </home>
</template>
<script>

  import message from './Messages.vue'
  import cart from './Cart.vue'
  import ProductFeedItem from './ProductFeedItem'
  import Home from './Home'

  export default {
    components: {
      ProductFeedItem,
      Home,
    },
    props: {
      product: Object,
    },
    data () {
      return {
        arrMenu: [
          { id: 0, name: 'feed', path: '/', isactive: true, size: 2 },
          { id: 1, name: 'friends', path: '/friends', isactive: false, size: 2 },
          { id: 2, name: 'message', path: '/messages', isactive: false, size: 2 },
          { id: 3, name: 'discussion forum', path: '/discussions', isactive: false, size: 2 },
          { id: 4, name: 'cart', path: '/cart', isactive: false, size: 2 },
          { id: 5, name: 'my groups', path: '/groups', isactive: false, size: 2 },
        ],
      }
    },
  }
</script>
